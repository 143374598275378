// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { userProfile} from "../assets";
import {
    Box,
    Button,
    Grid,
} from "@material-ui/core";


export interface Props {
    navigation: any;
    id: string;
    companyId:any;
    t?: any
    i18n?: any
    match?: any
    location?: any
    history?: any
}

interface S {
    loading: boolean;
    stepBuilding: number,
    complexBuildingId: string;

    selectedImage: null,
    previewImage: string,
    imagesBuilding: any,
    selectedFile: any,
    selectedFileBuliding: null,
    createConfirmModal:any,
    selectedFilePdfFloorPlanBuilding: any,
    imageURLComplex: string,
    imageURLBuilding: string,
    selectPdf: string,
    img: string,
    accountType:string,
    multiPleButtonComplex: any
    deleteMemberPopupBuilding:any
    page: number,
    rowsPerPage: number,
    findPopupOpen: any,
    jurisdictionSelected:any,
    facilityPopupBuilding: any,
    facilityValues:any,
    imagesSharedFacilityBuilding: any,
    coreMembers: any,
    createNewMember: any
    variants: any,
    selectedVariants: any
    coreMemberManager: any
    open: any
    openBuilding: any,
    savedFacility:any,
    memberValues:any,
    savedMemberBuilding:any,
    userList:any,
    roleList:any,
    userValues:any,
    deleteFacilityPopup:any,
    deleteFacilityIndex:any,
    deleteMemberIndexBuilding:any,
    buildingId:any;
    societyId:any;
    buildingList:any;
}

interface SS {
    id: any;
}
export default class CompEmpBuildingBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";
    GetUserList:any="";
    GetRoleList:any = "";
    SaveBuilding:any = "";
    addJBasicDetailId:any;
    addLocationId:any;
    addUnitsApiCall:any;
    addSharedApiId:any;
    getUploadDocumentListId:any;
    getCoreMember:any;
    addCoreMemberApiId:any;
    GetBuildingList:any;

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
        this.receive = this.receive.bind(this);
        this.state = {
            loading: false,
            stepBuilding: 4,
            complexBuildingId: "",

            coreMembers: false,
            createNewMember: false,
            accountType:'',
            selectedFile: [],
            selectedFileBuliding: null,
            selectedFilePdfFloorPlanBuilding: [],
            selectedImage: null,
            previewImage: "",
            imagesBuilding: [],
            coreMemberManager: [],
            page: 0,
            userList:[],
            roleList:[],
            userValues:{
                userName:'',
                role:''
            },
            createConfirmModal:false,
            rowsPerPage: 10,
            imagesSharedFacilityBuilding: [],
            imageURLComplex: '',
            multiPleButtonComplex: ['Jurisdiction', 'Basic Details', 'Documents', 'Location', 'Units', 'Shared Area', 'Core Members'],
            imageURLBuilding: '',
            selectPdf: '',
            img: userProfile,
            selectedVariants: [],
            facilityValues:{
                Details: '',
                sharedFacility: '',
                totalArea: '',
                fees: '',
                images:[],
                floorDocument:null,
            },
            savedFacility:[],
            memberValues:{
                email:'',
                phone:'',
                name:'',
                role:'',
                buildingName:'',
                unit:''
            },
            savedMemberBuilding:[],
           
            findPopupOpen: false,
            jurisdictionSelected: '',
            facilityPopupBuilding: false,
            open: false,
            openBuilding: false,
            deleteFacilityIndex:null,
            deleteMemberIndexBuilding:null,
            deleteFacilityPopup:false,
            deleteMemberPopupBuilding: false,
            variants: [
            ],
            buildingId:null,
            societyId:null,
            buildingList:[],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        const accountRole = localStorage.getItem("role")

        if (accountRole) {
            const accountRoleName = accountRole.replace(/"/g, '')
            this.setState({ accountType:  accountRoleName})
        }
    }

    async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
        if (this.state.stepBuilding !== prevState.stepBuilding) {
            if (this.state.stepBuilding === 6) {
                await this.getUserListBuilding();
                await this.getRoleListBuilding()
                await this.getBuildingList()
            }
        }
    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
          switch (apiRequestCallId) {
            case this.SaveBuilding:
                this.handleSaveBuilding(responseJson);
                break;
            case this.GetUserList:
                this.handleUserListBuilding(responseJson);
                break;
            case this.GetRoleList:
                this.handleRoleListBuilding(responseJson);
                break;
            case this.addJBasicDetailId:
                return this.handleBasicSucc(responseJson)
            case this.addLocationId:
                return this.handleLocationSucc(responseJson)
            case this.addUnitsApiCall:
                return this.handleUnitSucc(responseJson)
            case this.addSharedApiId:
                return this.handleSharedSucc(responseJson)
            case this.getUploadDocumentListId:
                return this.handleDocumentSucc(responseJson)
            case this.getCoreMember:
                return this.handleCoreSucc(responseJson)
            case this.addCoreMemberApiId:
                return this.handleCoreMemberSucc(responseJson)
            case this.GetBuildingList:
                return this.handleBuildingListSucc(responseJson)
            default:
              break;
          }
        
          this.setState({loading:false})
    
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          } else {
            ApiErrorResponse(responseJson);
          }
          ApiCatchErrorResponse(errorResponse);
        }
      }

    handleStepClick = (index: number) => {
        this.setState({ stepBuilding: index });
    };

    handleNextBuilding = () => {
        this.setState({ stepBuilding: this.state.stepBuilding + 1 });
    };

    handleBackBuilding = () => {
        this.setState({ stepBuilding: this.state.stepBuilding - 1 });
    };

    handleChangeJurisdiction = (jurisdiction: any) => {
        this.setState({ jurisdictionSelected: jurisdiction });
    };

    handleUpdateComplexBuildingId = (complexBuildingId: string, buildingId: any) => {
        this.setState({ complexBuildingId: complexBuildingId, buildingId: buildingId, stepBuilding: this.state.stepBuilding + 1 });
    }

    handleOpenDeleteFacilityPopup = (i:any) => {
        this.setState({ deleteFacilityPopup: true, deleteFacilityIndex: i });
    }

    handleCloseDeleteFacilityPopup = () => {
        this.setState({
            deleteFacilityPopup: false
        })
    }

    handleSaveBuilding = (responseJson:any) => {
        if (responseJson.data) {
            this.setState({createConfirmModal: true})
        }
    }


    handleUserListBuilding = (responseJson:any) => {
        if(responseJson?.status.length) {
            this.setState({userList: responseJson?.status})
        }
    }

    handleRoleListBuilding = (responseJson:any) => {
        if(responseJson?.data.length) {
           let status= responseJson?.data.map((data:any)=>({
            "label" : data.attributes.name,
            "value" : data.id
           }
            ))
            
            this.setState({roleList: status})
        }
    }

      handleBasicSucc=(responseJson:any)=>{
        if(responseJson.data){
            this.setState({buildingId:responseJson.data.attributes.is_building_list[0].id,societyId:responseJson.data.id,stepBuilding:2})
        }
      }
      handleLocationSucc=(responseJson:any)=>{
        if(responseJson.data){
            this.setState({stepBuilding:4})
        }
      }
      handleUnitSucc=(responseJson:any)=>{
        this.setState({stepBuilding:5})
      }
      handleSharedSucc=(responseJson:any)=>{
        this.setState({stepBuilding:6})
      }
      handleDocumentSucc=(responseJson:any)=>{
        this.setState({stepBuilding:3})
      }
      handleCoreSucc=(responseJson:any)=>{
        this.setState({savedMemberBuilding:[...this.state.savedMemberBuilding,responseJson.data]})
      }
      handleCoreMemberSucc=(responseJson:any)=>{
        this.setState({ memberValues:{
            email:'',
            phone:'',
            name:'',
            role:'',
            buildingName:'',
            unit:''
        },})
      }
      handleBuildingListSucc=(responseJson:any)=>{
        this.setState({buildingList:responseJson.buildings})
      }
    
    handleRemoveFacilityDetails = (index:number) => {
        const facilityArr = [...this.state.savedFacility]
        facilityArr.splice(index, 1)
        this.setState({savedFacility: facilityArr, deleteFacilityPopup:false})
    }

    handleRemoveMemberBuilding = (index:number) => {
        const memberArr = [...this.state.savedFacility]
        memberArr.splice(index, 1)
        this.setState({savedMemberBuilding: memberArr, deleteMemberPopupBuilding:false})
    }

    handleOpenDeleteMemberPopup = (i:any) => {
        this.setState({
            deleteMemberPopupBuilding: true,
            deleteMemberIndexBuilding: i
        })
    }

    handleCloseDeleteMemberPopupBuilding = () => {
        this.setState({
            deleteMemberPopupBuilding: false
        })
    }

   

    handleSharedDetails = (values: any) => {
        let error=false
        if(!error) {
            const savedFacilityObj = {
                ...values
            }
            this.setState({facilityValues: values, savedFacility: [...this.state.savedFacility, savedFacilityObj]}, () => {
                this.handleSaveSharedFacility()
                this.addSharedFacility(values)
            })
        }
    }

    handleMemberDetailsBuilding = (values:any) => {
        if (values) {
            const savedMember = {
                ...values
            }
            this.setState({savedMemberBuilding: [...this.state.savedMemberBuilding, savedMember]}, () => {
                this.sharedCreateNewMember()
                this.sharedCoreMembers()
                this.addCoreMember(values)
            })
        }
    } 

    handleSaveSharedFacility = () => {
        this.setState({facilityPopupBuilding: false})
    }

    getUserListBuilding = async () => {
        this.GetUserList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_my_team/team_members/user_name_list`,
          });
    }

    getRoleListBuilding = async () => {
        this.GetRoleList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_roles_permissions/roles`,
          });
    }
    getBuildingList=async()=>{
        this.GetBuildingList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_announcement/announcements/building_list?society_management_id=${this.state.societyId}`,
          });
    }
    addSharedFacility=async(facility:any)=>{
        const data = new FormData();
            data.append(`common_area[name]`, facility.sharedFacility)
            data.append(`common_area[details]`, facility.Details)
            data.append(`common_area[total_area]`, facility.totalArea)
            data.append(`common_area[reservation_fee]`, facility.fees)
            data.append(`common_area[floor_plan]`, facility.floorDocument)
            data.append('common_area[society_management_id]',this.state.societyId)
            facility.images.length && facility.images.forEach((photo:any) => {
                data.append(`common_area[photos][]`, photo)
            
        })
    this.addSharedApiId = await apiCall({
      body:data,
      method: "POST",
      endPoint: `/bx_block_society_management/common_areas`,
    });
    }

    addCoreMember=async(values:any)=>{
        const data = new FormData();
        data.append(`team_member[society_management_id]`, this.state.societyId)
            data.append(`team_member[role_id]`, values.role.value)
            data.append(`team_member[name]`,values.name)
            data.append(`team_member[email]`, values.email)
            data.append(`team_member[phone_number]`, values.phone)
            data.append(`team_member[unit]`, values.unit)
            data.append(`team_member[account_type]`, "true")
            data.append('team_member[building_management_id]', values.buildingName.id)
            this.addCoreMemberApiId = await apiCall({
                body:data,
                method: "POST",
                endPoint: `/bx_block_my_team/team_members/create_team_member`,
              });
    }
    handleBasicDetailsData=async(values:any)=>{
            this.handleBasicUserList(values)
    }
    handleBasicUserList=async(values:any)=>{
        this.sharedCoreMembers()
        this.getCoreMember = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_my_team/company_employee/employee_team_members/employee_details?employee_id=${values.userName.id}`,
          });
    }
      uploadDocuments=async()=>{
        const data = new FormData();
          this.getUploadDocumentListId = await apiCall({
            method: "POST",
            endPoint: `/society_managements/67/bx_block_my_document/upload_document`,
            body:data
          });
      }
      handleLocationAdd=async(values:any)=>{
        const data = new FormData();
        data.append('address[address]', "Add")
        data.append('address[society_management_id]', this.state.societyId)
        this.addLocationId = await apiCall({
            method: "POST",
            endPoint: `/bx_block_address/create_address`,
            body:data
          });
      }
    addUnitsId=async(unit:any)=>{
        const formData = new FormData();
            formData.append(`apartment_management[floor_number]`, unit.FloorNumber)
            formData.append('apartment_management[apartment_name]',unit.UnitNumber)
            formData.append(`apartment_management[configuration]`, unit.Configuration)
            formData.append(`apartment_management[size]`, unit.Size)
            formData.append(`apartment_management[purchase_price]`, unit.PurchasePrice)
            formData.append(`apartment_management[purchase_date]`, unit.PurchaseDate)
            formData.append(`apartment_management[current_valuation]`, unit.CurrentValuation)
            formData.append('apartment_management[status]','Own')
            formData.append('apartment_management[resident_name]',unit.ResidentName)
            formData.append('apartment_management[owner_name]',unit.ownerName)
            formData.append('apartment_management[building_management_id]',this.state.buildingId)
            formData.append('apartment_management[society_management_id]',this.state.societyId)
        
        this.addUnitsApiCall = await apiCall({
            method: "POST",
            endPoint: `/bx_block_settings/apartment_managements`,
            body:formData
          });
    }

    handleSave = async () => {
        const formData = new FormData();
        formData.append('jurisdiction_id', this.state.jurisdictionSelected.attributes.id)
        if (this.props.companyId) {
            formData.append('society_management[real_estate_company_id]', this.props.companyId)
        }

        formData.append('society_management[address_attributes][currency_id]', '1')

        this.state.savedFacility.length && this.state.savedFacility.forEach((facility:any, i:number) => {
            formData.append(`society_management[common_areas_attributes][${i}][name]`, facility.sharedFacility)
            formData.append(`society_management[common_areas_attributes][${i}][details]`, facility.Details)
            formData.append(`society_management[common_areas_attributes][${i}][total_area]`, facility.totalArea)
            formData.append(`society_management[common_areas_attributes][${i}][reservation_fee]`, facility.fees)
            formData.append(`society_management[common_areas_attributes][${i}][currency_id]`, '1')
        })
        
        this.setState({createConfirmModal:true})
    };

    exampleAPICall = async () => {
        this.exampleAPICallId = await this.apiCallForFileUpload({
            method: "POST",
            endPoint: `endPoint`,
            body: { data: {} }
        });
    }

    handleImageChangeBuilding = (event: any) => {
        const inputPicherPortableBuildings = document.createElement('input');
        inputPicherPortableBuildings.type = 'file';
        inputPicherPortableBuildings.accept = "image/png, image/jpeg, iamge/jpg,  iamge/jpg2"
        inputPicherPortableBuildings.multiple = true
        inputPicherPortableBuildings.click();
        inputPicherPortableBuildings.onchange = (event: any) => {
            this.PicherPortableBuilding(event)
        }
    };

    handleCloseCreateModal = () => {
        this.setState({createConfirmModal: false}, () => {
            this.props.navigation?.history?.push('/CompanyEmployee/complexlisting')
        })
      }

    PicherPortableBuilding = (event: any) => {
        const uplodeFilesBuilding = Array.from(event.target.files);
        uplodeFilesBuilding.forEach(async (file: any) => {
            if (file.size < 2000000) {
                const base64 = await this.convertBase64(file)
                this.setState({
                    imagesBuilding: [...this.state.imagesBuilding, base64],
                })
            }
        })
    }

    convertBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    picherPortablesComplex = (event: any) => {
        const fileBuilding = event.target.files[0];
        if (fileBuilding) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    selectedFile: fileBuilding,
                    imageURLComplex: event.target.result,
                });
            };
            reader.readAsDataURL(fileBuilding);
        }
    }

    sharedCoreMembers = () => {
        this.setState(prevState => ({
            coreMembers: !prevState.coreMembers,
        }));
    };

    handleImageSharedChangeBuilding = (event: any) => {
        const inputPicherPortableComplex = document.createElement('input');
        inputPicherPortableComplex.type = 'file';
        inputPicherPortableComplex.accept = "image/png, image/jpeg, iamge/jpg"
        inputPicherPortableComplex.multiple = true
        inputPicherPortableComplex.click();
        inputPicherPortableComplex.onchange = (event: any) => {
            this.picherPortableSharedComplex(event)
        }
    };

    findPersonPopup = () => {
        this.setState(prevState => ({
            findPopupOpen: !prevState.findPopupOpen,
        }));
    };

    sharedCreateNewMember = () => {
        this.setState(prevState => ({
            createNewMember: !prevState.createNewMember,
        }));
    };

    picherPortableSharedComplex = (event: any) => {
        const uplodeFilesArrayComplex = Array.from(event.target.files);
        uplodeFilesArrayComplex.forEach(async (file: any) => {
            if (file.size < 2000000) {
                const base64 = await this.convertBase64(file)
                this.setState({
                    imagesSharedFacilityBuilding: [...this.state.imagesSharedFacilityBuilding, base64],
                })
            }
        })
    }

    handleFileChangeComplex = (event: any) => {
        const inputPicherBasicDetailsBuiding = document.createElement('input');
        inputPicherBasicDetailsBuiding.type = 'file';
        inputPicherBasicDetailsBuiding.accept = "image/png, image/jpeg, image/jpg, image/jpg4"
        inputPicherBasicDetailsBuiding.multiple = true
        inputPicherBasicDetailsBuiding.click();
        inputPicherBasicDetailsBuiding.onchange = (event: any) => {
            this.picherPortablesComplexBuilding(event)
        }
    };

    picherPortablesComplexBuilding = (event: any) => {
        const fileBuilding = event.target.files[0];
        if (fileBuilding) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    selectedFileBuliding: fileBuilding,
                    imageURLBuilding: event.target.result,
                });
            };
            reader.readAsDataURL(fileBuilding);
        }
    }

    handleChangePage = (event: any, newPage: any) => {
        this.setState({ page: newPage - 1 });
    };

    handleFileChangesFloorPlanBuilding = (event: any) => {
        const inputPicherPortableComplex = document.createElement('input');
        inputPicherPortableComplex.type = 'file';
        inputPicherPortableComplex.accept = ".pdf, .doc, .docx"
        inputPicherPortableComplex.multiple = true
        inputPicherPortableComplex.click();
        inputPicherPortableComplex.onchange = (event: any) => {
            this.handleFileFloorPlanComplex(event)
        }
    };
    sharedFacilityPopupBuilding = () => {
        this.setState(prevState => ({
            facilityPopupBuilding: !prevState.facilityPopupBuilding,
        }));
    };

    handleFileFloorPlanComplex = (event: any) => {
        const file = event.target.files[0];
        const imagesArrays = [...this.state.selectedFilePdfFloorPlanBuilding];
        imagesArrays.push(file);
        this.setState({ selectedFilePdfFloorPlanBuilding: imagesArrays });
    }

    handleRemoveFileFloorPlanComplex = (fileIndex: any) => {
        const updatedFiles = this.state.selectedFilePdfFloorPlanBuilding.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfFloorPlanBuilding: updatedFiles });
    };

    handleCloseOkBuilding = () => {
        this.setState({ open: false });
    };

    handleCloseBuilding = () => {
        this.setState({ openBuilding: false });
    };

    multipleButton = () => {
        return (
            <Box style={{ display: 'flex', justifyContent: "end", flexWrap: 'wrap' }}>
                {this.state.stepBuilding > 0 && <Grid style={{ marginTop: "30px", marginRight: '20px' }}>
                    <Box style={{}}>
                        <Button style={{ color: "#2B6FED", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize', width: '177px', height: '56px', border: '1px solid #2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center' }} onClick={this.handleBackBuilding}><span className="bold-text">PREV</span></Button>
                    </Box>
                </Grid>}
                <Box >
                    {this.state.stepBuilding < 6 ?
                        <Grid style={{ marginTop: "30px", }}>
                            <Button style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} data-test-id="handleNextComplex" onClick={this.handleNextBuilding}><span className="bold-text">NEXT</span></Button>
                        </Grid> : <Grid style={{ marginTop: "30px", }}>
                            <Button onClick={this.handleClickOpen} style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} ><span className="bold-text">Save</span></Button>
                        </Grid>}
                </Box>
            </Box>
        )
    }

    handleClickOpenBuilding = () => {
        this.setState({ openBuilding: true });
        this.findPersonPopup()
    };
    
    handleChangeBuilding = (event: any) => {
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };


    imgUplodeAvtar = () => {
        return (
                <img style={{ width: '100px', border: '1px solid black', height: '100px', borderRadius: '100%', }} src={this.state.imageURLBuilding !== "" ? this.state.imageURLBuilding : userProfile} />
        )
    }
}
// Customizable Area End