// Customizable Area Start
import React from "react";
import ContractDetailsController, { Props, TFunction } from "./LeaseManagementContractDetailsController.web";
import {
    Box, styled, Breadcrumbs,
    Button,
    Divider,
    Grid,
    Popover,
    Typography,
    IconButton,
    Paper,
    Modal,
    MenuItem,
} from "@material-ui/core";
import MainScreenContainer from "../../dashboard/src/Company Employee/MainScreen.web";
import MenuIcon from "@material-ui/icons/MoreVert";
import { BuildingIcon, CalenderIcon, CircleCrossIcon, DownloadIcon, ProfileIcon, UnitIcon } from "./assets";
import ShareDocumentModal from "../../../components/src/DocumentComponent/ShareModal.web";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
// Customizable Area End

// Customizable Area Start

import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class LeaseManagementContractDetails extends ContractDetailsController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    renderTerminateContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.terminateContractModal}>
            <ContractDetailsTerminateModal >
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="inherit" id={"LeaseManagementId"} onClick={() => this.handleExistRecordNavigation("LeaseManagment")} data-testId="issueNewLeaseTestID">
                        {t("Lease Management")}
                    </Typography>
                    <Typography className="issueNewLeaseText">{t("Contract Details")}</Typography>
                </Breadcrumbs>
                <Paper className='TerminateModalChild'>
                    <img src={CircleCrossIcon} alt="" className="terminateModalIcon" />
                    <Typography className='TerminateModalChildHeading' data-testId={"terminateContractModalHeadingTestID"}>{t("Terminate Contract")}</Typography>
                    <Typography className='TerminateModalChildText'>{t(`Are you sure you want to terminate the contract with ${this.state.contractDetails.contract.data.attributes.tenant?.full_name} ? Once terminated it can't be reactivate.1`)}</Typography>
                    <Box className='TerminateModalChildButton'>
                        <Button variant='outlined' className='TerminateModalChildButton1' onClick={this.handleCloseTerminateContractModal} data-testId={"cancelBtnTerminateContractModalTestId"}>{t("Cancel")}</Button>
                        <Button variant='contained' className='TerminateModalChildButton2' onClick={this.terminateContract} data-testId={"terminateContractConfirmBtnTestId"}>{t("Confirm")}</Button>
                    </Box>
                </Paper>
            </ContractDetailsTerminateModal>
        </Modal>
    }

    renderContractDetailsData = () => {
        const { t }: { t: TFunction } = this.props;
        return <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ContractDetailsBoxComplexContainer">
                        <img
                            className="ContractDetailsIconsComplexContainer"
                            src={BuildingIcon}
                        />
                        <Box>
                            <Typography className="ContractDetailsHeaderComplexContainer">
                                {t("Complex")}
                            </Typography>
                            <Typography className="ContractDetailsHeader2ComplexContainer">
                                {this.state.contractDetails.contract.data.attributes.society_management.name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ContractDetailsBoxBuildingContainer">
                        <img
                            className="ContractDetailsIconsBuildingContainer"
                            src={BuildingIcon}
                        />
                        <Box>
                            <Typography className="ContractDetailsHeaderBuildingContainer">
                                {t("Building")}
                            </Typography>
                            <Typography className="ContractDetailsHeader2BuildingContainer">
                            {this.state.contractDetails.contract.data.attributes.building_name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ContractDetailsBoxUnitContainer">
                        <img
                            className="ContractDetailsIconsUnitContainer"
                            src={UnitIcon}
                        />
                        <Box>
                            <Typography className="ContractDetailsHeaderUnitContainer">
                                {t("Unit")}
                            </Typography>
                            <Typography className="ContractDetailsHeader2UnitContainer">
                            {this.state.contractDetails.contract.data.attributes.unit_name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ContractDetailsBoxOwnerContainer">
                        <img
                            className="ContractDetailsIconsOwnerContainer"
                            src={ProfileIcon}
                        />
                        <Box>
                            <Typography className="ContractDetailsHeaderOwnerContainer">
                                {t("Owner")}
                            </Typography>
                            <Typography className="ContractDetailsHeader2OwnerContainer">
                            {this.state.contractDetails.contract.data.attributes.landlord_name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ContractDetailsBoxTenantContainer">
                        <img
                            className="ContractDetailsIconsTenantContainer"
                            src={ProfileIcon}
                        />
                        <Box>
                            <Typography className="ContractDetailsHeaderTenatnContainer">
                                {t("Tenant")}
                            </Typography>

                            <Typography className="ContractDetailsHeader2TenantContainer">

                                {this.state.contractDetails.contract.data.attributes.tenant_name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ContractDetailsBoxContractDurationContainer">
                        <img
                            className="ContractDetailsIconsContractDurationContainer"
                            src={CalenderIcon}
                        />
                        <Box>
                            <Typography className="ContractDetailsHeaderContractDurationContainer">
                                {t("Contract Duration")}
                            </Typography>
                            <Typography className="ContractDetailsHeader2ContractDurationContainer">
                            {this.state.contractDetails.contract.data.attributes.agreement_duration + " months"}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box className="ContractDetailsBoxExpiryDateContainer">
                        <img
                            className="ContractDetailsIconsExpiryDateContainer"
                            src={CalenderIcon}
                        />
                        <Box>
                            <Typography className="ContractDetailsHeaderExpiryDateContainer">
                                {t("ExpiryDate")}
                            </Typography>
                            <Typography className="ContractDetailsHeader2ExpiryDateContainer">
                            {this.getFutureDate(this.state.contractDetails.contract.data.attributes.expire_on)}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const open = Boolean(this.state.anchor);
        const popoverId = open ? "simple-popover" : undefined;
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreenContainer>
                <ContactDetailsContainer>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography id="LeaseManagementId" onClick={() => this.handleExistRecordNavigation("LeaseManagment")} className="ContactDetailsBreadcrumbsChild1" data-testId={"leaseManagementHeadingTestId"}>
                                {t("Lease Management")}
                            </Typography>
                            <Typography className="ContactDetailsBreadcrumbsChild2">
                                {t("Contract Details")}
                            </Typography>
                        </Breadcrumbs>
                        <Box className="pageTitleContainer">
                            <Typography className="ContactDetailsHeading" data-testId={"contractDetailsHeadingTestId"}>
                                {t("Contract Details")}
                            </Typography>
                            {
                                this.state.contractDetails.contract.data.attributes.status === "Terminated" &&
                                <Typography variant="subtitle1" className="terminatedText">
                                    {t("Terminated")}
                                </Typography>
                            }
                        </Box>
                        <Paper className="ContactDetailsHeadingContainer">
                            <Box className="ContactDetailsHeadingBox">
                                <Typography className="ContactDetailsHeadingText">
                                    Contract 1
                                </Typography>
                                <Box className="ContractDetailsBoxIcons">
                                        <img
                                            className="ContractDetailDownloadIconStyled"
                                            src={DownloadIcon}
                                            alt={DownloadIcon}
                                            data-testId={"downloadContractBtnTestID"}
                                            onClick={() => this.handleDownloadFile(this.state.shareUrl)}
                                        />
                                    <IconButton onClick={this.handleClick} data-testId={"contractDetailsMenuIconTestId"}>
                                        <MenuIcon />
                                    </IconButton>
                                    <Popover
                                        onClose={this.handleClose}
                                        id={popoverId}
                                        anchorEl={this.state.anchor}
                                        open={open}
                                        data-testId={"menuPopoverCloseTestId"}
                                        anchorOrigin={{
                                            horizontal: "left",
                                            vertical: "bottom",
                                        }}
                                        transformOrigin={{
                                            horizontal: "right",
                                            vertical: "top",
                                        }}
                                    >
                                        <MenuItem onClick={this.handleShareModal} data-testId={"shareContractBtnTestID"}>
                                            {t("Share Contract")}
                                        </MenuItem>
                                    </Popover>
                                </Box>
                            </Box>
                            <Divider />
                            <Box className="ContractDetailsContent">
                                {this.renderContractDetailsData()}
                            </Box>
                            <Box className="ContractDetailsContentBox">
                                <Typography className="ContractDetailsContentHeading">
                                    Recident Rental Lease Agreement
                                </Typography>
                                <Typography className="ContractDetailsContentHeadingText">

                                    <div dangerouslySetInnerHTML={{ __html: this.state.contractDetails.contract.data.attributes.contract_template }}></div>

                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Box className="ContractDetailsButtonGroup">
                        {
                            this.state.contractDetails.contract.data.attributes.status !== "Terminated" &&
                            <Button variant="text" className="ContractDetailsButton1" onClick={this.handleOpenTerminateContractModal} data-testId={"terminateContractTestId"}>
                                {t("Terminate")}
                            </Button>
                        }
                        <Button variant="text" className="ContractDetailsButton1" onClick={() => this.handleExistRecordNavigation("LeaseManagment")} data-testId={"closeBtnTestId"}>
                            {t("Close")}
                        </Button>
                        {
                            this.state.contractDetails.contract.data.attributes.status !== "Terminated" &&
                            <Button variant="contained" className="ContractDetailsButton2" onClick={() => this.handleExistRecordNavigation("IssueNewContractReview")} data-testId={"renewContractTestId"}>
                                {t("Renew contract")}
                            </Button>
                        }
                    </Box>
                    {this.renderTerminateContractModal()}
                    <ShareDocumentModal
                        language={"English"}
                        isOpen={this.state.isShareModalOpen}
                        handleClose={this.handleShareModal}
                        heading="Share"
                        documentURL={this.state.shareUrl}
                        data-testId={"shareContractModalTestId"}
                    />
                    <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"errorAlertTestId"} />
                    <AlertSuccess show={this.state.showSuccessMessage} handleClose={this.hanldeCloseSuccessAlert} message={this.state.successMessage}  data-testId={"successAlertTestId"}/>
                </ContactDetailsContainer>
            </MainScreenContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementContractDetails);
export { LeaseManagementContractDetails };
const ContactDetailsContainer = styled(Box)({
    padding: 30,
    background: "#f4f7ff",
    overflow: "scroll",
    "& .ContactDetailsHeading": {
        fontSize: "30px",
        fontWeight: 800,
        marginTop: 10,
        paddingBottom: 20,
    },
    "& .ContactDetailsBreadcrumbsChild1": {
        color: "#676972",
    },
    "& .ContactDetailsHeadingContainer": {
        borderRadius: 10,
    },

    "& .ContactDetailsBreadcrumbsChild2": {
        color: "#6d84bc",
    },
    "& .ContactDetailsHeadingBox": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 15,
        paddingLeft: 20,
    },
    "& .ContractDetailDownloadIconStyled": {
        height: "30px",
        width: "33px",
        marginRight: 15,
        cursor: "pointer"
    },
    "& .ContactDetailsHeadingText": {
        fontWeight: 800,
        fontSize: "25px",
    },

    "& .ContractDetailsButton1": {
        fontWeight: 800,
        color: "#3274ce",
        border: "1px solid #3274ce",
        marginRight: 15,
        paddingLeft: 50,
        paddingRight: 50,
    },
    "& .ContractDetailsButton2": {
        color: "white",
        background: "#3274ce",
        padding: "6px 40px",
        fontWeight: 800,
    },
    "& .ContractDetailsContentHeading": {
        fontWeight: 800,
        padding: "0px 20px"
    },
    "& .ContractDetailsContentHeadingText": {
        marginTop: 10,
        color: "gray",
        textAlign: "justify",
        padding: "20px"
    },
    "& .ContractDetailsButtonGroup": {
        float: "right",
        paddingTop: 50,
    },
    "& .ContractDetailPopover": {
        right: "700px",
        position: "absolute",
        top: "300px",
    },
    "& .ContractDetailsHeaderComplexContainer": {
        cursor: "pointer",
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeaderContainer": {
        cursor: "pointer",
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeaderBuildingContainer": {
        cursor: "pointer",
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeaderUnitContainer": {
        cursor: "pointer",
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeaderOwnerContainer": {
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeaderTenatnContainer": {
        cursor: "pointer",
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeaderContractDurationContainer": {
        cursor: "pointer",
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeaderExpiryDateContainer": {
        cursor: "pointer",
        fontWeight: "500",
        color: "#fcd2ab",
    },
    "& .ContractDetailsHeader2Container": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsHeader2ComplexContainer": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsHeader2BuildingContainer": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsHeader2UnitContainer": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsHeader2OwnerContainer": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsHeader2TenantContainer": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsHeader2ContractDurationContainer": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsHeader2ExpiryDateContainer": {
        cursor: "pointer",
        fontWeight: "bold",
    },
    "& .ContractDetailsContent": {
        display: "flex",
        flexWrap: "wrap",
        padding: 20,
    },
    "& .ContractDetailsBoxComplexContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ContractDetailsBoxBuildingContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ContractDetailsBoxUnitContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ContractDetailsBoxOwnerContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ContractDetailsBoxTenantContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ContractDetailsBoxContractDurationContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ContractDetailsBoxExpiryDateContainer": {
        gap: "10px",
        display: "flex",
    },
    "& .ContractDetailsBoxIcons": {
        alignItems: "center",
        display: "flex",
    },
    "& .ContractDetailsIconsComplexContainer": {
        height: 25,
        width: 25,
    },
    "& .ContractDetailsIconsBuildingContainer": {
        height: 25,
        width: 25,
    },
    "& .ContractDetailsIconsUnitContainer": {
        height: 25,
        width: 25,
    },
    "& .ContractDetailsIconsOwnerContainer": {
        height: 25,
        width: 25,
    },
    "& .ContractDetailsIconsTenantContainer": {
        height: 25,
        width: 25,
    },
    "& .ContractDetailsIconsContractDurationContainer": {
        height: 25,
        width: 25,
    },
    "& .ContractDetailsIconsExpiryDateContainer": {
        height: 25,
        width: 25,
    },
    "& .pageTitleContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .terminatedText": {
        color: "red"
    }
});

const ContractDetailsTerminateModal = styled(Box)({
    justifyContent: 'center',
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    "& .terminateModalIcon": {
        marginBottom: "20px"
    },
    "& .issueNewLeaseText": {
        color: "#537ad2 !important"
    },
    '& .TerminateModalChild': {
        display: 'flex',
        padding: "50px",
        flexDirection: 'column',
        alignItems: 'center',
        paddingRight: '50px',
        justifyContent: 'center',
        borderRadius: "10px",
        paddingLeft: '50px',
    },

    '& .TerminateModalChildHeading': {
        fontSize: 25,
        marginBottom: 10,
        fontWeight: 800,
    },
    '& .TerminateModalChildButton': {
        marginTop: 40
    },
    '& .TerminateModalChildText': {
        textAlign: 'center',
        color: '#5f6062',
        width: "420px"
    },

    '& .TerminateModalChildButton2': {
        color: 'white',
        background: '#2f6ded',
        padding: '15px 40px 15px 40px',
        marginLeft: 15,
        borderRadius: 10,
        fontWeight: 700,
    },
    '& .TerminateModalChildButton1': {
        color: '#2f6ded',
        border: '1px solid #2f6ded',
        fontWeight: 700,
        borderRadius: 10,
        padding: '15px 40px 15px 40px',
    },

})


// Customizable Area End