import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    MenuItem,
    Typography,
    Select,
    InputAdornment,
    Button,
    TableContainer,
    TableHead,
    Table,
    Paper,
    TableCell,
    TableRow,
    TableBody,
    Divider,
    styled,
    IconButton,
    Link,
} from "@material-ui/core";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import SearchInput from "../../../components/src/CommonComponents/SearchInput.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Pagination from "@material-ui/lab/Pagination";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
import { logo } from "./assets";

// Customizable Area End

import SubscriptionReportsController, {
    Props,
} from "./SubscriptionReportsController.web";

// Customizable Area Start
export class SubscriptionReports extends SubscriptionReportsController {
    constructor(props: Props) {
        super(props);
    }

    subscriptionTable = () => {
        const { t,i18n }: any = this.props;
        const language = i18n.language;
        return (
            <>
                {this.state.subscriptionReports?.map((subscription: { id: string; attributes: { complex: { name: string }; building: { name: string }; name: { full_name: string }; role: { name: string }; account_manger: string; expiry_date: string }; }, index: number) => (
                    <TableRow>
                        <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>{subscription.attributes?.complex?.name ? subscription.attributes?.complex?.name : "-"}</TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                            {subscription.attributes?.building?.name ? subscription.attributes?.building?.name : "-"}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                            {subscription.attributes?.name?.full_name ? subscription.attributes?.name?.full_name : "-"}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                            {subscription.attributes?.role?.name ? subscription.attributes?.role?.name : "-"}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                            {subscription.attributes.account_manger ? subscription.attributes.account_manger : "-"}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>
                            {subscription.attributes.expiry_date}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
                            <Menu menuButton={<IconButton><MoreVertIcon /></IconButton>}>
                                <Link href={`/CompanyEmployee/BuildingDetailsPage/${subscription.id}`}>
                                    <MenuItem
                                        className="ce-pm-view-report"
                                        data-test-id="viewProfileId"
                                    >
                                        {t("View building details")}
                                    </MenuItem>
                                </Link>
                                <Link href={`/CompanyEmployee/SubsciptionDetails/${subscription.id}`}>
                                    <MenuItem
                                        className="ce-pm-view-report"
                                        data-test-id="viewProfileId"
                                    >
                                        {t("View subscription details")}
                                    </MenuItem>
                                </Link>
                            </Menu>

                        </TableCell>
                    </TableRow>
                ))}
            </>
        )
    }

    downloadTable = () => {
        const { i18n }: any = this.props;
        const language = i18n.language;
        return (
            <>
                {this.state.subscriptionReports.map((reportData: { attributes: {city: string; building: { city: string }; name: { full_name: string, last_name: string }; role: { types: string }; expiry_date: string }; }, index: number) => {
                    return (
                        <TableRow>
                            <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                            <TableCell align={languageCondition(language, "right", "left")}>{reportData.attributes.city ? reportData.attributes.city : "-"}</TableCell>
                            <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                                {reportData?.attributes?.name?.full_name ? reportData?.attributes?.name?.full_name : "-"}
                            </TableCell>
                            <TableCell align={languageCondition(language, "right", "left")}>
                                {reportData?.attributes?.role?.types ? reportData?.attributes?.role?.types : "-"}
                            </TableCell>
                            <TableCell align={languageCondition(language, "right", "left")}>
                                {reportData?.attributes?.expiry_date}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </>
        )
    }



// Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as any
        const { t,i18n }: any = this.props;
        const language = i18n.language;
        // Customizable Area End
            return (
                // Customizable Area Start
                <CEHeaderComponentWeb>
                    <SubscriptionReportsCss>
                        {!this.state.viwePage && <BoxView className="containerBox">
                            <BoxView className="navigation">
                                <Typography variant="body1" className="">
                                    <Link href="/CompanyEmployee/ReportsStatistics">
                                        {t("Documents & Reports")} / {t("Reports")}
                                    </Link>{" "}
                                    /{" "}
                                    <BoxView component="span">
                                        {t("Subscription Reports")}
                                    </BoxView>{" "}
                                    /{" "}<BoxView component="span" className = "tabColor">
                                    {t(this.state.tags)}
                                    </BoxView>
                                </Typography>

                            </BoxView>
                            <br />
                            <BoxView className="header-title">
                                <Typography variant='h4' className="bold-text">{t("Subscription Reports")}</Typography>
                                <Typography className="bold-text downloadButton" onClick={() => this.goTosubscriptionReportDetailsPage()} data-test-id="downloadButtonId1">{t("DOWNLOAD REPORT")}</Typography>
                            </BoxView>
                            <BoxView >
                                <Grid container xs={12} spacing={2}>
                                    <Grid item>
                                        <Typography data-test-id="TrailId" style={this.state.tags === "Trail Subscriptions to Expire" ? addAnotherUnit.selectTag : addAnotherUnit.unSelectTage} className="bold-text" onClick={() => this.handleTag("Trail Subscriptions to Expire")}>{t("Trail Subscriptions to Expire")}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <BoxView>
                                            <Typography data-test-id="activeId" style={this.state.tags === "Active Subscriptions to Expire" ? addAnotherUnit.selectTag : addAnotherUnit.unSelectTage} className="bold-text" onClick={() => this.handleTag("Active Subscriptions to Expire")}>{t("Active Subscriptions to Expire")}</Typography>
                                        </BoxView>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={2} className="drop-down">
                                    <Grid item>
                                        <Select
                                            displayEmpty
                                            className="selectmenu"
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            disableUnderline
                                            onChange={(event) => this.handleCountryFilterChange(event)}
                                            id="select-city1"
                                            value={this.state.individualbuilding}
                                            data-test-id="buildingsdropId"
                                        >
                                            <MenuItem disabled value="" id="selectStatusBudgetReport">
                                                {t("Complex/Individual Buildings")}
                                            </MenuItem>
                                            <MenuItem value="Complex" id="selectStatusBudgetReport">
                                                {t("Complex")}
                                            </MenuItem>
                                            <MenuItem value="Individualbuilding" id="selectStatusBudgetReportAll">
                                                {t("Individual building")}
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        <BoxView>
                                            <Select
                                                disableUnderline
                                                IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                                displayEmpty
                                                data-test-id="selectCountryId"
                                                className="selectmenu"
                                                value={this.state.selectedCountry}
                                                placeholder="Select Country"
                                                id="select-Country"
                                                onChange={(event) => this.handleSelectCountryChange(event)}
                                            >
                                                <MenuItem
                                                    value=""
                                                    disabled
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Select Country")}
                                                </MenuItem>
                                                <MenuItem
                                                    value="All"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                                {this.state.selectCountry?.length > 0 &&
                                                    this.state.selectCountry?.map((item: string) => {
                                                        return (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </BoxView>
                                    </Grid>
                                    <Grid item>
                                        <Select displayEmpty
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            data-test-id="selectCityId"
                                            value={this.state.selectedCity}
                                            disableUnderline
                                            className="selectmenu"
                                            id="select-city1"
                                            onChange={(event) => this.handleSelectCityChange(event)}
                                        >
                                            <MenuItem
                                                disabled
                                                value=""
                                                id="selectStatusBudgetReport"
                                            >
                                                {t("Select City")}
                                            </MenuItem>
                                            <MenuItem
                                                    value="All"
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                            {this.state.selectCity?.length > 0 &&
                                                this.state.selectCity?.map((item: string) => {
                                                    return (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Grid>

                                    <Grid item>
                                        <Select displayEmpty
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            value={this.state.selectedComplex}
                                            disableUnderline
                                            id="select-city1"
                                            onChange={(event) => this.handleSelectComplexChange(event)}
                                            className="selectmenu"
                                            data-test-id="selectComplexId"
                                        >
                                            <MenuItem
                                                disabled
                                                value=""
                                                id="selectComplexId"
                                            >
                                                {t("Select Complex")}
                                            </MenuItem>
                                            <MenuItem
                                                    value="All"
                                                    id="selectStatusBudgetReportAll"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                            {this.state.selectComplex?.length > 0 &&
                                                this.state.selectComplex?.map((item: { id: string; name: string }) => {                                                    
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        <Select displayEmpty
                                            id="selectBuilding"
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            disableUnderline
                                            className="selectmenu"
                                            value={this.state.selectedBuilding}
                                            onChange={(event) => this.handleSelectBuildingChange(event)}
                                            data-test-id="slctBuildingId"
                                        >
                                            <MenuItem
                                                value=""
                                                id="selectStatusBudgetReport"
                                                disabled
                                            >
                                                {t("Select Building")}
                                            </MenuItem>
                                            <MenuItem
                                                    id="selectStatusBudgetReportAll"
                                                    value="All"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                            {this.state.selectBuilding?.length > 0 &&
                                                this.state.selectBuilding?.map((item: { id: string; name: string }) => {
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Grid>

                                    <Grid item>
                                        <Select displayEmpty
                                            id="selectBuilding"
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            disableUnderline
                                            className="selectmenu"
                                            value={this.state.selectedRole}
                                            data-test-id="RoleId"
                                            onChange={(event) => this.handleRoleChange(event)}
                                        >
                                            <MenuItem
                                                disabled
                                                value=""
                                            >
                                                {t("Select Role")}
                                            </MenuItem>
                                            <MenuItem
                                                    value="All"
                                                    id="selectStatusBudgetReportAll"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                            {this.state.role?.length > 0 &&
                                                this.state.role?.map((item:{ id: string; attributes:{name:string}} ) => {
                                                    return (
                                                        <MenuItem key={item.attributes.name} value={item.attributes.name}>
                                                            {item.attributes.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            displayEmpty
                                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                            id="select-city1"
                                            disableUnderline
                                            className="selectmenu"
                                            data-test-id="SubscriptionId"
                                            value={this.state.selectSubscription}
                                            onChange={(event) => this.handleUserrrSubscription(event)}
                                        >
                                            <MenuItem disabled value="" id="selectStatusBudgetReport">
                                                {t("Select SubscriptionType")}
                                            </MenuItem>
                                            <MenuItem
                                                    value="All"
                                                    id="select-city1"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                            {this.state.userSubscription?.length > 0 &&
                                                this.state.userSubscription?.map((item:{ id: string; attributes:{title:string}}) => {
                                                    return (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.attributes.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Grid>
                                    <Grid item>
                                        <BoxView>
                                            <Select
                                                disableUnderline
                                                IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                                displayEmpty
                                                data-test-id="selectStatusBudgetId"
                                                className="selectmenu"
                                                value={this.state.selectedSalse}
                                                onChange={(event) => this.handleSaleChange(event)}
                                                id="select-Country"
                                            >
                                                <MenuItem
                                                    disabled
                                                    id="selectStatusBudgetReport"
                                                    value=""
                                                >
                                                    {t("Sales Manager")}
                                                </MenuItem>
                                                <MenuItem
                                                    value="All"
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                                {this.state.salseManage?.length > 0 &&
                                                    this.state.salseManage?.map((item: { id: string; first_name:string}) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.first_name}>
                                                                {item.first_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </BoxView>
                                    </Grid>
                                    <Grid item>
                                        <BoxView>
                                            <Select
                                                disableUnderline
                                                IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                                                displayEmpty
                                                data-test-id="selectAccountManagerId"
                                                className="selectmenu"
                                                value={this.state.account}
                                                onChange={(event) => this.handleAccountChange(event)}
                                                id="select-Country"
                                            >
                                                <MenuItem
                                                    disabled
                                                    id="selectStatusBudgetReport"
                                                    value=""
                                                >
                                                    {t("Account Manager")}
                                                </MenuItem>
                                                <MenuItem
                                                    id="selectStatusBudgetReportAll"
                                                    value="All"
                                                >
                                                    {t("All")}
                                                </MenuItem>
                                                {this.state.accountManager?.length > 0 &&
                                                    this.state.accountManager?.map((item:  { id: string; first_name:string}) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.first_name}>
                                                                {item.first_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </BoxView>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            className="mybtn"
                                            onClick={()=>this.handleSearch()}
                                            data-test-id="saveButton"
                                        >
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                            {t("Search")}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid className="table-box-card">
                                    <Grid item sm={12} md={12} xs={12}>
                                        <BoxView className="table-top">
                                            <h4 className="bold-text">{t(this.state.tags)}</h4>
                                            <BoxView className="filter-head-box">
                                                <BoxView className="sort-by">
                                                    <Grid item>
                                                        <BoxView>
                                                            <Select
                                                                disableUnderline
                                                                displayEmpty
                                                                data-test-id="filterId"
                                                                className="filter"
                                                                value={this.state.sort}
                                                                onChange={(event) => this.handleSortChange(event)}
                                                                id="select-Country"
                                                            >
                                                                <MenuItem
                                                                    disabled
                                                                    id="selectStatusBudgetReport"
                                                                    value=""
                                                                >
                                                                    {t("Sort By")}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    data-test-id="descendingId"
                                                                    value="descending"
                                                                >
                                                                    {t("descending")}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    data-test-id="ascendingId"
                                                                    value="ascending"
                                                                >
                                                                    {t("ascending")}
                                                                </MenuItem>
                                                            </Select>
                                                        </BoxView>
                                                    </Grid>
                                                    <SearchInput
                                                        placeholder={t("Search")}
                                                        data-test-id="filterId2"
                                                        onChange={(event: React.ChangeEvent<{
                                                            value:
                                                            unknown;
                                                        }>) => this.handleFilter(event)}
                                                        t={t}
                                                    />
                                                </BoxView>
                                            </BoxView>
                                        </BoxView>
                                        <Divider />
                                        <TableContainer component={Paper} className="table-container">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                                                        <TableCell
                                                            align={languageCondition(language, "right", "left")} className="bold-text"
                                                        >
                                                            {t("Complex")}
                                                        </TableCell>
                                                        <TableCell
                                                            className="bold-text" align={languageCondition(language, "right", "left")}
                                                        >
                                                            {t("Building")}
                                                        </TableCell>
                                                        <TableCell
                                                            className="bold-text" align={languageCondition(language, "right", "left")}
                                                        >
                                                            {t("Name")}
                                                        </TableCell>
                                                        <TableCell
                                                            align={languageCondition(language, "right", "left")}
                                                            className="bold-text"
                                                        >
                                                            {t("Role")}
                                                        </TableCell>
                                                        <TableCell
                                                            className="bold-text"
                                                            align={languageCondition(language, "right", "left")}
                                                        >
                                                            {t("Account Manager")}
                                                        </TableCell>
                                                        <TableCell
                                                            className="bold-text"
                                                            align={languageCondition(language, "right", "left")}
                                                        >
                                                            {t("Expires in")}
                                                        </TableCell>
                                                        <TableCell align={languageCondition(language, "right", "left")} />
                                                    </TableRow>
                                                </TableHead>
                                                {this.state.subscriptionReports ? <TableBody>
                                                    {this.subscriptionTable()}
                                                </TableBody> : <Typography className="tableError">Data Not Found</Typography>}
                                            </Table>
                                        </TableContainer>
                                        <BoxView className="table-bottom">
                                            <p>
                                                {t("Showing")} <span className="current-page">{this.state.subscriptionReports?.length}</span> {t("of")}{" "}
                                                <span className="total-page">{this.state.totalCount}</span>{" "}
                                                {t("results")}
                                            </p>
                                            <BoxView>
                                                <Pagination
                                                    id="PaginationModule"
                                                    count={this.state.totalPages}
                                                    onChange={this.handlePageChange}
                                                    variant="outlined"
                                                    shape="rounded"
                                                    data-test-id="paginationID"
                                                    style={{ marginRight: "20px" }}
                                                />
                                            </BoxView>

                                        </BoxView>
                                    </Grid>
                                </Grid>
                            </BoxView>
                        </BoxView>}

                        {this.state.viwePage && <BoxView className="containerBox">
                            <BoxView className="navigation">
                                <Typography variant="body1" className="">
                                    <Link href="/CompanyEmployee/dashboard">
                                        {t("Documents & Reports")} /
                                    </Link>{" "}
                                    <Link href="/CompanyEmployee/ReportsStatistics">
                                        {t("Reports")} /
                                    </Link>{" "}
                                    <Link href="/CompanyEmployee/SubscriptionReports">{t("Subscription Reports")}</Link> /{" "}
                                    <BoxView component="span" className = "tabColor">
                                        {t(this.state.tags)}
                                    </BoxView>
                                </Typography>
                            </BoxView>
                            <BoxView>
                                <BoxView className="header-titleBox">
                                    <Typography variant='h4' className="bold-text">{t("Subscription Reports")} </Typography>
                                    <Typography className="bold-text downloadButton" onClick={()=> this.handleDownloadReport()} data-test-id="downloadButtonId2">{t("DOWNLOAD REPORT")}</Typography>
                                </BoxView>
                                <BoxView className="main">
                                    <BoxView className="heading">
                                        <BoxView>
                                        <img src={logo.default} className="imageBox" height={49} width={126} />
                                        <Typography className="bold-text">{t("TENANT INTERNATIONAL")}</Typography>
                                        
                                        </BoxView>
                                        <Typography className="bold-text">{this.state.reportGgenerated}</Typography>
                                    </BoxView>
                                    <div className="detail">
                                        <Typography variant="h5" className="bold-text">{t(this.state.tags)}</Typography>
                                    </div>
                                    <BoxView className="table-Data">
                                        <TableContainer component={Paper} className="table-container">
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell 
                                                            align={languageCondition(language, "right", "left")}>
                                                            #
                                                        </TableCell>
                                                        <TableCell 
                                                            align={languageCondition(language, "right", "left")} 
                                                            className="bold-text">
                                                            {t("City")}
                                                        </TableCell>
                                                        <TableCell 
                                                            className="bold-text" 
                                                            align={languageCondition(language, "right", "left")}>
                                                            {t("Name")}
                                                        </TableCell>
                                                        <TableCell 
                                                            className="bold-text" align={languageCondition(language, "right", "left")}>
                                                            {t("User Type")}
                                                        </TableCell>
                                                        <TableCell align={languageCondition(language, "right", "left")} 
                                                            className="bold-text">
                                                            {t("Expires In")}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.downloadTable()}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <BoxView className="total-pageBottom">
                                            <Typography>TENANT INTERNATIONAL</Typography>
                                            <Typography>Page 1 of 1</Typography>
                                        </BoxView>
                                    </BoxView>
                                </BoxView>
                            </BoxView>
                        </BoxView>}
                    </SubscriptionReportsCss>
                </CEHeaderComponentWeb>
                // Customizable Area End
            );
        }
}

// Customizable Area Start
export const addAnotherUnit = {
    selectTag: {
        background: "#2B6FED",
        padding: "10px 20px",
        marginTop: "35px",
        color: "#fff",
        borderRadius: "20px",
        cursor: "pointer"
    },
    unSelectTage: {
        background: "#EEF0F8",
        marginTop: "35px",
        color: "#8D8F98",
        padding: "10px 20px",
        borderRadius: "20px",
        cursor: "pointer"
    },
}

const SubscriptionReportsCss = styled(Box)({
    '& .containerBox': {
        padding: "0px 40px"
    },
    '& .subcontainer': {
        padding: '0px 40px',
    },
    '& .selectmenu': {
        border: '1px solid #F0F0F0',
        padding: '7px',
        background: '#fff',
        borderRadius: '15px',
        borderBottom: 'none',
        width: '100%',
        gap: '10px',
        marginTop: '10px',
    },
    '& .titleBox': {
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
    },
    '& .ButtonBox': {
        justifyContent: 'flex-end',
        gap: 15,
        padding: 20,
        display: 'flex',
    },
    '& .cancleButton': {
        fontWeight: 900,
        border: '1px solid #2B6FED',
        color: '#2B6FED',
        padding: '5px 40px',
    },
    '& .confirmButton': {
        background: '#2B6FED',
        fontWeight: 900,
        color: '#fff',
        padding: '5px 40px',
    },
    '& .inputType': {
        padding: '7px',
        border: '1px solid darkgray',
        background: '#fff',
        borderRadius: '15px',
        width: '100%',
        marginTop: '10px',
        gap: '10px',
    },
    '& .mybtn': {
        backgroundColor: '#2D6EED',
        color: 'white',
        padding: '8px 16px',
        marginTop: '11px',
        textTransform: 'capitalize',
    },
    '& .Box': {
        borderRadius: '10px',
        backgroundColor: '#fff',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        paddingLeft: '30px',
        alignItems: 'flex-start',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        marginTop: '25px',
    },
    '& .lable': {
        borderRadius: '20px',
        padding: '5px 15px',
        color: '#EBA370',
        backgroundColor: 'rgba(252, 132, 52, 0.1)',
        fontWeight: 700,
        marginTop: '5px',
        width: 'fit-content',
    },
    '& .searchButtons': {
        background: 'white',
        borderRadius: '5px',
        border: '1px solid #f0f0f0',
        marginLeft: "20px"
    },
    '& .main': {
        padding: '15px',
        backgroundColor: '#fff',
        marginTop:"20px"
    },
    '& .heading': {
        justifyContent: 'space-between',
        display: 'flex',
    },
    '& .detail': {
        backgroundColor: 'rgba(221, 221, 221, 0.38)',
        marginTop: '35px',
        textAlign: 'center',
        color: '#191E26',
        borderRadius: '8px',
        padding: '10px 50px',
    },
    '& .userData': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'space-between',
        height: '83%',
    },
    '& .tblData': {
        marginTop: '20px',
        boxShadow: 'none',
    },
    '& .tblpage': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& .selectTag': {
        padding: '10px 20px',
        background: '#2B6FED',
        color: '#fff',
        marginTop: '35px',
        cursor: 'pointer',
        borderRadius: '20px',
    },
    '& .unSelectTage': {
        marginTop: '35px',
        background: '#EEF0F8',
        color: '#8D8F98',
        borderRadius: '20px',
        padding: '10px 20px',
        cursor: 'pointer',
    },
    '& .downloadReports': {
        padding: '10px 20px',
        color: '#fff',
        background: '#FC8434',
        cursor: 'pointer',
        borderRadius: '8px',
    },
    '& .table-header': {
        padding: "20",
        background: "#fff",
        display: "flex",
        alignItems: "center",
        border: "1px solid rgba(224, 224, 224, 1)",
        justifyContent: "space-between",
    },
    '& .sort-by': {
        display: "flex",
    },
    '& .filter': {
        fontSize: "14px",
        backgroundColor: "white",
        borderRadius: "8px",
        border: "1px solid #F0F0F0",
        padding: "8px 12px",
        fontWeight: 500,
        color: "darkgray",
        minWidth: "168px",
        maxWidth: "168px",
        marginRight:"15px"
    },
    "& .downloadButton": {
        padding: "10px 20px",
        background: "#FC8434",
        color: "#fff",
        borderRadius: "8px",
        cursor: "pointer"
    },
    "& .header-title": {
        display: "flex",
        justifyContent: "space-between",
    },
    '& .header-titleBox': {
        display: "flex", 
        justifyContent: "space-between",
        marginTop:"15px"
    },
    '& .table-Data': {
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '83%',
        display: 'flex',
        marginTop:"15px"
    },
    '& .table-container': {
        marginTop: '20px',
        boxShadow: 'none',
    },
    '& .total-pageBottom': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& .tabColor': {
        color: "rgb(43, 111, 237)"
    },
    '& .drop-down': {
        marginTop: "25px"
    },
    '& .table-top': {
        padding: "0px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    '& .imageBox': {
        marginLeft:"20px"

    },
    '& .tableError' : {
        padding : "10px"
    }
});
export default withTranslation()(
    //@ts-ignore
    (withRouter(SubscriptionReports))
);
// Customizable Area End