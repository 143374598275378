// Customizable Area Start
import { runEngine } from "framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  complexBuildingId: any;
  handleBack: any;
  handleNext: any;
  jurisdictionSelected: any;
  handleChangeJurisdiction: any;
  step: number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;

  jurisdictionList: any[];

  jurisdictionBuildingDetail: {
    formula: string;
    currency: string;
    timezone: string;
    mobileLength: string;
    measurement: string;
    contract: string;
  };
}

interface SS {
  id: any;
}

export default class CompEmpComplexBlockStep1Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetJurisdictionListCallId: string = "";
  GetJurisdictionDetailCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,

      jurisdictionList: [],

      jurisdictionBuildingDetail: {
        measurement: "",
        timezone: "",
        formula: "",
        mobileLength: "",
        currency: "",
        contract: "",
      },
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetJurisdictionDetailCallId:
          return this.handleJurisdictionDetailResponse(responseJson);
        case this.GetJurisdictionListCallId:
          return this.handleJurisdictionList(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, async () => {
      await this.getJurisdictionList();

      if (this.props.jurisdictionSelected) {
        this.getJurisdictionDetails(this.props.jurisdictionSelected);
      }
    });
  }

  getJurisdictionList = async () => {
    this.GetJurisdictionListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_society_management/company_employee/jurisdictions`,
    });
  };

  handleJurisdictionList = (responseJson: any) => {
    this.setState({ loading: false }, async () => {
      if (responseJson && responseJson.jurisdictions) {
        this.setState({
          jurisdictionList: responseJson.jurisdictions.data.map((item: any) => ({
            label: item.attributes.jurisdiction_name,
            value: item.id,
          })),
        });
      }
    });
  };

  getJurisdictionDetails = async (id: number | string) => {
    this.GetJurisdictionDetailCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/jurisdictions/${id}`,
    });
  };

  handleJurisdictionDetailResponse = (responseJson: { data: any }) => {
    this.setState({ loading: false }, async () => {
      if (responseJson && responseJson.data) {
        const jurisdiction = responseJson.data;
        this.setState({
          jurisdictionBuildingDetail: {
            currency: jurisdiction.attributes.currency.currency,
            timezone: jurisdiction.attributes.timezone,
            measurement: jurisdiction.attributes.unit_of_measurement,
            formula: jurisdiction.attributes.formula,
            mobileLength: jurisdiction.attributes.mobile_number_length,
            contract: jurisdiction.attributes.contracts,
          },
        });
      }
    });
  };

  changeJurisdiction = (event: any) => {
    this.setState({ loading: true }, () => {
      this.getJurisdictionDetails(event.target.value);
      this.props.handleChangeJurisdiction(event.target.value);
    });
  };
}

// Customizable Area End
