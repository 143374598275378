// Customizable Area Start
import React from "react";
import IssueNewContractFormController, { Props, TFunction, UnitNumberinterface, SingleComplex,SingleBuilding,Currency,GetFindPersonListItem } from "./LeaseManagementIssueNewContractFormController.web";
import { Box, Breadcrumbs, Button, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Paper, Popover, Select, TextField, Typography, styled, Input } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClearIcon from "@material-ui/icons/Clear";
import { withTranslation } from "react-i18next";
import AlertErrorWeb from "../../../components/src/AlertError.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { IdCartImage } from "./assets";
import NormalDateField from "../../../components/src/CommonComponents/NormalDateField.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

class LeaseManagementIssueNewContractForm extends IssueNewContractFormController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area Start
  }

  // Customizable Area Start 
  renderFindPersonModal = () => {
    const { anchorEl, selectedId } = this.state;
    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;
    const { t }: { t: TFunction } = this.props;
    return (
      <Box>
        <Modal open={this.state.openFindPersonModal} data-testId={"findPersonModalTestId"}>
          <ContactDetailsHeader>
            <Box className="modalContainer">
              <Box className="ContractDetailsModal">
                <Typography className="ContractDetailsModalHeading" data-testId={"findPersonModalHeadingTestId"}>
                  {t("Find Person")}
                </Typography>
                <IconButton onClick={this.handleCloseFindPersonModal} data-testId={"findPersonModalCrossIconTestId"}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider className="dividerLine" />
              <Typography className="ContractDetailsModalHeadingChild">
              {t("Fill one of more of the fields to find the person, if two fields are populated, any matching person will be shown.")}
              </Typography>
              <Box className="ContractDetailsModalTextfield">
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("ID Number")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="ID Number"
                    onChange={this.handleIdNumberChange}
                    data-testId={"idNumberTestId"}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("Passport Number")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="Passport Number"
                    onChange={this.hanldePassportNumberChange}
                    data-testId={"passportNumberTestId"}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("Email ID")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="Email ID"
                    onChange={this.findPesonEmailChange}
                    data-testId={"emailTestId"}
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("Phone Number")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="+12345667890"
                    onChange={this.findPesonPhoneNumberChange}
                    data-testId={"phoneNumberTestId"}
                  />
                </Box>
                <Box>
                  <Button variant="contained" className="ContractDetailsButton" onClick={this.findPerson} data-testId={"searhcBtnTestId"}>
                    <SearchIcon /> {t("Search")}
                  </Button>
                </Box>
              </Box>
              {this.state.findPersonList.length === 0 ? "" : <Box className="modalTableContainer">
                <table style={{ width: "100%" }} className="tableContainer">
                  <thead>
                    <tr>
                      <th className="tableHeadItem">{t("Name")}</th>
                      <th className="tableHeadItem">{t("ID Number")}</th>
                      <th className="tableHeadItem">{t("Phone Number")}</th>
                      <th className="tableHeadItem">{t("Email")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this?.state?.findPersonList.map(
                      (item: GetFindPersonListItem, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="tableDataItem">{item.attributes.full_name}</td>
                            <td className="tableDataItem">{item.id}</td>
                            <td className="tableDataItem">{item.attributes.email}</td>
                            <td className="tableDataItem">
                              <Box className="tableEmailContainer">
                                <Typography>{"dfgdf"}</Typography>
                                <Box>
                                  <IconButton
                                    onClick={(event) =>
                                      this.handleClick(event, Number(item.id))
                                    }
                                    data-testId={`findShowPersonModalMenuIconTestId${index}`}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Popover
                                    id={popoverId}
                                    open={open && selectedId === Number(item.id)}
                                    anchorEl={anchorEl}
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    data-testId={`tableMenuTestId${index}`}
                                  >
                                    <PopoverContainer>
                                      <MenuItem onClick={() => this.handleShowPerson(item)}
                                        data-testId={`findShowPersonModalMenuOptionBtnTestId${index}`}>
                                        <span className="popoverText" data-testId={`findShowPersonModalMenuOptionTestId${index}`}>{t("View Detail")}</span>
                                      </MenuItem>
                                    </PopoverContainer>
                                  </Popover>
                                </Box>
                              </Box>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </Box>}
              <Box className="modalCancelBtnContainer">
                <Button
                  variant="text"
                  className="ContractDetailsButton1"
                  onClick={this.handleCloseFindPersonModal}
                >
                  {t("Cancel")}
                </Button>
              </Box>
            </Box>
          </ContactDetailsHeader>
        </Modal>
      </Box>
    );
  };

  renderFindShowPersonModal = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <Modal open={this.state.openFindShowPersonModal} data-testId={"findPersonShowModalTestId"}>
        <OpenFindShowPersonModalContainer>
          <Box className="openFindShowPersonModalContainer">
            <Box className="showPersonHeadingContainer">
              <Typography className="showPersonHeading" data-testId={`findShowPersonModalHeadingTestId`}>{t("Find Person")}</Typography>
              <IconButton onClick={this.handleCloseShowPerson}>
                <ClearIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box className="showPersonContentContainer">
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">{t("Name")}</Typography>
                  <Typography className="showPersonFieldValue">
                    {this.handleNullValue(this.state.selectedFindPerson.attributes.full_name)}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("Complex")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                    {this.handleNullValue(this.state.selectedFindPerson.attributes.company_name)}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("ID Number")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                  {this.handleNullValue(this.state.selectedFindPerson.id)}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("Email")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                  {this.handleNullValue(this.state.selectedFindPerson.attributes.email)}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("Address")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                  {this.handleNullAddress(this.state.selectedFindPerson.attributes.address)}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="showPersonBtnContainer">
                    <Button
                      className="showPersonBtnOutlined"
                      onClick={this.handleCloseShowPerson}
                      data-testId={`findShowPersonModalCancelBtnTestId`}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button className="showPersonBtnContained" onClick={this.handleSelectPerson} data-testId={"selectFindPersonTestId"}>{t("Select")}</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </OpenFindShowPersonModalContainer>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t }: { t: TFunction } = this.props;
    return (
      <MainScreen>
        <IssueNewContractContainer>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography id={"LeaseManagmentId"} onClick={() => this.handleExistRecordNavigation("LeaseManagment")} className="ContactDetailsBreadcrumbs" data-testId={"issueNewContractFormHeadingTestId"}>
                {t("Lease Management")}
              </Typography>
              <Typography id={"IssueNewLeaseId"} onClick={() => this.handleExistRecordNavigation("IssueNewLease")} className="ContactDetailsBreadcrumbs" data-testId={"issueNewLeaseTestId"}>
                {t("Issue A New Lease")}
              </Typography>
              <Typography className="ContactDetailsBreadcrumbsStyled">
                {t("Issue a New Contract")}
              </Typography>
            </Breadcrumbs>
            <Typography className="ContactDetailsHeading">
              {t("Issue a New Contract")}
            </Typography>
            <Paper className="ContactDetailsHeadingContainer">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box>
                    <InputLabel>{t("Tenant Name")}</InputLabel>
                    <TextField
                      variant="outlined"
                      className="ContractDetailsTextFieldMain"
                      label={t("+ Add Tenant")}
                      fullWidth
                      onFocus={() => this.handlefocus('Tenant')}
                      value={this.state.tenantName}
                      data-testId={"tenantInputTestId"}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box>
                    <InputLabel>{t("Owner Name")}</InputLabel>
                    <TextField
                      variant="outlined"
                      className="ContractDetailsTextFieldMain"
                      label={t("+ Owner Name")}
                      fullWidth
                      onFocus={() => this.handlefocus('Owner')}
                      data-testId={"ownerNameTestId"}
                      value={this.state.owenerName}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >                    
                    <Select
                      label="UAE"
                      IconComponent={ExpandMoreIcon}
                      value={this.state.selectedCountry}
                      onChange={this.handleCountryChange}
                      data-testId={"countrySelectTestId"}
                      displayEmpty
                      disabled>
                      <MenuItem value={""}><em>{t("Select Country")}</em></MenuItem>
                      {this.state.countryList.map((country: string, index: number) => {
                        return <MenuItem key={index} value={country}>{country}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >                             
                    <Select
                      label="Dubai"
                      IconComponent={ExpandMoreIcon}
                      onChange={this.handleCityChange}
                      value={this.state.selectedCity}
                      disabled
                      data-testId={"citySelectTestId"}
                      displayEmpty
                    >
                      <MenuItem value={""}><em>{t("Select City")}</em></MenuItem>
                      {this.state.cityList.map((city: string, index: number) => {
                        return <MenuItem key={index} value={city}>{city}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select label="Complex"
                      IconComponent={ExpandMoreIcon}
                      onChange={this.hanldeComplex}
                      value={this.state.complex}
                      disabled
                      data-testId={"complexTestId"}
                      displayEmpty
                    >
                      <MenuItem value={""}><em>{t("Select Complex")}</em></MenuItem>
                      <MenuItem value={"Complex"}>{t("Complex")}</MenuItem>
                      <MenuItem value={"Individual Building"}>{t("Individual Building")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select label="Complex 1"
                      IconComponent={ExpandMoreIcon}
                      onChange={this.hanldeComplexList}
                      value={this.state.selectedComplex}
                      disabled
                      displayEmpty
                      data-testId={"selectComplexTestId"}>
                    <MenuItem value={""}><em>{t("Select complex")}</em></MenuItem>
                      {this.state.complexList.map((complex: SingleComplex, index: number) => {
                        return <MenuItem key={index} value={complex.attributes.name}>{complex.attributes.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      label="Building 1"
                      IconComponent={ExpandMoreIcon}
                      onChange={this.handleBuildingChange}
                      value={this.state.selectedBuilding}
                      disabled
                      data-testId={"buildingSelectTestId"}
                      displayEmpty
                    >
                    <MenuItem value={""}><em>{t("Select Building")}</em></MenuItem>
                      {this.state.buildingList.map((buildingName: SingleBuilding, index: number) => {
                        return <MenuItem key={index} value={buildingName.name}>{buildingName.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select 
                    label="Building 1" 
                    IconComponent={ExpandMoreIcon} 
                    onChange={this.handleUnitChange} 
                    value={this.state.unitValue} 
                    disabled
                    data-testId={"unitSelectTestId"}
                    displayEmpty
                    >
                      <MenuItem  value={""}>{t("Select Unit")}</MenuItem>
                      {this.state.unitNumberList.map((unit: UnitNumberinterface, index: number) => {
                        return <MenuItem key={index} value={unit.apartment_name}>{unit.apartment_name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextField"
                    label={t("Enter Agreement Duration (in months)")}
                    fullWidth
                    onChange={this.handleAgrementDuration}
                    data-testId={"agreementDurationTestId"}
                    value={this.state.agrementDuration}
                    inputProps={{ inputMode: 'numeric', pattern: '\\d*' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="datePickerContainer" >
                    <NormalDateField
                      t={t}
                      label="Contract Start Date"
                      value={this.state.findPersonDate}
                      name="dob"
                      onChange={this.handleDateChange}
                      isLabel={false}
                      data-testId={"dateInputTestId"}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextField"
                    label={t("Enter Rent Amount (per month)")}
                    fullWidth
                    onChange={this.handleRentAmount}
                    value={this.state.selectedRentAmount}
                    data-testId={"rentAmountTestId"}
                    inputProps={{ inputMode: 'numeric', pattern: '\\d*' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      label="Select Currency"
                      IconComponent={ExpandMoreIcon}
                      onChange={this.handleCurrencyChange}
                      value={this.state.selectedCurrency} 
                      displayEmpty
                      data-testId={"currencySelectTestId"}
                    >
                       <MenuItem value={""}><em>{t("Select Currency")}</em></MenuItem>
                      {this.state.currencyList.map((Currency: Currency, index: number) => {
                        return (
                          <MenuItem key={index} value={Currency.attributes.currency}>
                            {Currency.attributes.currency}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Box>

          <Box className="ContractDetailsButtonGroup">
            <Button variant="text" className="ContractDetailsButton1" onClick={this.handleBack} data-testId={"backBtnTestId"}>
            {t("Back")}
            </Button>
            <Button variant="contained" className="ContractDetailsButton2" onClick={this.handleNext} data-testId={"nextButtonTestId"} disabled={this.handleDisableNextButton()}>
              {t("Next")}
            </Button>
          </Box>
          {this.renderFindPersonModal()}
          {this.renderFindShowPersonModal()}
          <AlertErrorWeb show={this.state.showError} handleClose={this.handleCloseAlertError} message={this.state.error} data-testId={"errorAlertTestId"} />
        </IssueNewContractContainer>
      </MainScreen>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContractForm);
export { LeaseManagementIssueNewContractForm };
const IssueNewContractContainer = styled(Box)({
  padding: 30,
  background: "#f4f7ff",
  height: "100vh",
  "& .calenderGrayIcon": {
    cursor: "pointer"
  },
  "& .ContactDetailsHeading": {
    fontWeight: 800,
    fontSize: "30px",
    padding: "20px 0px",
  },
  "& .ContactDetailsHeadingContainer": {
    background: "white",
    padding: 20,
  },
  "& .ContactDetailsBreadcrumbs": {
    color: "black",
    cursor: "pointer"
  },
  "& .ContactDetailsBreadcrumbsStyled": {
    color: "#768ac2",
    cursor: "pointer"
  },
  "& .ContactDetailsHeadingText": {
    fontSize: "25px",
    fontWeight: 800,
  },
  "& .ContractDetailDownloadIconStyled": {
    height: "30px",
    width: "33px",
    marginRight: 15,
  },
  "& .ContractDetailsButton1": {
    color: "#3274ce",
    fontWeight: 800,
    marginRight: 15,
    border: "1px solid #3274ce",
    paddingRight: 60,
    paddingLeft: 60,
    cursor: "pointer",
    height: "40px",
  },
  "& .ContractDetailsButton2": {
    background: "#3274ce",
    color: "white",
    fontWeight: 800,
    cursor: "pointer",
    paddingLeft: 70,
    paddingRight: 70,
    height: "40px",
    "&.MuiButton-contained.Mui-disabled":{
      backgroundColor: "#3274ce !important",
      opacity: "0.7"
    }
    },
  "& .ContractDetailsContentHeading": {
    fontWeight: 800,
    marginTop: 30,
    marginBottom: 10,
  },
  "& .ContractDetailsContentHeadingText": {
    color: "gray",
  },
  "& .ContractDetailsButtonGroup": {
    float: "right",
    padding: "50px 0px",
  },
  "& .ContractDetailsHeader": {
    color: "#fcd2ab",
    fontWeight: "700",
    marginLeft: 10,
  },
  "& .ContractDetailsHeader2": {
    marginLeft: 35,
    fontWeight: "bold",
  },
  "& .ContractDetailsContent": {
    marginTop: 10,
  },
  "& .ContractDetailsChild": {
    marginTop: 10,
  },
  "& .ContractDetailsTextFieldMain": {
    marginTop: 8,
    background: "rgb(249,249,249)",
    borderRadius: "10px",
    border: "1px solid #d3ac91",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      border: "1px solid #d3ac91",
    },
  },
  "& .ContractDetailsTextField": {
    marginTop: 15,
    background: "#f9f8f8",
    borderRadius: "10px",
    border: "1px solid #f2f2f2",
    "& .MuiTextField-root": {
      marginTop: 15,
      background: "#f9f8f8",
      borderRadius: "10px",
      border: "1px solid #f2f2f2",
    }
  },
  '& #demo-simple-select-outlined': {
    color: 'black !important',
  },
  "& .datePickerContainer": {
    marginTop: 15,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    height: "54px",
    border: "1px solid #f2f2f2",
    borderRadius: "10px",
    "& .input_icon__form_box .icon_date":{
      width:"100%",
      paddingLeft:"0px"
    },
    "& .input_icon__form_box .input_box":{
      width: "95.2% !important",
      padding: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiFormHelperText-root.Mui-error":{
      display:"none"
    },
    "& .MuiInput-underline.Mui-error::after": {
      borderBottomColor: "white",
    }
  },
  "& .dividerLine": {
    width: "100%"
  },
  "& .idCardImg": {
    width: "130px",
    height: "75px"
  }
});
const ContactDetailsHeader = styled(Box)({
  height: "100vh",
  border: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "scroll",

  "& .modalContainer": {
    background: "white",
    width: "70%",
  },

  "& .ContractDetailsModal": {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
  "& .ContractDetailsModalHeading": {
    fontWeight: 700,
  },
  "& .ContractDetailsModalHeadingChild": {
    color: "#c2c2c2",
    padding: 15,
    fontSize: 14,
  },
  "& .ContractDetailsTextFieldHeading": {
    fontSize: 14,
  },
  "& .ContractDetailsModalTextfield": {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
  "& .ContractDetailsButton": {
    background: "#3274ce",
    color: "white",
    fontWeight: 800,
    height: 55,
    marginTop: 21,
    borderRadius: "10px",
  },
  "& .ContractDetailsTextFieldModal": {
    border: "1px solid #f1f1f1",
    borderRadius: "10px",
    background: "#f9f9f9",
    "& .MuiTextField-root": {
      borderRadius: "10px",
      background: "#f9f9f9",
      border: "1px solid #f1f1f1"
    }
  },
  "& .modalCancelBtnContainer": {
    padding: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .ContractDetailsButton1": {
    color: "#3274ce",
    fontWeight: 800,
    border: "1px solid #3274ce",
    paddingRight: 40,
    paddingLeft: 40,
    cursor: "pointer",
  },
  "& .modalTableContainer": {
    padding: "20px",
  },
  "& .tableHeadItem": {
    fontSize: "16px",
    fontWeight: 900,
    textAlign: "left",
    borderBottom:"none",
    color:"#272a2b"
  },
  "& .tableEmailContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .tableDataItem": {
    textAlign: "left",
    borderBottom: "1px solid #f1f1f1",
  },
  "& .tableContainer": {
    borderCollapse: "collapse",
  },

});
const PopoverContainer = styled(Box)({
  width: "150px",
  "& .popoverText": {
    padding: "10px 20px ",
  },
});
const OpenFindShowPersonModalContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  overflow: "scroll",
  "& .openFindShowPersonModalContainer": {
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "70%",
  },
  "& .showPersonHeadingContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
  },
  "& .showPersonHeading": {
    fontSize: "18px",
    fontWeight: 800,
  },
  "& .showPersonContentContainer": {
    padding: "20px",
  },
  "& .showPersonFieldLable": {
    fontSize: "16px",
    fontWeight: 800,
    marginBottom: "5px",
  },
  "& .showPersonFieldValue": {
    color: "#a8a8a8",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .personIdPic": {
    width: "100px",
    height: "70px",
    objectFit: "contain",
  },
  "& .showPersonBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    alignItems: "center",
  },
  "& .showPersonBtnOutlined": {
    width: "180px",
    height: "40px",
    color: "#2b6fec",
    fontWeight: 900,
    backgroundColor: "#fff",
    border: "1px solid #2b6fec",
    borderRadius: "6px",
  },
  "& .showPersonBtnContained": {
    width: "180px",
    height: "40px",
    color: "#fff",
    fontWeight: 900,
    backgroundColor: "#2b6fec",
    borderRadius: "6px",
  },
});

const StyledInput = styled(Input)({
  width: '100%',
  "& #VisitorTimeBox":{
    padding:"10px",
    marginLeft:"0px"
  },
  "&.MuiInput-underline::before":{
    borderBottom: '1px solid transparent !important',
  },
  '&.datePicker': {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid transparent !important',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: 0,
    },
    "& .MuiInput-underline::before":{
      borderBottom: '1px solid transparent !important',
    },
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid transparent !important',
  },
});
// Customizable Area End