import React from "react";
// Customizable Area Start
import { Box, Breadcrumbs, Button, Chip, Divider, FormControl, IconButton, MenuItem, Paper, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RegisterOwnerResponseItem, TFunction } from "./RegisteredOwnersController.web";
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import AlertError from "../../../components/src/AlertError.web";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End

import RegisteredOwnersController, { Props, } from "./RegisteredOwnersController.web";

class RegisteredOwners extends RegisteredOwnersController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCOORegisteredOwner = () => {
        const { popoverAncher, selectedItem } = this.state;
        const { t }: { t: TFunction } = this.props;
        const open = Boolean(popoverAncher);
        return <Box className='registeredOwnerTopSectionContainer'>
            <Box>
                <Breadcrumbs>
                    <Typography data-testId={"myDashboardTestId"}  color="inherit" onClick={()=>this.handleNavigation("dashboard")}>
                        {t("My Dashboard")}
                    </Typography>
                    <Typography data-testId={"generalDashboardTestId"}  color="inherit" onClick={()=>this.handleNavigation("dashboard")}>
                        {t("General Dashboard")}
                    </Typography>
                    <Typography className="textActive">{t("Registered Owner")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box>
                <Typography className='registerHeading' variant='h4'>{t("Registered Owner")}</Typography>
            </Box>
            <Box className='searchFilterContainer'>
                <FormControl className={"sortBySearch"} variant="filled">
                    <Select 
                        value={this.state.ownerStatus}
                        displayEmpty
                        className={"sortBySearch"}
                        variant='outlined'
                        placeholder='Owner Status'
                        IconComponent={ExpandMoreIcon}
                        onChange={this.handleOwnerStatusChange}
                        data-testId={"ownerStatusTestId"}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value=""><em>{t("Owner Status")}</em></MenuItem>
                        <MenuItem value="Invited">{t("Invited")}</MenuItem>
                        <MenuItem value="Accepted">{t("Accepted")}</MenuItem>
                        <MenuItem value="On Board">{t("On Board")}</MenuItem>
                        <MenuItem value="Awaited Approval">{t("Awaited Approval")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select
                        variant='outlined'
                        displayEmpty
                        value={this.state.payingClient}
                        onChange={this.handlePayingClientsChange}
                        IconComponent={ExpandMoreIcon}
                        placeholder='Paying Clients'
                        data-testId={"payingClientTestId"}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        className={"payingClientsSelect"}
                    >
                        <MenuItem value=""><em>{t("Paying Clients")}</em></MenuItem>
                        <MenuItem value="Yes" className="payingClientOption">{t("Yes")}</MenuItem>
                        <MenuItem value="No" className="payingClientOption">{t("No")}</MenuItem>
                    </Select>
                </FormControl>
                <Button className={"searchBtn"} variant='contained' startIcon={<SearchIcon className='searchbtnIcon' />} onClick={()=>this.getRegisteredOwner("")} data-testId={"cooSearchBtnTestId"}>{t("Search")}</Button>
            </Box>
            <Box className='registeredOwnerTableContainer' component={Paper} >
                <Box className='registeredOwnerTableTopContainer'>
                    <Typography variant='h5' className='tableHeading'>List of registered owners</Typography>
                    <Box className='searchContainer'>
                        <FormControl variant="filled" className={"sortBySearch"}>
                            <Select
                                value={this.state.sortBy}
                                onChange={this.handleSortByChange}
                                data-testId={"sortBySelectTestId"}
                                displayEmpty className={"sortBySearch"}
                                variant='outlined'
                                placeholder='Sort By'
                                IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                            >
                                <MenuItem value=""><em>{t("Sort By")}</em></MenuItem>
                                <MenuItem value="asc"><em>{t("Ascending")}</em></MenuItem>
                                <MenuItem value="dsc"><em>{t("Descending")}</em></MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            value={this.state.searchText}
                            className='searchTextField'
                            variant='outlined'
                            data-testId={"searchInputTestId"}
                            onChange={this.handleSearchInputChange}
                            placeholder='Search by Owner Name or ID'
                            InputProps={{ startAdornment: <SearchIcon className='searchIcon' /> }}
                        />
                    </Box>
                </Box>
                <Divider />
                <Box >
                    <TableContainer className='tableContainer'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className='tableColumnHeading'>#</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Owner Name")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Owner ID")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Total Unit")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Paying Clients")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Managed By")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeadingOwnerStatus'>{t("Owner Status")}</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.registeredOwner.length === 0 ?<Typography>{t("No data found")}</Typography> :this.state.registeredOwner.map((registerOwner: RegisterOwnerResponseItem, registerOwnerIndex: number) => {
                                    return <TableRow className="cootablerow" key={registerOwnerIndex}>
                                        <TableCell align="center" className="cootableHeading">{registerOwnerIndex + 1}</TableCell>
                                        <TableCell align="center" className="cootableHeading" data-testId={`tableOwnerName${registerOwnerIndex}`}>{registerOwner.attributes.owner_name}</TableCell>
                                        <TableCell align="center" className="cootableHeading">{registerOwner.attributes.owner_id}</TableCell>
                                        <TableCell align="center" className="cootableHeading">{registerOwner.attributes.Total_units}</TableCell>
                                        <TableCell align="center" className="cootableHeading">{registerOwner.attributes.paying_clients}</TableCell>
                                        <TableCell align="center" className="cootableHeading">{registerOwner.attributes.managed_by}</TableCell>
                                        <TableCell align="center" >
                                            {registerOwner.attributes.status === "Awaited Approval" && <Chip label={registerOwner.attributes.status} className='ownerStatusOrange' />}
                                            {registerOwner.attributes.status === "On Board" && <Chip label={registerOwner.attributes.status} className='ownerStatusGreen' />}
                                            {registerOwner.attributes.status === "Accepted" && <Chip label={registerOwner.attributes.status} className='ownerStatusGreen' />}
                                            {registerOwner.attributes.status === "Invited" && <Chip label={registerOwner.attributes.status} className='ownerStatusBlue' />}
                                        </TableCell>
                                        <TableCell align="center" >
                                            <Box>
                                                <IconButton onClick={this.handleTableMenu} data-testId={`tableMenuTestId${registerOwnerIndex}`}>
                                                    <MoreVertIcon className="moreOptionIcon" />
                                                </IconButton>
                                                <Popover
                                                    open={open}
                                                    anchorEl={this.state.popoverAncher}
                                                    onClose={this.handleClosePopover}
                                                    data-testId={`popoverTestId${registerOwnerIndex}`}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <MenuItem data-testId={`tableMenuItemTestId${registerOwnerIndex}`} onClick={()=>this.handleViewDetails(registerOwner.id)}>{t("View Details")}</MenuItem>
                                                </Popover>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <Box className='tablePaginationContainer'>
                        <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData?.current_page} data-testId={"cooPaginationTestId"} />
                        </Box>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    };

    renderSalesManagerRegisteredOwner = () => {
        const { popoverAncher, selectedItem } = this.state;
        const { t }: { t: TFunction } = this.props;
        const open = Boolean(popoverAncher);
        return <Box className='registeredOwnerTopSectionContainer'>
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography data-testId={"myDashboardTestId"} onClick={()=>this.handleNavigation("dashboard")} color="inherit">
                        {t("My Dashboard")}
                    </Typography>
                    <Typography data-testId={"generalDashboardTestId"} onClick={()=>this.handleNavigation("dashboard")} color="inherit">
                        {t("General Dashboard")}
                    </Typography>
                    <Typography className="textActive">{t("Registered Owner")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box>
                <Typography variant='h4' className='registerHeading'>{t("Registered Owner")}</Typography>
            </Box>
            <Box className='searchFilterContainer'>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select 
                    displayEmpty className={"payingClientsSelect"} 
                    data-testId={"complexTestId"}
                    value={this.state.complex}
                    variant='outlined' 
                    onChange={this.handleComplexChange}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    placeholder='Paying Clients' 
                    IconComponent={ExpandMoreIcon}
                        
                    >
                        <MenuItem value="">
                            <em>{t("Select Complex")}</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select
                        onChange={this.handleBuildingChange}
                        value={this.state.building}
                        displayEmpty
                        data-testId={"buildingTestId"}
                        variant='outlined'
                        className={"payingClientsSelect"}
                        
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        IconComponent={ExpandMoreIcon}
                        placeholder='Paying Clients'
                        
                    >
                        <MenuItem value=""><em>{t("Paying Building")}</em></MenuItem>
                        <MenuItem value="Yes"><em>{t("Yes")}</em></MenuItem>
                        <MenuItem value="No"><em>{t("No")}</em></MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select
                        onChange={this.handleUnitChange}
                        value={this.state.unit}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        displayEmpty
                        data-testId={"unitTestId"}
                        variant='outlined'
                        className={"payingClientsSelect"}
                        IconComponent={ExpandMoreIcon}
                        placeholder='Paying Clients'
                        
                    >
                        <MenuItem value="">
                            <em>{t("Select Unit")}</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select
                    MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        onChange={this.handleOwnerStatusChange}
                        value={this.state.ownerStatus}
                        displayEmpty
                        data-testId={"ownerStatusTestId"}
                        variant='outlined'
                        className={"sortBySearch"}
                        placeholder='Owner Status'
                        IconComponent={ExpandMoreIcon}                        
                    >
                        <MenuItem value=""><em>{t("Owner Status")}</em></MenuItem>
                        <MenuItem value="Invited"><em>{t("Invited")}</em></MenuItem>
                        <MenuItem value="Awaited Approval"><em>{t("Awaited Approval")}</em></MenuItem>
                        <MenuItem value="On Board"><em>{t("On Board")}</em></MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select 
                        value={this.state.payingClient}
                        onChange={this.handlePayingClientsChange}
                        data-testId={"payingClientTestId"}
                        displayEmpty className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value=""><em>{t("Paying Clients")}</em></MenuItem>
                        <MenuItem value="">{t("Yes")}</MenuItem>
                        <MenuItem value="">{t("No")}</MenuItem>
                    </Select>
                </FormControl>
                <Button variant='contained' startIcon={<SearchIcon className='searchbtnIcon' />} onClick={()=>this.getRegisteredOwner("")} className={"searchBtn"} data-testId={"salesManagerSearchBtnTestId"}>{t("Search")}</Button>
            </Box>
            <Box component={Paper} className='registeredOwnerTableContainer'>
                <Box className='registeredOwnerTableTopContainer' component={Box}>
                    <Typography variant='h5' className='tableHeading'>{t("List of registered owners")}</Typography>
                    <Box className='searchContainer'>
                        <FormControl variant="filled" className={"sortBySearch"}>
                            <Select
                                value={this.state.sortBy}
                                onChange={this.handleSortByChange}
                                displayEmpty
                                data-testId={"sortByTestId"}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                                IconComponent={ExpandMoreIcon}
                                className={"sortBySearch"}
                                variant='outlined'
                                placeholder='Sort By'
                                
                            >
                                <MenuItem value=""><em>{t("Sort By")}</em></MenuItem>
                                <MenuItem value="Ascending"><em>{t("Ascending")}</em></MenuItem>
                                <MenuItem value="Descending"><em>{t("Descending")}</em></MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            variant='outlined'
                            placeholder='Search by Owner Name or ID'
                            InputProps={{ startAdornment: <SearchIcon className='searchIcon' /> }}
                            value={this.state.searchText}
                            className='searchTextField'
                            onChange={this.handleSearchInputChange}
                            data-testId={"searchInputTestId"}
                            />
                    </Box>
                </Box>
                <Divider />
                <Box >
                    <TableContainer className='tableContainer'>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className='tableColumnHeading'>#</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Owner Name")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Owner ID")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Total Unit")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Paying Clients")}</TableCell>
                                    <TableCell align="center" className='tableColumnHeading'>{t("Managed By")}</TableCell>
                                    <TableCell className='tableColumnHeadingOwnerStatus' align="left">{t("Owner Status")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.registeredOwner.map((registerOwner: RegisterOwnerResponseItem, registerOwnerIndex: number) => {
                                    return <TableRow key={registerOwnerIndex}>
                                        <TableCell variant="head" className="tableCell" align="center">{registerOwnerIndex + 1}</TableCell>
                                        <TableCell variant="head" className="tableCell" align="center" data-testId={`tableOwnerName${registerOwnerIndex}`}>{registerOwner.attributes.owner_name}</TableCell>
                                        <TableCell variant="head" className="tableCell" align="center">{registerOwner.id}</TableCell>
                                        <TableCell variant="head" className="tableCell" align="center">{registerOwner.attributes.Total_units}</TableCell>
                                        <TableCell variant="head" className="tableCell" align="center">{registerOwner.attributes.paying_clients}</TableCell>
                                        <TableCell variant="head" className="tableCell" align="center">{registerOwner.attributes.managed_by}</TableCell>
                                        <TableCell variant="head" className="tableCell" align="left" >
                                            <Box className='ownerStatusContainer'>
                                                <Box className='ownerStatusChipContainer'>
                                                    {registerOwner.attributes.status === "Awaited Approval" && <Chip label={registerOwner.attributes.status} className='ownerStatusOrange' />}
                                                    {registerOwner.attributes.status === "Accepted" && <Chip label={registerOwner.attributes.status} className='ownerStatusGreen' />}
                                                    {registerOwner.attributes.status === "On Board" && <Chip label={registerOwner.attributes.status} className='ownerStatusGreen' />}
                                                    {registerOwner.attributes.status === "Invited" && <Chip label={registerOwner.attributes.status} className='ownerStatusBlue' />}
                                                </Box>
                                                <Box>
                                                    <IconButton onClick={this.handleTableMenu} data-testId={`tableMenuTestId${registerOwnerIndex}`}>
                                                        <MoreVertIcon className="moreIcon" />
                                                    </IconButton>
                                                    <Popover
                                                        anchorEl={this.state.popoverAncher}
                                                        open={open}
                                                        data-testId={`popoverTestId${registerOwnerIndex}`}
                                                        onClose={this.handleClosePopover}
                                                        transformOrigin={{
                                                            horizontal: 'right',
                                                            vertical: 'top',
                                                        }}
                                                        anchorOrigin={{
                                                            horizontal: 'left',
                                                            vertical: 'bottom',
                                                        }}
                                                    >
                                                        <MenuItem data-testId={`tableMenuItemTestId${registerOwnerIndex}`} onClick={()=>this.handleViewDetails(registerOwner.id)}>{t("View Details")}</MenuItem>
                                                    </Popover>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <Box className='tablePaginationContainer'>
                            <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.salesPage} data-testId={"salesPaginationTestId"} />
                        </Box>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    };

    renderAccountManagerRegisteredOwner = () => {
        const { popoverAncher, selectedItem } = this.state;
        const open = Boolean(popoverAncher);
        const { t }: { t: TFunction } = this.props;
        return <Box className='registeredOwnerTopSectionContainer'>
            <Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="inherit" data-testId={"myDashboardTestId"} onClick={()=>this.handleNavigation("dashboard")}>
                        {t("My Dashboard")}
                    </Typography>
                    <Typography color="inherit" data-testId={"generalDashboardTestId"} onClick={()=>this.handleNavigation("dashboard")}>
                        {t("General Dashboard")}
                    </Typography>
                    <Typography className="textActive">{t("Registered Owner")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box>
                <Typography variant='h4' className='registerHeading'>{t("Registered Owner")}</Typography>
            </Box>
            <Box className='searchFilterContainer'>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select
                        value={this.state.complex}
                        onChange={this.handleComplexChange}
                        data-testId={"complexTestId"}
                        displayEmpty className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>{t("Select Complex")}</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select 
                        value={this.state.building}
                        onChange={this.handleBuildingChange}
                        data-testId={"buildingTestId"}
                        IconComponent={ExpandMoreIcon}
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        placeholder='Paying Clients'
                    >
                        <MenuItem value=""><em>{t("Paying Building")}</em></MenuItem>
                        <MenuItem className="payingBuildingOtpion" value="Yes">{t("Yes")}</MenuItem>
                        <MenuItem className="payingBuildingOtpion" value="No">{t("No")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select
                        value={this.state.unit}
                        onChange={this.handleUnitChange}
                        data-testId={"unitTestId"}
                        displayEmpty
                        className={"payingClientsSelect"}
                        variant='outlined'
                        placeholder='Paying Clients'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value="">
                            <em>{t("Select Unit")}</em>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="filled" className={"sortBySearch"}>
                    <Select
                        value={this.state.ownerStatus}
                        onChange={this.handleOwnerStatusChange}
                        data-testId={"ownerStatusTestId"}
                        displayEmpty
                        className={"sortBySearch"}
                        variant='outlined'
                        placeholder='Owner Status'
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                    >
                        <MenuItem value=""><em>{t("Owner Status")}</em></MenuItem>
                        <MenuItem value="Accepted">{t("Accepted")}</MenuItem>
                        <MenuItem value="Invited">{t("Invited")}</MenuItem>
                        <MenuItem value="Awaited Approval">{t("Awaited Approval")}</MenuItem>
                        <MenuItem value="On Board">{t("On Board")}</MenuItem>
                    </Select>
                </FormControl>
                <Button variant='contained' className={"searchBtn"} startIcon={<SearchIcon className='searchbtnIcon' />} onClick={()=>this.getRegisteredOwner("1")} data-testId={"accountManagerSearchBtnTestId"}>{t("Search")}</Button>
            </Box>
            <Box component={Paper} className='registeredOwnerTableContainer'>
                <Box className='registeredOwnerTableTopContainer'>
                    <Typography variant='h5' className='tableHeading'>List of registered owners</Typography>
                    <Box className='searchContainer'>
                        <FormControl variant="filled" className={"sortBySearch"}>
                            <Select
                                value={this.state.sortBy}
                                onChange={this.handleSortByChange}
                                displayEmpty
                                className={"sortBySearch"}
                                variant='outlined'
                                data-testId={"sortByTestId"}
                                placeholder='Sort By'
                                IconComponent={ExpandMoreIcon}
                                MenuProps={{
                                    style: {
                                        marginTop: "50px",
                                    },
                                }}
                            >
                                <MenuItem value=""><em>{t("Sort By")}</em></MenuItem>
                                <MenuItem className="sortByOption" value="Ascending">{t("Ascending")}</MenuItem>
                                <MenuItem className="sortByOption" value="Descending">{t("Descending")}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            className='searchTextField'
                            variant='outlined'
                            placeholder='Search by Owner Name or ID'
                            InputProps={{ startAdornment: <SearchIcon className='searchIcon' /> }}
                            value={this.state.searchText}
                            onChange={this.handleSearchInputChange}
                            data-testId={"searchInputTestId"}
                        />
                    </Box>
                </Box>
                <Divider />
                <Box >
                    <TableContainer className='tableContainer'>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell variant="head" align="center" className='tableColumnHeading'>#</TableCell>
                                    <TableCell variant="head" align="center" className='tableColumnHeading'>{t("Owner Name")}</TableCell>
                                    <TableCell variant="head" align="center" className='tableColumnHeading'>{t("Owner ID")}</TableCell>
                                    <TableCell variant="head" align="center" className='tableColumnHeading'>{t("Total Unit")}</TableCell>
                                    <TableCell variant="head" align="center" className='tableColumnHeading'>{t("Paying Clients")}</TableCell>
                                    <TableCell variant="head" align="center" className='tableColumnHeading'>{t("Managed By")}</TableCell>
                                    <TableCell variant="head" className='tableColumnHeadingOwnerStatus' align="left">{t("Owner Status")}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.registeredOwner.map((registerOwner: RegisterOwnerResponseItem, registerOwnerIndex: number) => {
                                    return <TableRow key={registerOwnerIndex}>
                                        <TableCell align="center">{registerOwnerIndex + 1}</TableCell>
                                        <TableCell align="center" data-testId={`tableOwnerName${registerOwnerIndex}`}>{registerOwner.attributes.owner_name}</TableCell>
                                        <TableCell align="center">{registerOwner.attributes.owner_id}</TableCell>
                                        <TableCell align="center">{registerOwner.attributes.Total_units}</TableCell>
                                        <TableCell align="center">{registerOwner.attributes.paying_clients}</TableCell>
                                        <TableCell align="center">{registerOwner.attributes.managed_by}</TableCell>
                                        <TableCell align="left" >
                                            <Box className='ownerStatusContainer'>
                                                <Box className='ownerStatusChipContainer'>
                                                    {registerOwner.attributes.status === "On Board" && <Chip label={registerOwner.attributes.status} className='ownerStatusGreen' />}
                                                    {registerOwner.attributes.status === "Accepted" && <Chip label={registerOwner.attributes.status} className='ownerStatusGreen' />}
                                                    {registerOwner.attributes.status === "Invited" && <Chip label={registerOwner.attributes.status} className='ownerStatusBlue' />}
                                                    {registerOwner.attributes.status === "Awaited Approval" && <Chip label={registerOwner.attributes.status} className='ownerStatusOrange' />}
                                                </Box>
                                                
                                            </Box>
                                        </TableCell>
                                        <TableCell align="left" >
                                            <Box>
                                                <IconButton onClick={this.handleTableMenu} data-testId={`tableMenu${registerOwnerIndex}`}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Popover
                                                    anchorEl={this.state.popoverAncher}
                                                    data-testId={`popoverTestId${registerOwnerIndex}`}
                                                    onClose={this.handleClosePopover}
                                                    open={open}
                                                    anchorOrigin={{
                                                        horizontal: 'left',
                                                        vertical: 'bottom',
                                                    }}
                                                    transformOrigin={{
                                                        horizontal: 'right',
                                                        vertical: 'top',
                                                    }}
                                                >
                                                    <MenuItem data-testId={`tableMenuItemTestId${registerOwnerIndex}`} onClick={() => this.handleViewDetails(registerOwner.id)}>{t("View Details")}</MenuItem>
                                                </Popover>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        <Box className='tablePaginationContainer'>
                            <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.accountPage} data-testId={"accountPaginationTestId"} />
                        </Box>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                <RegisteredOwnerContainer>
                    {this.state.userRole === "coo" && this.renderCOORegisteredOwner()}
                    {this.state.userRole === "sales_manager" && this.renderSalesManagerRegisteredOwner()}
                    {this.state.userRole === "account_manager" && this.renderAccountManagerRegisteredOwner()}
                </RegisteredOwnerContainer>
                <AlertError show={this.state.showError} handleClose={this.handleErrorAlert} message={this.state.errorMessage} data-testId={"errorAlertTestId"}/>
                <Loader loading={this.state.loader}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(RegisteredOwners);
export {RegisteredOwners};
const RegisteredOwnerContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#F4F7FF",
    height: "100vh",
    overflow: "scroll",
    "& .registeredOwnerTopSectionContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "15px"
    },
    "& .registerHeading": {
        fontWeight: "900"
    },
    "& .textActive": {
        color: "#3f7cef"
    },
    "& .registeredOwnerTableContainer": {
        borderRadius: "10px"
    },
    "& .registeredOwnerTableTopContainer": {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
        alignItems: "center"
    },
    "& .searchContainer": {
        display: "flex",
        gap: "20px"
    },
    "& .sortBySearch": {
        width: "180px",
        backgroundColor: "white",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA"
    },
    "& .payingClientsSelect": {
        width: "180px",
        backgroundColor: "white",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA"
    },
    "& .tableHeading": {
        fontWeight: "900"
    },
    "& .searchTextField": {
        width: "270px",
        height: "51px",
        borderRadius: "10px",
        border: "1px solid #EAEAEA",
        "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            height: "51px",
            border: "1px solid #EAEAEA",
        },
        "& .MuiOutlinedInput-inputAdornedStart": {
            paddingLeft: "45px"
        }
    },
    "& .searchIcon": {
        color: "#777a7f"
    },
    "& .tableContainer": {
        height: "100%",
        overflow: "scroll"
    },
    "& .tableColumnHeading": {
        fontWeight: "700"
    },
    "& .tableColumnHeadingOwnerStatus": {
        width: "250px",
        fontWeight: "700"
    },
    "& .ownerStatusBlue": {
        backgroundColor: "#e6edfc",
        color: "#3f7dee",
        fontWeight: "700",
    },
    "& .ownerStatusOrange": {
        backgroundColor: "#fef0e6",
        color: "#fc924b",
        fontWeight: "700",
    },
    "& .ownerStatusGreen": {
        backgroundColor: "#e4f8eb",
        color: "#40ce74",
        fontWeight: "700",
    },
    "& .ownerStatusChipContainer": {
        display: "flex",
        flexDirection: "column"
    },
    "& .ownerStatusContainer": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .MuiIconButton-root": {
        padding: "10px 0px",
        width: "40px"
    },
    "& .tablePaginationContainer": {
        padding: "20px",
        display: "flex",
        justifyContent: "space-between"
    },
    "& .colorOrange": {
        color: "#fc8e45"
    },
    "& .colorBold": {
        fontWeight: "900"
    },
    "& .paginationContainer": {
        "& .MuiPaginationItem-page.Mui-selected": {
            backgroundColor: "#fc8e45",
            border: "none",
            color: "#fff"
        },
        "& .MuiPaginationItem-rounded": {
            color: "#ebeced"
        }
    },
    "& .searchFilterContainer": {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap"
    },
    "& .searchBtn": {
        backgroundColor: "#2a6fed",
        height: "45px",
        color: "#fff",
        borderRadius: "10px"
    },
    "& .searchbtnIcon": {
        color: "#ffff"
    }
});
// Customizable Area End