import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { getStorageData } from "framework/src/Utilities";
const navigation = require("react-navigation");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
navigation: typeof navigation;
  id: string;
  t: (value: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewSavedTemplateModal: boolean;
  savedTemplateModalData: string;
  loader: boolean;
  showError: boolean;
  errorMessage: string;
  searchText: string;
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  savedTemplatesList: Array<SavedTemplatesItem> | null;
  selectedTemplate: number | null;
  savedTemplateId: string;
  showSuccess: boolean;
  showSuccessMessage: string;
  ownerName: string;
  // Customizable Area End
}

// Customizable Area Start
export type TFunction = (value: string) => string;

export type DataType = string | number | null;

export interface SavedTemplatesItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    lease_template_id: number;
    template_name: string | null;
    custom_lease_template: DataType;
    account_id: number;
    apartment_management_id: DataType;
    building_management_id: DataType;
    society_management_id: number;
    landlord_name: string | null;
    country: string | null;
    city: string | null;
    agreement_duration: DataType;
    start_date: DataType;
    rent_amount: DataType;
    currency: DataType;
    expires_on: DataType;
    tenant_name: DataType;
    duration: DataType;
    contract_type: DataType;
    custom_term_condition: DataType;
    penanlty_late_payment: boolean | null;
    custom_contract: boolean;
    tenant_id: DataType;
    term_ids: Array<string> | null;
    condition_ids: Array<string> | null;
    lease_template_text: string;
    lease_template_pdf: {
      url: string;
    };
    custom_lease_template_pdf: {
      url: string;
    } | null;
    terms: Array<string> | null;
    conditions: Array<string> | null;
    penanlty_late_payments: boolean | null;
    society_management: SocietyManagement | null;
    building_management: BuildingManagement | null;
    apartment_management: ApartMentManagement | null;
    template_in_used_count: number | null;
  }
}

export interface ApartMentManagement {
  id: number;
  apartment_name: string;
}

export interface BuildingManagement {
  id: number;
  name: string;
}

export interface SocietyManagement {
  id: number;
  name: string;
}

export interface SavedTemplateListResponse {
  owner_name: string;
  templates: {
    data: Array<SavedTemplatesItem>;
  }
}

export interface DeleteSavedTemplateResponse {
  message: string;
}

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
  contract: string
};

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}
// Customizable Area End

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedTemplateDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSavedTemplateListApiCallId: string = "";
  deleteSavedTemplateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ContractDetailsId),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      viewSavedTemplateModal: false,
      savedTemplateModalData: "",
      loader: false,
      showError: false,
      errorMessage: "",
      searchText: "",
      anchorEl: null,
      selectedId: 0,
      savedTemplatesList: [],
      selectedTemplate: null,
      savedTemplateId: "",
      showSuccess: false,
      showSuccessMessage: "",
      ownerName: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.savedTemplateDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.savedTemplateDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let savedTemplateId = await getStorageData('savedTemplateId');
      if (savedTemplateId) {
        this.setState({ savedTemplateId: savedTemplateId }, () => {
          this.getSavedTemplateList();
        })
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSavedTemplateList();
  }

  contractDetailsApiCall = async (data: APIPayloadType) => {
    let token = localStorage.getItem("loginSuccessToken")
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      "Content-Type": contentType,
      token: token
    };
    let contractDetailsrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    contractDetailsrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    body && type !== "formData"
      ? contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : contractDetailsrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
    return contractDetailsrequestMessage.messageId;
  };

  savedTemplateDetailsApiResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: SavedTemplateListResponse & DeleteSavedTemplateResponse) => {
    if (apiRequestCallId === this.getSavedTemplateListApiCallId) {
      this.savedTemplateListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteSavedTemplateApiCallId) {
      this.deleteSavedTemplateItemSucessCallBack(responseJson);
    }
  };

  savedTemplateDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.getSavedTemplateListApiCallId) {
      this.savedTemplateListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteSavedTemplateApiCallId) {
      this.deleteSavedTemplateItemFailureCallBack(responseJson);
    }
  };

  getSavedTemplateList = async () => {
    this.setState({ loader: true });
    let endPoint = `${configJSON.savedTemplateItemListEndpoint}${this.state.savedTemplateId}&search=${this.state.searchText}`;
    this.getSavedTemplateListApiCallId = await this.contractDetailsApiCall({
      method: configJSON.contractDetailsMethod,
      endPoint: endPoint
    });
  };

  savedTemplateListSucessCallBack = (response: SavedTemplateListResponse) => {
    if (response.templates.data === null || response.templates.data.length === 0) {
      this.setState({ showError: true, errorMessage: "No Templates Found", loader: false });
    } else {
      this.setState({ savedTemplatesList: response.templates.data, ownerName: response.owner_name, loader: false });
    }
  };

  savedTemplateListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
  };

  handleDeleteTemplate = async (templateId: string) => {
    this.setState({ loader: true, anchorEl: null, selectedId: null });
    this.deleteSavedTemplateApiCallId = await this.contractDetailsApiCall({
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.deleteSavedTemplateItem}${templateId}`,
    });
  };

  deleteSavedTemplateItemSucessCallBack = (response: DeleteSavedTemplateResponse) => {
    this.setState({ showSuccess: true, showSuccessMessage: response.message, loader: false }, () => {
      this.getSavedTemplateList();
    });
  };

  deleteSavedTemplateItemFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, errorMessage: response.errors[0].contract, loader: false });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, selectedId: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedId: selectedId });
  };

  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, () => {
      this.getSavedTemplateList();
    });
  };

  handleSavedTemplateModal = () => {
    this.setState({ viewSavedTemplateModal: !this.state.viewSavedTemplateModal });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };

  downloadTemplate = (downloadUrl: string) => {
    const downloadBtn = document.createElement('a');
    downloadBtn.href = downloadUrl;
    downloadBtn.download = 'document.pdf';
    downloadBtn.target = '_blank';
    document.body.appendChild(downloadBtn);
    downloadBtn.click();
    document.body.removeChild(downloadBtn);
  };

  viewTemplate = (templateId: string) => {
    if (this.state.savedTemplatesList !== null) {
      let viewTemplateModalData = this.state.savedTemplatesList.find((item) => {
        return item.id === templateId
      });
      if (viewTemplateModalData) {
        let template = viewTemplateModalData.attributes.lease_template_text
        template = template.replace("{{TENANT_NAME}}", viewTemplateModalData.attributes.tenant_name + "");
        template = template.replace("{{START_DATE}}", viewTemplateModalData.attributes.start_date + "");
        template = template.replace("{{BUILDING_NAME}}", viewTemplateModalData.attributes.building_management?.name + "");
        template = template.replace("{{UNIT_NAME}}", viewTemplateModalData.attributes.apartment_management?.apartment_name + "");
        template = template.replace("{{DURATION}}", viewTemplateModalData.attributes.agreement_duration + "months");
        template = template.replace("{{END_DATE}}", viewTemplateModalData.attributes.expires_on + "");
        template = template.replace("{{AMOUNT}}", viewTemplateModalData.attributes.rent_amount + "");
        template = template.replace("{{COMPLEX_NAME}}", viewTemplateModalData.attributes.society_management?.name + "");
        template = template.replace("{{COMPLEX_ADDRESS}}", viewTemplateModalData.attributes.city + ", " + viewTemplateModalData.attributes.country);
        template = template.replace(/{{LANDLORD_NAME}}/g, viewTemplateModalData.attributes.landlord_name + "");
        this.setState({ savedTemplateModalData: template }, () => {
          this.setState({ viewSavedTemplateModal: !this.state.viewSavedTemplateModal, })
          this.handleClose()
        });
      }
    }
  };

  handleCloseAlertError = () => {
    this.setState({ showError: false });
  };

  handleCloseAlertSuccess = () => {
    this.setState({ showSuccess: false});
  };

  handleNavigation = (path: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    this.send(msgData);
  };
  handleNullValue = (value: null | { url: string }) => {
    return value === null ? "-" : value.url;
  }
  // Customizable Area End
}