import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { handleFilterComplexList } from "../../../../components/src/UtilFunction.web";
import * as Yup from "yup";
interface FormValue {
  country: string;
  type: string;
  individualBuilding: string;
  city: string;
  complex: string;
  building: string;
  fullName: string;
  unit: string;
  countryCode: string;
  phoneNumber: string;
  emailID: string;
}

interface IUserDetail {
  idUrl: string;
  complex: string;
  idNumber: string | number;
  email: string;
  name: string;
  address: string;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  formValue: FormValue;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  requestsManagementList: any;
  initialValues: any;
  dataList: any;
  inviteMemberModelOpen: boolean;
  complexBuildingFilterList: any;

  countryList: any[],
  companyList: any[],
  cityList: any[],
  complexList: any[];
  filterComplexList: any[];
  buildingsList: any[];
  unitList: any[];
  userList: any[];

  formValue: FormValue;
  disableField: boolean;
  disableFieldBuilding: boolean;
  tabComplex: boolean;
  filter: {
    country: string;
    city: string;
    complex: string;
    company: string;
  };

  isInvitePersonOpen: boolean;
  isUserSelected: boolean;
  isInviteUserListEmpty: boolean;
  inviteUserList: any[];
  person: {
    name: string;
    email: string;
    phone: string;
  },
  userDetail: IUserDetail;
  user: any;
  complexId: string;

  isBuildingSelected: boolean;
  isAlready: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CERequestManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetRequestsManagementList: any;
  GetMemberInvitationList: any;
  GetCountryFilterList: any;
  GetCityFilterList: any;
  GetComplexCallId: any;
  GetCompanyCallId: any;
  GetAllBuildingsCallId: any;
  GetAllUnitCallId: any;
  showAllUserAPICallId: any;
  GetRMUserListCallId: string = "";
  GetRMFindUserDetailCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      requestsManagementList: [],
      dataList: [],
      unitList: [],
      userList: [],
      companyList: [],
      disableField: false,
      disableFieldBuilding: false,
      initialValues: {
        userType: "",
        fullName: "",
        emailId: "",
        phoneNumber: "",
        countryCode: "",
        selectedCountry: "",
        selectedCity: "",
        selectedComplex: "",
        selectedBuilding: "",
        selectedUnit: "",
        selectedIndividualBuilding: "",
      },
      filter: {
        country: "",
        complex: "",
        city: "",
        company: ""
      },
      tabComplex: true,

      buildingsList: [],
      countryList: [],
      cityList: [],
      complexList: [],
      filterComplexList: [],
      complexBuildingFilterList: [
        {
          label: "Complex",
          value: 'complex'
        },
        {
          label: "Individual Building",
          value: 'indiviualBuilding'
        },
      ],

      formValue: {
        type: "",
        country: "",
        city: "",
        individualBuilding: "",
        complex: "",
        building: "",
        unit: "",
        fullName: "",
        emailID: "",
        countryCode: "+1",
        phoneNumber: ""
      },
      inviteMemberModelOpen: false,

      isInvitePersonOpen: false,
      isUserSelected: false,
      isInviteUserListEmpty: false,
      inviteUserList: [],
      person: {
        name: "",
        email: "",
        phone: "",
      },
      userDetail: {
        idUrl: "",
        name: "",
        idNumber: "",
        email: "",
        address: "",
        complex: "",
      },
      user: null,
      complexId: "",

      isBuildingSelected: false,
      isAlready: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getRequestsManagementList();
    this.getAllUser();
    this.getFilterListTable();
  }
  getFilterListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryFilterList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }

  handleDisableField = async (type: any, complex: any) => {
    const selectedUser = this.state.userList.filter(element => element.value === type)
    this.setState({ disableField: selectedUser[0].label !== "Owner Resident" && selectedUser[0].label !== "Owner" && selectedUser[0].label !== "Tenant" && selectedUser[0].label !== "Property Manager",
    disableFieldBuilding: (selectedUser[0].label !== "Owner Resident" && selectedUser[0].label !== "Owner" && selectedUser[0].label !== "Tenant" && selectedUser[0].label !== "Property Manager") || complex === "indiviualBuilding" });
  }

  meetingValidation: any = Yup.object().shape({
    country: Yup.string().required("Required").matches(/\S/, "Required"),
    type: Yup.string().required("Required").matches(/\S/, "Required"),
    fullName: Yup.string().required("Required").max(100, "Maximum length of Name should be 100 character").matches(/\S/, "Required"),
    complex: Yup.string().required("Required").matches(/\S/, "Required"),
    city: Yup.string().required("Required").matches(/\S/, "Required"),
    unit: Yup.mixed().test("unit", "Required", (value: any) => {
      if (!value && !this.state.disableField) {
        return false;
      }
      return true;
    }),
    phoneNumber: Yup.string().required("Required").matches(/\S/, "Required"),
    individualBuilding: Yup.string().required("Required").matches(/\S/, "Required"),
    emailID: Yup.string().email("Invalid email format").required("Required"),
    building: Yup.mixed().test("unit", "Required", (value: any) => {
      if (!value && !this.state.disableFieldBuilding ) {
        return false;
      }
      return true;
    }),
  });
  async handleFormSubmit(value: any) {
    this.setState({ inviteMemberModelOpen: !this.state.inviteMemberModelOpen, isInvitePersonOpen: false }, async () => {
    const formData = new FormData();
    formData.append("member_invitation[full_name]", value.fullName);
    formData.append("member_invitation[phone_number]", value.countryCode + "-" + value.phoneNumber);
    formData.append("member_invitation[email_address]", value.emailID.toLowerCase());
    formData.append("member_invitation[building_management_id]", value.unit?.building_management_id);
    formData.append("member_invitation[role_id]", value?.type);
    formData.append("member_invitation[society_management_id]", value?.complex);
    formData.append("member_invitation[apartment_management_id]", value.unit?.id);
    this.GetMemberInvitationList = await apiCall({
      method: configJSON.sellerDetailsAPIMethodPOST,
      endPoint: configJSON.createMemberInvitationEndPoint,
      body: formData
    });
    });
  }
  getRequestsManagementList = async () => {
    this.setState({ loading: true })
    this.GetRequestsManagementList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getRequestsManagementListEndPoint + `?is_building=${!this.state.tabComplex}&country${this.getData(this.state.filter.country)}&city${this.getData(this.state.filter.city)}&complex${this.getData(this.state.filter.complex)}&company${this.getData(this.state.filter.company)}`,
    });
  }
  getData = (value: any) => (value !== "" && value !== "all") ? `=${value}` : "";
  handleRequestsManagementList = (responseJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({
        requestsManagementList: responseJson.data || []
      })
    }
  }
  handleFiltersList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        userList:
          responseJson.data.map((item: any) => ({
            label: item.attributes.name, value: item.id
          }))
      })
    }
  }

  handleComplexList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ complexList: responseJson.data }, () => {
        this.setState({ filterComplexList: handleFilterComplexList(!this.state.isBuildingSelected ? 1 : 2, this.state.complexList) });
      });
    }
  }

  handleUnitList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        unitList: responseJson.data.map((item: any) => ({
          label: item.attributes.apartment_name,
          value: {
            id: item.id,
            society_management_id: item.attributes.society_management.id,
            building_management_id: item.attributes.building_management.id
          }
        })
        )
      })
    }
  }
  handleCompanyList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ companyList: responseJson.data.map((item: any) => ({ label: item?.attributes?.company_name, value: item?.id })) }
      )
    }
  }
  handleBuildingList = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingsList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id })) }
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ countryList: responseJson?.data?.countries.map((item: any) => ({ label: item, value: item })) });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ cityList: responseJson?.data?.cities.map((item: any) => ({ label: item, value: item })) });
    }
  };
  handleCountryCodeValue = (value: any, keyState: any) => {
    this.setState({ initialValues: { ...this.state.initialValues, [keyState]: value } })
  }
  handleTab = () => {
    this.setState({ tabComplex: !this.state.tabComplex, isBuildingSelected: this.state.tabComplex, filter: {
      country: "",
      complex: "",
      city: "",
      company: ""
      }}, () => {
      this.getRequestsManagementList();
    })
  }
  handleFilterChange = (event: any, keyState: any) => {
    if (keyState === "country") {
      this.getCityList(event.target.value)
    } else if (keyState === "city") {
      this.getComplexList(event.target.value)
      this.getCompanyList(event.target.value)
    } else if (keyState === "complex") {
      const id = event.target.value.id;
      this.getBuildingsList(id);
    }
    this.setState({ filter: { ...this.state.filter, [keyState]: event.target.value } })
  }
  getAllUser = async () => {
    this.showAllUserAPICallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getUserTypeEndPoint,
    });
  };
  getBuildingsList = async (id: any) => {
    this.GetAllBuildingsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
    });
  };
  getUnitList = async (complexId: any, buildingId: any) => {
    this.GetAllUnitCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/company_employee/complexes/complex_unit_list?id=${complexId}&building_management_id=${buildingId}`,
    });
  };
  getComplexList = async (city: any) => {
    this.GetComplexCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getComplexListEndPoint + `?city=${city}`
    });
  };
  getCompanyList = async (city: any) => {
    this.GetCompanyCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getCompanyListEndPoint
    });
  }
  getCityList = async (country: string) => {
    this.GetCityFilterList = await apiCall({
      contentType: configJSON.contentTypeApiAddDetail,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getCityListEndPoint + `?country=${country}`
    })
  }

  handleIsComplex = (value: string) => {
    this.setState({
      filterComplexList: handleFilterComplexList(value === "complex" ? 1 : 2, this.state.complexList),
    });
  };

  handleClose = () => {
    this.setState({ inviteMemberModelOpen: false })
  }
  handleInviteMember = () => {
    this.setState({
      inviteMemberModelOpen: true, formValue: {
        type: "",
        country: "",
        city: "",
        individualBuilding: this.state.tabComplex ? "complex" : "indiviualBuilding",
        complex: "",
        building: "",
        unit: "",
        fullName: "",
        emailID: "",
        countryCode: "+1",
        phoneNumber: ""
      }
    })
  }
  handleMemberInvitation = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.getRequestsManagementList()
    }
  }

  handleFindInviteMemberModal = (id: string) => {
    this.setState({
      complexId: id, isInvitePersonOpen: !this.state.isInvitePersonOpen, person: { name: "", email: "", phone: "" }, isUserSelected: false, inviteUserList: [], isInviteUserListEmpty: false, formValue: {
        ...this.state.formValue,
        individualBuilding: this.state.tabComplex ? "complex" : "indiviualBuilding",
      },
      disableFieldBuilding: !this.state.tabComplex
    });
  }

  getFindUserList = async () => {
    const { email, name, phone } = this.state.person;
    this.GetRMUserListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${this.state.complexId}/bx_block_contract/employee_contracts/find_person?phone_number=${phone}&email=${email}&name=${name}`,
    });
  };

  handleGetFindUserListResponse = (responseJson: { data: any[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ inviteUserList: responseJson.data, isInviteUserListEmpty: true });
    }
  };

  getRMFindUserDetail = (id: string | number) => {
    this.setState({ isUserSelected: true }, async () => {
      this.GetRMFindUserDetailCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `society_managements/${this.state.complexId}/bx_block_contract/employee_contracts/find_person_details?id=${id}`,
      });
    });
  };

  handleRMGetFindUserDetailResponse = (responseJson: { data: any }) => {
    if (responseJson && responseJson.data) {
      const user = responseJson.data;
      this.setState({
        user: user,
        userDetail: {
          idUrl: "",
          name: user.attributes.full_name,
          idNumber: user.id,
          email: user.attributes.email,
          address: user.attributes.address?.address || "",
          complex: user.attributes.complex?.name || "",
        },
      });
    }
  };

  handleSelectUser = () => {
    const user = this.state.user;
    
    this.setState({
      inviteMemberModelOpen: true,
      isAlready: true,
      disableFieldBuilding: !user.attributes.complex.is_building,
      formValue: {
        type: "",
        country: user.attributes.address.country,
        city: user.attributes.address.city,
        individualBuilding: user.attributes.complex.is_building ? "complex" : "indiviualBuilding",
        complex: user.attributes.complex.id,
        building: "",
        unit: "",
        fullName: user.attributes.full_name,
        emailID: user.attributes.email,
        countryCode: user.attributes.full_phone_number.split("-")[0],
        phoneNumber: user.attributes.full_phone_number.split("-")[1],
      },
    }, () => {
      this.getCityList(user.attributes.address.country);
      this.getComplexList(user.attributes.address.city);
      this.getBuildingsList(user.attributes.complex.id);
      this.getUnitList(user.attributes.complex.id, "");
    });
  }

  inviteNewMemberFun = () => {
    this.setState({ inviteMemberModelOpen: true, isAlready: false, formValue: {
      type: "",
      country: "",
      city: "",
      individualBuilding: this.state.tabComplex ? "complex" : "indiviualBuilding",
      complex: "",
      building: "",
      unit: "",
      fullName: "",
      emailID: "",
      countryCode: "+1",
      phoneNumber: ""
    } });
  }

  handleActive = (tab: boolean) => {
    return tab ? "active" : "";
  }

  handleUrl = () => {
    return this.state.tabComplex ? "Complex" : "IndividualBuilding";
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetRequestsManagementList:
          this.handleRequestsManagementList(responseJson);
          break;
        case this.GetMemberInvitationList:
          this.handleMemberInvitation(responseJson);
          break;
        case this.GetCountryFilterList:
          this.handleCountryResponse(responseJson);
          break;
        case this.GetCityFilterList:
          this.handleCityResponse(responseJson);
          break;
        case this.GetComplexCallId:
          this.handleComplexList(responseJson);
          break;
        case this.GetAllBuildingsCallId:
          this.handleBuildingList(responseJson);
          break;
        case this.GetAllUnitCallId:
          this.handleUnitList(responseJson)
          break;
        case this.showAllUserAPICallId:
          this.handleFiltersList(responseJson)
          break;
        case this.GetCompanyCallId:
          this.handleCompanyList(responseJson)
          break;
        case this.GetRMUserListCallId:
          this.handleGetFindUserListResponse(responseJson)
          break;
        case this.GetRMFindUserDetailCallId:
          this.handleRMGetFindUserDetailResponse(responseJson)
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }
}
