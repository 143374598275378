import React from "react";
// Customizable Area Start
import MainScreen from "./Company Employee/MainScreen.web";
import { SearchOutlined } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
    Box,
    Typography,
    Paper,
    Select,
    MenuItem,
    Divider,
    styled,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Popover,
    IconButton,
    Breadcrumbs,
    TextField,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { TFunction, AssignedAccountManagerItem } from './AccountManagerController.web';
import Loader from "../../../components/src/Loader.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import AlertError from "../../../components/src/AlertError.web";

// Customizable Area End

import AccountManagerController, { Props, } from "./AccountManagerController.web";

class AccountManager extends AccountManagerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderAccountManager = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <>
                <AccountAssignedManagerStyle>
                    <Breadcrumbs>
                        <Typography className="mangingDashboard" onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"myDashboardTitleTestId"}> {t("My Dashoards")} </Typography>
                        <Typography className="mangingDashboard" onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"generalDashboardTitleTestId"}>{t("General Dashboard")}</Typography>
                        <Typography className="assignedAccount">{t("Assigned Account Mangers")}</Typography>
                    </Breadcrumbs>
                        <Typography className="headingStyle">
                            {t("Assigned Account Managers")}
                        </Typography>
                    <Paper className="paperStyle">
                        <Box className="managerStyle">
                            <Typography className="headingManger">
                                {t("List of assigned account Manager")}
                            </Typography>
                            <Box className="selectStyles">
                                <Select
                                    value={this.state.sortBy}
                                    className="selectStyle"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    IconComponent={KeyboardArrowDownIcon}
                                    displayEmpty
                                    MenuProps={{
                                        style: { marginTop: "50px" }
                                    }}
                                    onChange={this.sortByChange}
                                    data-testId={"sortBySelectTestId"}
                                >
                                    <MenuItem value="" className="defaultSelect">
                                        <em>{t("Sort By")}</em>
                                    </MenuItem>
                                </Select>
                                <TextField
                                    placeholder="Search By Name or Id "
                                    name="search"
                                    InputProps={{startAdornment:<SearchOutlined className="searchIcon" />}}
                                    className="searchInputStyle"
                                    onChange={this.searchChange}
                                    data-testId={"searchInputTestId"}
                                    value={this.state.searchText}
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                        <Divider />
                        {this.tabelOfAssignedManagerRendering()}
                    </Paper>
                </AccountAssignedManagerStyle>
            </>
        );
    };

    tabelOfAssignedManagerRendering = () => {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);        
        const popoverId = open ? "simple-popover" : undefined;
        const { t }: { t: TFunction } = this.props;
        return (
            <>
                <TableContainer className="tableStyle">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableCellHeading">#</TableCell>
                                <TableCell className="tableCellHeading">{t("Account Manager")}</TableCell>
                                <TableCell className="tableCellHeading">{t("ID")}</TableCell>
                                <TableCell className="tableCellHeading">{t("Email Address")}</TableCell>
                                <TableCell className="tableCellHeading">{t("Assigned Complexes")}</TableCell>
                                <TableCell className="tableCellHeading">{t("Assigned Buildings")}</TableCell>
                                <TableCell className="tableCellHeading">{t("Assigned Owners")}</TableCell>
                                <TableCell className="tableCellHeading">{t("Assigned Propert Developers")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.accountManagerDataSave.map((accountManager: AssignedAccountManagerItem, accountManagerIndex) => (
                                <TableRow key={accountManager.id}>
                                    <TableCell >{accountManagerIndex + 1}</TableCell>
                                    <TableCell data-testId={`accountManagerName${accountManagerIndex}`}>{accountManager.attributes.full_name}</TableCell>
                                    <TableCell >{accountManager.id}</TableCell>
                                    <TableCell >{accountManager.attributes.email}</TableCell>
                                    <TableCell >{accountManager.attributes.assigend_complexes}</TableCell>
                                    <TableCell >{accountManager.attributes.assigend_buildings}</TableCell>
                                    <TableCell >{accountManager.attributes.assigend_property_developers}</TableCell>
                                    <TableCell >
                                        <Box className="tableMoreOption">
                                            <IconButton onClick={this.handleClick} data-testId={`accountManagerMenu${accountManagerIndex}`}>
                                                <MoreVertIcon className="threeDotsStyle" fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className="paginationStyle">
                    <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData?.current_page} data-testId={"accountManagerPaginationTestId"}/>
                </Box>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    id={popoverId}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        style: {
                            marginLeft: "20px",
                        },
                    }}
                    data-testId={'tablePopOverTestId'}
                >
                    <MenuItem data-testId={"viewDetailsTestId"}>{t("View Details")}</MenuItem>
                </Popover>
            </>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.renderAccountManager()}
                <Loader loading={this.state.loader}/>
                <AlertError show={this.state.showError} handleClose={this.handleAlertError} message={this.state.errorMessage} data-testId={"errorAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(AccountManager)
export {AccountManager};
const AccountAssignedManagerStyle = styled(Box)({
    backgroundColor: "#f5f7ff",
    padding: "35px",
    "& .paginationStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        marginLeft: "10px",
    },
    "& .tableMoreOption": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .mangingDashboard": {
        fontSize: "18px",
        fontWeight: 400,
        color: "#3D424B",
        cursor: "pointer"
    },
    "& .assignedAccount": {
        color: "#2b6fed",
        fontSize: "18px"
    },
    "& .headingStyle": {
        fontSize: "30px",
        fontWeight: "bold",
        marginTop: "10px",
    },
    "& .headingManger": {
        fontSize: "24px",
        fontWeight: 600,
    },
    "& .managerStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
    },
    "& .paperStyle": {
        width: "100%",
        marginTop: "20px",
    },
    "& .selectStyles": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-Between",
    },
    "& .searchInputStyle": {
        width: 230,
        borderRadius: "10px",
        "& .MuiOutlinedInput-input":{
            padding:"9px 0px 9px 30px"
        },
        border: "1px solid #e8e8e8"
    },
    "& .selectStyle": {
        width: "130px",
        height: "40px",
        marginRight: "15px",
        borderRadius: "8px",
        padding: "0px 15px",
        color: "grey",
        "& ..MuiOutlinedInput-input ": {
            padding: "10px",
        },
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
        border: "1px solid #EBEBEC",
    },
    "& .tableCellHeading": {
        fontWeight: 700,
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#FC8E45",
            color: "white",
        },
        "& .MuiPaginationItem-outlined": {
            border: " 1px solid #EBEBEC",
            color: "grey",
        },
    },
    "& .resultShowingOutOf": {
        fontWeight: 600,
    },
    "& .resultShowing": {
        color: "#FC8E45",
        fontWeight: 600,
    },
    "& .defaultSelect": {
        color: "#EBEBEC",
    },
    "& .searchIcon": {
        color: "#979797",
        marginLeft: "0px"
    }
});

// Customizable Area End
