import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
const { baseURL } = require("../../../framework/src/config");

interface UserFilters {
    city: string;
    complex: string;
    building: string;
    country: string;
    sales_manager: string;
    account_manager:string;
    role: string;
    page: number;
    per_page: string;
    [key: string]: string | number | boolean | null;
  }


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    tags:string
    open:boolean
    anchorEl: HTMLElement | null;
    viwePage:boolean
    individualbuilding: string
    selectCountry: string[]
    selectedCountry: string
    selectCity: string[]
    selectComplex: { id: string; name: string }[]
    selectBuilding: { id: string; name: string }[]
    selectUnit: string[]
    selectedUnit: string
    selectUserType: string
    token: string;
    selectCompany: string[]
    selectedCompany: string
    selectedCity: string
    selectedComplex: string
    selectedBuilding: string
    role: {id: string; attributes:{name:string}}[]
    selectedRole:string
    salseManage:{ id: string; first_name:string}[]
    selectedSalse:string
    userSubscription:{ id: string; attributes:{title:string}}[]
    selectSubscription:string
    subscriptionReports:[]
    accountManager:  { id: string; first_name:string}[]
    account:string
    countPage:number | undefined
    totalPages:number
    totalCount: number
    sort:string
    subscription:string;
    reportGgenerated:string
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SubscriptionReportsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCountryApiId: string = ""
    getCityApiId: string = ""
    getComplexApiId: string = ""
    getBuildingApiId: string = ""
    getRoleApiId: string = ""
    getSalesManagerApiId: string = ""
    getuserSubscriptionTypeId: string =""
    subscriptionReportsApId: string = ""
    getAccountManagerApiId: string = ""
    accountManagerApiId: string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
           
            tags:"Trail Subscriptions to Expire",
            open: false,
            anchorEl: null,
            viwePage:false,
            individualbuilding: "",
            selectCountry: [],
            selectedCountry: "",
            selectCity: [],
            selectComplex: [],
            selectBuilding: [],
            selectUnit: [],
            selectUserType: "",
            token: "",
            selectCompany: [],
            selectedCompany: "",
            selectedCity: "",
            selectedComplex: "",
            selectedBuilding: "",
            selectedUnit: "",
            role: [],
            selectedRole:"",
            salseManage:[],
            selectedSalse: "",
            userSubscription:[],
            selectSubscription:"",
            subscriptionReports:[],
            accountManager:[],
            account:'',
            countPage:1,
            totalPages:1,
            totalCount:0,
            sort:'',
            subscription:'',
            reportGgenerated:''
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getBuildingApiId) {
                this.setState({ selectBuilding: responseJson.data.building_list })
            }  else if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies })
            } else if (apiRequestCallId === this.getRoleApiId) {
                this.setState({role:responseJson.data})
            } else if (apiRequestCallId === this.getSalesManagerApiId){
                this.setState({salseManage:responseJson.sales_manager_listing})
            } else if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.getuserSubscriptionTypeId){
                this.setState({userSubscription:responseJson.data})
            } else if (apiRequestCallId === this.subscriptionReportsApId) {                
                this.setState({subscriptionReports:responseJson.data,totalPages:responseJson?.meta?.pagination?.total_pages,totalCount:responseJson?.meta?.pagination?.total_count,reportGgenerated:responseJson?.meta?.report_generated})
            } else if (apiRequestCallId === this.getAccountManagerApiId){
                this.setState({accountManager:responseJson.account_manager_listing})
            } else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } 
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let token  = await getStorageData("loginSuccessToken");
        this.setState({token:token})
        this.handleBuildingApi()
        this.handleComplexApi()
        this.handleCityApi()
        this.handleCountryApi()
        this.handleRoleApi()
        this.handleSalseApi()
        this.subscriptionReportsApi("","Trial",1)
        this.accountManagerApi()
        this.subscriptionTypeApi()
    }

    handleTag = (name:string) => {
        this.setState({tags:name})
        if(name === "Trail Subscriptions to Expire"){
            this.subscriptionReportsApi("","Trial",1)
        } else if (name === "Active Subscriptions to Expire"){
            this.subscriptionReportsApi("","Active",1)
        }
    }

    handlePageChange = (_event:React.ChangeEvent<unknown>,page:number) => {
        const {tags} = this.state
        this.setState({countPage:page})
        if(tags === "Trail Subscriptions to Expire"){
            this.subscriptionReportsApi("","Trial",page,this.state.sort)
        } else if (tags === "Active Subscriptions to Expire"){
            this.subscriptionReportsApi("","Active",page,this.state.sort)
        }
    }

    handleSortChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ sort: value as string })
        this.subscriptionReportsApi("",this.state.subscription,1 ,value as string) 
    }

    handleSelectCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.handleCityApi(value as string)
        const values = {
            country: value ? value : "",
            city: this.state.selectedCity
        }
        this.handleComplexApi(values as {
            country: string,
            city: string
        })
        this.setState({ selectedCountry: value as string,selectedCity:"",selectedComplex:"" })
    };
    
    handleCountryFilterChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ individualbuilding: value as string })
    };

    handleSelectComplexChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.handleBuildingApi(value)
        this.setState({ selectedComplex: value as string,selectedBuilding:"" })
    };
    
    handleSelectCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        const values = {
            country: this.state.selectedCountry,
            city: value ? value :""
          }
          this.handleComplexApi(values as {
            country:string,
            city:string
          }) 
        this.setState({ selectedCity: value as string,selectedComplex:"" })
    };

    handleSelectBuildingChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedBuilding: value as string })
    };

    handleRoleChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedRole: value as string })
    };

    handleCountryApi = (value?: string) => {
        const params = value ? `?company_id=${value}` : ""
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountryApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint + `${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCityApi = (value?: string) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const params = `?country=${value}` 

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCityApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint + `${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleComplexApi = (value?: {
        country: string,
        city: string
    }) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const params = value ? `?country=${value.country}&city=${value.city}` : ""

        this.getComplexApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.complexApiEndpoin + `${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
      
      handleBuildingApi = (value?: unknown) => {
        const header = {
          "Content-Type": configJSON.exampleApiContentType,
          token:this.state.token
        };
        const params = value ? `?complex_id=${value}` : ""
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuildingApiId = requestMessage.messageId;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.buildingApiEndpoin + `${params}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

    handleRoleApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRoleApiId = requestMsg.messageId;

        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.roleApiEndPoint
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
        return true;
    }

    handleSaleChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedSalse: value as string })
    }

    handleAccountChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ account: value as string })
    }

    handleSalseApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSalesManagerApiId = requestMessages.messageId;
        
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.salesManagerApiEndPoint
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    subscriptionTypeApi = () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getuserSubscriptionTypeId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userSubscriptionTypeApiEndPoint 
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    accountManagerApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAccountManagerApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.accountManagerApiEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleUserrrSubscription = (event: ChangeEvent<{ value: unknown | string }>) => {
        const {value} = event.target
        this.setState({selectSubscription:value as string})
    }

    handleFilter = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
            this.subscriptionReportsApi(value as string,this.state.subscription,1)
    }

    handleSearch = () => {
        this.subscriptionReportsApi("",this.state.subscription,1)
    }

    subscriptionReportsApi = (value :string ,subscription:string,page:number ,sort?:string | undefined) => {
        console.log(sort,"hheleowuif");
        
        this.setState({subscription:subscription})
        const { selectedCountry,  selectedCity, selectedComplex, selectedBuilding,selectedRole,selectSubscription,selectedSalse,account } = this.state
        let userFilters: UserFilters = {
            country: selectedCountry,
            city: selectedCity,
            complex: selectedComplex,
            building: selectedBuilding,
            role: selectedRole,
            sales_manager: selectedSalse,
            account_manager: account,
            subscription_type: selectSubscription,
            page: page,
            per_page: "10",
            sort_by:sort ?? "",
            search:value
        }

        let initialEndPoint = `${configJSON.subscriptionReportsApiEndPoint}?subscription=${subscription}&`;
        let isFirstParam = true;

        for (let filter in userFilters) {
            if (userFilters[filter] && userFilters[filter] !== 'All') {
                if (!isFirstParam) {
                    initialEndPoint += '&';
                }
                initialEndPoint += `${filter}=${userFilters[filter]}`;
                isFirstParam = false;
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.subscriptionReportsApId = requestMessaged.messageId;
        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            initialEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    goTosubscriptionReportDetailsPage = () => {
        this.setState({viwePage:true})
      };

      handleDownloadReport = () => {
        this.downloadPdf(
            `bx_block_report/subscription_reports/download_report?subscription=${this.state.subscription}`, `subscription_report.pdf`
          );
    }
    downloadPdf = async (path: string, fileName: string) => {
        const myHeaders = new Headers();
        myHeaders.append("token", this.state.token);
        let requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        const response = await fetch(`${baseURL}/${path}`, requestOptions);
        const resBlob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([resBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) {
            link.parentNode.removeChild(link);
        }
      };

    // Customizable Area End
}
