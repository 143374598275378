import React from "react";
// Customizable Area Start
import {
    Box,
    Input,
    Button,
    Typography,
    IconButton,
    Paper,
    Select,
    MenuItem,
    Divider,
    styled,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Popover,
    Breadcrumbs,
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import { I18nInterface, TFunction, PropertyManagerListItem } from './PropertyManagerController.web';
import { languageCondition } from "../../../components/src/ConditionManager.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import AlertError from "../../../components/src/AlertError.web";

// Customizable Area End

import PropertyManagerController, { Props, } from "./PropertyManagerController.web";

class PropertyManager extends PropertyManagerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    cooPropertyManager = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <>
                <CooPropertyManagerStyle>
                    <Breadcrumbs dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="mangingDashboard" dir={languageCondition(language, "rtl", "ltr")}>
                            {t("My Dashoards")}
                        </Typography>
                        <Typography className="mangingDashboard" dir={languageCondition(language, "rtl", "ltr")}>
                            {t("General Dashboard")}
                        </Typography>
                        <Typography className="assignedAccount" dir={languageCondition(language, "rtl", "ltr")}>
                            {t("RegisteredProperty Managers")}
                        </Typography>
                    </Breadcrumbs>
                    <Typography className="headingStyle" dir={languageCondition(language, "rtl", "ltr")}>
                        {t("Registred Property Managers")}
                    </Typography>
                    <Box className="selctStyles" dir={languageCondition(language, "rtl", "ltr")}>
                        <Box className="selectAllItem" dir={languageCondition(language, "rtl", "ltr")}>
                            <Select
                                value={this.state.country}
                                onChange={this.handleCountryChange}
                                data-testId={"countrySelectTestId"}
                                className="selectManger"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                MenuProps={{
                                    style: { marginTop: "50px" }
                                }}
                                dir={languageCondition(language, "rtl", "ltr")}
                            >
                                <MenuItem value="" className="defaultSelect">{t("Select Country")}</MenuItem>
                                {this.state.countryList.map((country: string, countryIndex: number)=>{
                                    return <MenuItem value={country} className="defaultSelect" key={countryIndex} data-testId={`countryItem${countryIndex}`}>{country}</MenuItem>
                                })}
                            </Select>
                            <Select
                                value={this.state.city}
                                onChange={this.handleCityChange}
                                data-testId={"citySelectTestId"}
                                className="selectManger"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                MenuProps={{
                                    style: { marginTop: "50px" }
                                }}
                                dir={languageCondition(language, "rtl", "ltr")}
                            >
                                <MenuItem value="" className="defaultSelect">{t("Select City")}</MenuItem>
                                {this.state.cityList.map((city: string, cityIndex: number)=>{
                                    return <MenuItem value={city} className="defaultSelect" key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                                })}
                            </Select>
                            <Select
                                value={this.state.propertyManagerId}
                                onChange={this.handlePropertyManagerIdChange}
                                data-testId={"propertyManagerIdSelectTestId"}
                                className="selectManger"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                IconComponent={KeyboardArrowDownIcon}
                                displayEmpty
                                MenuProps={{
                                    style: { marginTop: "50px" }
                                }}
                                dir={languageCondition(language, "rtl", "ltr")}
                            >
                                <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>{t("Property Manager Id")}</MenuItem>
                                {this.state.propertyManagerList.map((propertyManager: PropertyManagerListItem,propertyManagerIndex:number)=>{
                                    return <MenuItem value={propertyManager.id} key={propertyManagerIndex} className="defaultSelect">{propertyManager.id}</MenuItem>
                                })}
                            </Select>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SearchOutlined />}
                            className="searchButton"
                            dir={languageCondition(language, "rtl", "ltr")}
                            onClick={() => this.getPropertyManagerList("1")}
                            data-testId={"searchButtonTestId"}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                    <Paper className="paperStyle">
                        <Box className="managerStyle" dir={languageCondition(language, "rtl", "ltr")}>
                            <Typography className="headingManger" dir={languageCondition(language, "rtl", "ltr")}>
                                {t("List of assigned Sales Manager")}
                            </Typography>
                            <Box className="selectStyles" dir={languageCondition(language, "rtl", "ltr")}>
                                <Select
                                    value={this.state.sortBy}
                                    onChange={this.handleSortByChange}
                                    data-testId={"sortBySelectTestId"}
                                    className="selectStyle"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    IconComponent={KeyboardArrowDownIcon}
                                    displayEmpty
                                    MenuProps={{
                                        style: { marginTop: "50px" }
                                    }}
                                    dir={languageCondition(language, "rtl", "ltr")}
                                >
                                    <MenuItem value="" className="defaultSelect" dir={languageCondition(language, "rtl", "ltr")}>
                                        {t("Sort By")}
                                    </MenuItem>
                                    <MenuItem value={"asc"}>{t("Assending")}</MenuItem>
                                    <MenuItem value={"dsc"}>{t("Decending")}</MenuItem>
                                </Select>
                                <Input
                                    placeholder={t("Search By Name or Id")}
                                    disableUnderline
                                    name="search"
                                    startAdornment={<SearchOutlined />}
                                    className="searchInputStyle"
                                    value={this.state.searchText}
                                    onChange={this.handleSearchInputChange}
                                    data-testId={"searchInputTestId"}
                                    dir={languageCondition(language, "rtl", "ltr")}
                                />
                            </Box>
                        </Box>
                        <Divider className="dividerStyle" />
                        {this.tabelOfPropertyManager()}
                    </Paper>
                </CooPropertyManagerStyle>
            </>
        );
    };

    tabelOfPropertyManager = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const popoverId = open ? "simple-popover" : undefined;
        return (
            <>
                <TableContainer className="tableStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <Table dir={languageCondition(language, "rtl", "ltr")}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("#")}
                                </TableCell>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("Property Manager Name")}
                                </TableCell>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("Property Manager ID")}
                                </TableCell>
                                <TableCell align="left" className="tableHeaderStyle">
                                    {t("Managed Units")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{ paddingLeft: "10px" }}>
                            {this.state.propertyManagerList.length === 0 ? <MenuItem>No Data Found</MenuItem> : this.state.propertyManagerList.map((accountManager: PropertyManagerListItem, accountManagerIndex: number) => (
                                <TableRow key={accountManager.id}>
                                    <TableCell>{accountManagerIndex + 1}</TableCell>
                                    <TableCell data-testId={`propertyManagerName${accountManagerIndex}`}>{accountManager.attributes.name}</TableCell>
                                    <TableCell>{accountManager.id}</TableCell>
                                    <TableCell>
                                        {" "}
                                        <Box className="tableMoreOption">
                                            {accountManager.id}{" "}
                                            <IconButton onClick={this.handleClick} data-testId={`tableMenuBtnTestId${accountManagerIndex}`}>
                                                <MoreVertIcon
                                                    className="threeDotsStyle"
                                                    fontSize="small"

                                                />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box className="paginationStyle" dir={languageCondition(language, "rtl", "ltr")}>
                    <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData.current_page} data-testId={"contractListPagintionTestId"} />
                </Box>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    data-testId={"popoverTestId"}
                    id={popoverId}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        style: {
                            marginLeft: "20px",
                        },
                    }}
                    dir={languageCondition(language, "rtl", "ltr")}
                >
                    <MenuItem data-testId={"tableMenuItem1"} dir={languageCondition(language, "rtl", "ltr")}>{t("View Details")}</MenuItem>
                </Popover>
            </>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.cooPropertyManager()}
                <AlertError show={this.state.showError} handleClose={this.handleAlertClose} message={this.state.errorMessage} data-testId={"errorAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(PropertyManager);
export { PropertyManager };
const CooPropertyManagerStyle = styled(Box)({
    backgroundColor: "#f5f7ff",
    padding: "35px",
    "& .headingManger": {
        fontSize: "24px",
        fontWeight: 600,
    },
    "& .tableHeaderStyle": {
        fontWeight: 900,
        fontSize: "14px",
    },
    "& .mangingDashboard": {
        fontSize: "18px",
        fontWeight: 400,
        color: "#3D424B",
    },
    "& .assignedAccount": {
        color: "#3e7aeb",
    },
    "& .headingStyle": {
        fontSize: "30px",
        fontWeight: "bold",
        marginTop: "10px",
    },
    "& .selectAllItem": {
        display: "flex",
        alignItems: "center",
    },
    "& .tableMoreOption": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .selctStyles": {
        display: "flex",
        marginTop: "20px",
        alignItems: "center",
        color: "grey",
    },
    "& .dividerStyle": {
        height: "1px",
        backgroundColor: "#e9eaeb",
    },
    "& .selectManger": {
        background: "white",
        display: "flex",
        marginRight: "22px",
        width: "200px",
        flexWrap: "wrap",
        color: "grey",
        border: "1px solid #EBEBEC",
        borderRadius: "10px",
        height: "45px",
        padding: "0px 20px 0px 10px",
        "& .MuiSelect-outlined.MuiSelect-outlined": {
        },
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: "10px",
        },
    },
    "& .searchButton": {
        height: "40px",
        backgroundColor: "#2b70ed",
        color: "white",
        padding: "15px 25px",
        borderRaduis: "10px",
    },
    "& .managerStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
    },
    "& .selectStyle": {
        width: "130px",
        height: "40px",
        marginRight: "15px",
        borderRadius: "8px",
        padding: "0px 15px",
        color: "grey",
        "& ..MuiOutlinedInput-input ": {
            padding: "10px",
        },
        "&.MuiInput-underline::after": {
            borderBottom: "0px",
        },
        "&.MuiOutlinedInput-root": {
            borderRadius: "20px",
        },
        "&.MuiInput-underline::before": {
            borderBottom: "0px",
        },
        border: "1px solid #EBEBEC",
    },
    "& .paperStyle": {
        width: "100%",
        marginTop: "30px",
    },
    "& .selectStyles": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-Between",
    },
    "& .paginationStyle": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        marginLeft: "10px",
    },
    "& .threeDotsStyle": {
        color: "grey",
    },
    "& .showingResult": {
        marginLeft: "10px",
    },
    "& .tablePagination": {
        color: "#EBEBEC",
        "& .MuiPaginationItem-page.Mui-selected": {
            background: "#FC8E45",
            color: "white",
        },
        "& .MuiPaginationItem-outlined": {
            border: " 1px solid #EBEBEC",
            color: "grey",
        },
    },
    "& .resultShowingOutOf": {
        fontWeight: 800,
    },
    "& .resultShowing": {
        color: "#FC8E45",
        fontWeight: 600,
    },
    "& .defaultSelect": {
        color: "#EBEBEC",
    },
    "& .searchInputStyle": {
        height: 40,
        width: 240,
        backgroundColor: "#fafafa",
        borderRadius: "5px",
        padding: "0px 15px 0px 15px",
        border: "1px solid #EBEBEC",
    },
});
// Customizable Area End