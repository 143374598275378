// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";

export interface Props {
  complexBuildingId: any;
  handleBack: any;
  handleNext: any;
  jurisdictionSelected: any;
  step: number;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  loading: boolean;

  cityList: any[];
  stateList: any[];
  countriesList: any[];

  locationFormValue: IBuildingLocationForm;

  locationId: string;
}

interface IBuildingLocationForm {
  link: string;
  state: string;
  lat: string;
  city: string;
  country: string;
  landmark: string;
  zipCode: string;
  long: string;
  address: string;
  street: string;
}

interface SS {
  id: any;
}

export default class CompEmpBuildingBlockStep4Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetIBuildingCountryListCallId: string = "";
  GetIBuildingStateListCallId: string = "";
  GetIBuildingCityListCallId: string = "";
  GetIBuildingAddressesDetailsCallId: string = "";
  AddEditIBuildingLocationCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      cityList: [],
      stateList: [],
      countriesList: [],

      locationFormValue: {
        link: "",
        address: "",
        long: "",
        lat: "",
        state: "",
        city: "",
        zipCode: "",
        landmark: "",
        country: "",
        street: "",
      },

      locationId: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetIBuildingCountryListCallId:
          return this.handleIBuildingCountryListResponse(responseJsonBlock);
        case this.GetIBuildingStateListCallId:
          return this.handleIBuildingStateListResponse(responseJsonBlock);
        case this.GetIBuildingCityListCallId:
          return this.handleIBuildingCityListResponse(responseJsonBlock);
        case this.GetIBuildingAddressesDetailsCallId:
          return this.handleIBuildingAddressesDetailsResponse(responseJsonBlock);
        case this.AddEditIBuildingLocationCallId:
          return this.handleIBuildingLocationSubmitResponse();
        default:
          break;
      }

      if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJsonBlock);
      }
      ApiCatchErrorResponse(errorResponseBlock);
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true }, () => {
      this.getIBuildingCountryList();
      this.getIBuildingAddressesDetails();
    });
  }

  handleIBuildingLocationSubmit = async (values: IBuildingLocationForm) => {
    const data = new FormData();
    data.append("address[locationlink]", values.link);
    data.append("address[latitude]", values.lat);
    data.append("address[longitude]", values.long);
    data.append("address[country]", values.country);
    data.append("address[state]", values.state);
    data.append("address[city]", values.city);
    data.append("address[zipcode]", values.zipCode);
    data.append("address[landmark]", values.landmark);
    data.append("address[name]", values.street);
    data.append("address[address]", values.street);
    data.append("address[society_management_id]", this.props.complexBuildingId + "");

    if (this.state.locationId) {
      this.AddEditIBuildingLocationCallId = await apiCall({
        method: "PUT",
        endPoint: `bx_block_address/addresses/${this.state.locationId}`,
        body: data,
      });
    } else {
      this.AddEditIBuildingLocationCallId = await apiCall({
        method: "POST",
        endPoint: `bx_block_address/create_address`,
        body: data,
      });
    }
  };

  handleIBuildingLocationSubmitResponse = () => {
    this.setState({ loading: false }, () => {
      this.props.handleNext();
    });
  };

  getIBuildingCityList = async (id: string | number) => {
    this.GetIBuildingCityListCallId = await apiCall({
      contentType: "application/json",
      endPoint: `bx_block_address/city_by_state?state=${id}`,
      method: "GET",
    });
  };

  handleIBuildingCityListResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson) {
        this.setState({
          cityList: responseJson.map((item: any) => ({ value: item.name, label: item.name })),
        });
      }
    });
  };

  getIBuildingStateList = async (id: string | number) => {
    this.GetIBuildingStateListCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_address/state_list?country=${id}`,
    });
  };

  handleIBuildingStateListResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson) {
        this.setState({
          stateList: responseJson.map((item: any) => ({ value: item.name, label: item.name })),
        });
      }
    });
  };

  getIBuildingAddressesDetails = () => {
    this.setState({ loading: true }, async () => {
      this.GetIBuildingAddressesDetailsCallId = await apiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_address/address_deatils_by_society?society_management_id=${this.props.complexBuildingId}`,
      });
    });
  };

  handleIBuildingAddressesDetailsResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.address) {
        const address = responseJson.address;
        this.setState(
          {
            locationId: address.id,
            locationFormValue: {
              link: address.locationlink,
              address: address.address,
              lat: address.latitude,
              state: address.state,
              street: address.streetname,
              long: address.longitude,
              city: address.city,
              zipCode: address.zipcode,
              country: address.country,
              landmark: address.landmark,
            },
          },
          () => {
            this.getIBuildingStateList(address.country);
            this.getIBuildingCityList(address.state);
          }
        );
      } else {
        this.setState({ locationId: "" });
      }
    });
  };

  getIBuildingCountryList = async () => {
    this.GetIBuildingCountryListCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_address/addresses`,
    });
  };

  handleIBuildingCountryListResponse = (responseJson: { data: any[] }) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({
          countriesList: responseJson.data.map((item: any) => ({
            label: item.attributes.name,
            value: item.attributes.name,
          })),
        });
      }
    });
  };

  validationSchema = Yup.object().shape({
    country: Yup.string().required(this.props.t("Country is required")),
    long: Yup.string().required(this.props.t("Longitude is required")),
    lat: Yup.string().required(this.props.t("Latitude is required")),
    city: Yup.string().required(this.props.t("City is required")),
    state: Yup.string().required(this.props.t("State is required")),
    link: Yup.string().required(this.props.t("Location Link is required")).nullable(),
    zipCode: Yup.string()
      .max(7, this.props.t("Zip Code must be at most 7 characters"))
      .required(this.props.t("Zip Code is required"))
      .min(6, this.props.t("Zip Code must be at least 6 characters"))
      .nullable(),
    street: Yup.string().required(this.props.t("Street Name is required")).nullable(),
    address: Yup.string().required(this.props.t("Official Address is required")).nullable(),
    landmark: Yup.string().required(this.props.t("Landmark is required")).nullable(),
  });
}
// Customizable Area End
