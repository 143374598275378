import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    t: (value: string) => string;
    i18n: I18nInterface
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    company: string;
    country: string;
    city: string;
    complex: string;
    accountManager: string;
accountManagerList : Array<AccountMangerListItem>;
    subscriptionEndsIn: string;
    salesManager: string;
    salesManagerList: Array<SalesManagerItem>;
    totalComplexList: Array<TotalComplexListItem>;
    anchorEl: HTMLButtonElement | null;
    userRole: string | null;
    loginModal: boolean;
    skipModal: boolean;
    showPassword: boolean;
    loginTextPassword: string;
    searchText: string;
    email: string;
    countryList: Array<string>;
    companyList: Array<CompanyItem>;
    cityList: Array<string>;
    showError: boolean;
    showErrorMessage: string;
    paginationData: PaginationData | null;
    page: number;
    complexList: Array<ComplexListItem>;
    complexId: string;
    companyId: string;
    salesManagerId: string;
    loader: boolean;
    openReassignModal: boolean;
    modalBuildingId: number | null;
    modalBuildingName:string | null;
    modalCurrentSalesManager:string | null;
    modalCurrentAccountManager:string | null;
    accountManagerAssign: string;
    salesManagerAssign: string;
    noteText: string;
    ownerDetails: OwnerDetailsResponse;
    showSuccess: boolean ;
    showSuccessMessage: string;
    employeeAccountId: number | null;
    newAccountManagerList: Array<AccountManagerListItem>;
    newSalesManagerList: Array<SalesManagerListItem>;
    // Customizable Area End
}
// Customizable Area Start
export type TFunction = (value: string) => string

export interface I18nInterface {
    language: string;
}

export interface TotalComplexListItem {
    id: string | null;
    type: string | null;
    attributes: {
        id: number;
        building_name: string | null;
        company_name: CompanyName | null;
        account_manager: string | null;
        no_of_floor: number | null;
        totle_unit: number | null;
        totle_building: number | null;
        subscription_ends: string | null;
        country: string | null;
        city: string | null;
    }
}

export interface CompanyName {
    company_name: string;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface CountryResponse {
    data: {
        countries: Array<string>;
    }
}

export interface CityResponse {
    data: {
        cities: Array<string>;
    }
}

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>;
};

export interface ApiFailureResponseError {
    contract: string;
};

export interface CompanyListResponse {
    data: Array<CompanyItem>;
}

export interface CompanyItem {
    id: string;
    attributes: {
        name: string;
    }
}

export interface SalesManagerListResponse {
    sales_manager_listing: Array<SalesManagerItem>;
}

export interface SalesManagerItem {
    id: number;
    full_name: string;
}

export interface AccountMangerListResponse {
    account_manager_listing: Array<AccountMangerListItem>;
}

export interface AccountMangerListItem {
    id: number;
    full_name: string;
}
export interface PaginationData {
    current_page: null | number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: null | number;
    total_count: null | number;
}

export interface TotalComplexResponse {
    data: Array<TotalComplexListItem>;
    meta: {
        pagination: PaginationData | null
    }
}

export interface ComplexListResponse {
    data: Array<ComplexListItem>;
}

export interface ComplexListItem {
    id: string;
    attributes: {
        name: string | null;
    }
}

export interface OwnerDetailsResponse {
    data: {
        reporting_person: string | null;
        employee_account: string | null;
        object_id: number | null;
        object_name: string | null;
    }
}

export interface AccountListResponse {
    manager_liting: Array<AccountManagerListItem>;
}

export interface SalesListResponse {
    manager_liting: Array<SalesManagerListItem>;
}

export interface AccountManagerListItem {
    id: number | null;
    first_name: string;
}

export interface SalesManagerListItem {
    id: number | null;
    full_name: string;
}

export interface ReassignToAnotherManagerResponse {
    manager_listing: string;
}

export interface CompaniesDataErrorResponse {
    errors: Array<string>;
}

// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class TotalComplexController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryListApiCallId: string = "";
    cityListApiCallId: string = "";
    companiesListApiCallId: string = "";
    salesManagerListApiCallId: string = "";
    accountManagerListApiCallId: string = "";
    totalComplexListApiCallId: string = "";
    complexListApiCallId: string = "";
    ownerDetailsApiCallId: string = "";
    accountListApiCallId: string = "";
    salesListApiCallId: string = "";
    reassignAnotherManagerApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ContractDetailsId),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            company: "",
            country: "",
            city: "",
            complex: "",
            accountManager: "",
            accountManagerList: [],
            subscriptionEndsIn: "",
            salesManager: "",
            salesManagerList: [],
            anchorEl: null,
            userRole: "",
            searchText: "",
            email: "",
            totalComplexList: [],
            loginModal: false,
            skipModal: false,
            showPassword: false,
            loginTextPassword: "",
            countryList: [],
            companyList: [],
            cityList: [],
            showError: false,
            showErrorMessage: "",
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0,
            },
            page: 0,
            complexList: [],
            complexId: "",
            companyId: "",
            salesManagerId: "",
            loader: false,
            modalBuildingId: null,
            modalBuildingName: "",
            modalCurrentSalesManager: "",
            modalCurrentAccountManager: "",
            openReassignModal: false,
            salesManagerAssign: "",
            noteText: "",
            accountManagerAssign: "",
            ownerDetails: {
                data: {
                    reporting_person: null,
                    employee_account: null,
                    object_id: null,
                    object_name: null
                }
            },
            showSuccess: false,
            showSuccessMessage: "",
            employeeAccountId: null,
            newAccountManagerList: [],
            newSalesManagerList: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.contractDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.contractDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getCountryListItems();
        this.getUserType();
        this.getCompanyList();
        this.getSalesManagerList();
        this.getAccountManagerList();
        this.getTotalComplexList("1");
    };

    getUserType = async () => {
        const storedRole = await localStorage.getItem('role');
        this.setState({ userRole: storedRole });
    };

    totalComplexApiCall = async (apiPayloadData: APIPayloadType) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = apiPayloadData;
        const header = {
            "Content-Type": contentType,
            token: token
        };
        let totalComplexrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        totalComplexrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        totalComplexrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        totalComplexrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        totalComplexrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        )
        body && type !== "formData"
            ? totalComplexrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : totalComplexrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(totalComplexrequestMessage.id, totalComplexrequestMessage);
        return totalComplexrequestMessage.messageId;
    };

    contractDetailsApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: CountryResponse & CityResponse & CompanyListResponse & SalesManagerListResponse & AccountMangerListResponse & TotalComplexResponse & OwnerDetailsResponse & AccountListResponse & SalesListResponse & ReassignToAnotherManagerResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListItemsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerListApiCallId) {
            this.salesManagerListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerListApiCallId) {
            this.accountManagerListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.totalComplexListApiCallId) {
            this.totalComplexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerSucessCallBack(responseJson);
        }
    };

    contractDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
        if (apiRequestCallId === this.countryListApiCallId) {
            this.countryListItemsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.cityListItemsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.companiesListApiCallId) {
            this.companyListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesManagerListApiCallId) {
            this.salesManagerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountManagerListApiCallId) {
            this.accountManagerListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.totalComplexListApiCallId) {
            this.totalComplexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.complexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.ownerDetailsApiCallId) {
            this.ownerDetailsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.accountListApiCallId) {
            this.accountListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.salesListApiCallId) {
            this.salesListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.reassignAnotherManagerApiCallId) {
            this.reassignToAnotherAccountManagerFailureCallBack(responseJson);
        }
    };

    getCountryListItems = async () => {
        this.countryListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.countryEndPoint,
        });
    };

    countryListSucessCallBack = (response: CountryResponse) => {
        this.setState({ countryList: response.data.countries });
    };

    countryListItemsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getCityListItems = async () => {
        this.cityListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.cityEndPoint}${this.state.country}`
        });
    };

    cityListItemsSucessCallBack = (response: CityResponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListItemsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    };

    getCompanyList = async () => {
        this.companiesListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.companyListEndPoint
        });
    };

    companyListSucessCallBack = (response: CompanyListResponse) => {        
        this.setState({ companyList: response.data });
    };

    companyListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    }

    getSalesManagerList = async () => {
        this.salesManagerListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.salesManagerEndpoint
        });
    };

    salesManagerListSucessCallBack = (response: SalesManagerListResponse) => {
        this.setState({ salesManagerList: response.sales_manager_listing });
    };

    salesManagerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    }

    getAccountManagerList = async () => {
        this.accountManagerListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.accountManagerEndpoint
        });
    };

    accountManagerListSucessCallBack = (response: AccountMangerListResponse) => {
        this.setState({ accountManagerList: response.account_manager_listing });
    };

    accountManagerListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    }

    getTotalComplexList = async (page: string) => {
        this.setState({ loader: true });
        let endPoint = `${configJSON.totalComplexEndpoint}page=${page}`
        let { country, city, companyId, complexId, salesManagerId, subscriptionEndsIn, searchText } = this.state;
        if (country) {
            endPoint += `&country=${country}`;
        }
        if (city) {
            endPoint += `&city=${city}`;
        }
        if (complexId) {
            endPoint += `&complex=${complexId}`;
        }
        if (companyId) {
            endPoint += `&company=${companyId}`;
        }
        if (subscriptionEndsIn) {
            endPoint += `&subscription_ends_in=${subscriptionEndsIn}`;
        }
        if (searchText) {
            endPoint += `&search_term=${searchText}`;
        }
        if (salesManagerId) {
            endPoint += `&sales_manager=${salesManagerId}`;
        }
        this.totalComplexListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint
        });
    };

    totalComplexListSucessCallBack = (response: TotalComplexResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ totalComplexList: response.data, loader: false, paginationData: response.meta.pagination });
        } else {
            this.setState({
                totalComplexList: [],
                loader: false,
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0,
                }
            });
        }
    };

    totalComplexListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    }

    getComplexList = async () => {
        this.complexListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.complexListEndPoint}city=${this.state.city}&is_building=false`
        });
    };

    complexListSucessCallBack = (response: ComplexListResponse) => {
        this.setState({ complexList: response.data });
    };

    complexListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract });
    }

    getOwnerDetails = async (companyId: string | number | null) => {
        this.setState({ loader: true });
        this.ownerDetailsApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.ownerDetailsEndpoint}${companyId}`
        });
    };

    ownerDetailsSucessCallBack = (response: OwnerDetailsResponse) => {   
        this.setState({ 
            ownerDetails: response, 
            loader: false, 
            openReassignModal: true,
            modalBuildingId: response.data.object_id,
            modalBuildingName: response.data.object_name,
            modalCurrentSalesManager: response.data.reporting_person,
            modalCurrentAccountManager: response.data.employee_account
        });
    };

    ownerDetailsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    getAccountList = async (endpoint: string ) => {
        this.setState({ loader: true });
        this.accountListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endpoint
        });
    };

    accountListSucessCallBack = (response: AccountListResponse) => {
        this.setState({ newAccountManagerList : response.manager_liting, loader: false, openReassignModal: true });
    };

    accountListFailureCallBack =(response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    getSalesList = async (endpoint: string ) => {
        this.setState({ loader: true });
        this.salesListApiCallId = await this.totalComplexApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endpoint
        });
    };

    salesListSucessCallBack = (response: SalesListResponse) => {
        this.setState({ newSalesManagerList: response.manager_liting, loader: false, openReassignModal: true });
    };

    salesListFailureCallBack =(response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    reassignToAnotherAccountManager = async () => {
        this.setState({ loader: true });
        this.reassignAnotherManagerApiCallId = await this.totalComplexApiCall({
            method: configJSON.httpPut,
            endPoint: `${configJSON.reassignAccountManagerEndpoint}employee_account_id=${this.state.employeeAccountId}&object_id=${this.state.modalBuildingId}&note=${this.state.noteText}`
        });
    };

    reassignToAnotherAccountManagerSucessCallBack = (response: ReassignToAnotherManagerResponse) => {
        this.setState({ showSuccessMessage: response.manager_listing,openReassignModal: false, showSuccess: true,loader:false });
    };

    reassignToAnotherAccountManagerFailureCallBack = async (response: ApiFailureResponse) => {
        this.setState({ showError: true, showErrorMessage: response.errors[0].contract, loader: false });
    };

    handleUserType = (userType: string) => {
        if (this.state.userRole === userType) {
            return true
        } else {
            return false
        }
    };

    handleCompanyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let company = this.state.companyList.find((company) => { return company.attributes.name === event.target.value });
        if (company) {
            this.setState({ company: event.target.value as string, companyId: company.id });
        }
    };

    handlecountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string }, () => {
            this.getCityListItems()
        });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string }, () => {
            this.getComplexList();
        });
    };

    handleComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let complex = this.state.complexList.find((complex) => { return complex.attributes.name === event.target.value });
        if (complex) {
            this.setState({ complex: event.target.value as string, complexId: complex.id });
        }
    };

    handleSubcriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ subscriptionEndsIn: event.target.value as string });
    };

    handleSalesManagerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let salesManager = this.state.salesManagerList.find((salesManager) => { return salesManager.full_name === event.target.value });
        if (salesManager) {
            this.setState({ salesManager: event.target.value as string, salesManagerId: String(salesManager.id) });
        }
    };

    handleAccountManagerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ accountManager: event.target.value as string });
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: event.target.value });
    };

    handlePasswordText = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ loginTextPassword: event.target.value });
    };

    handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value }, () => {
            this.getTotalComplexList("1")
        });
    };

    handlePassWordShow = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    onHandleLoginModal = () => {
        this.setState({ loginModal: true, anchorEl: null });
    };

    handleLoginModal = () => {
        this.setState({ loginModal: false });
    };

    handlecloseLoginModal = () => {
        this.setState({ loginModal: false });
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getTotalComplexList(String(value));
    };

    handleNavigation = (path: string) => {
        const msgData = new Message(getName(MessageEnum.NavigationMessageSendData));
        msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
        msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgData);
    };

    handleErrorAlert = () => {
        this.setState({ showError: false });
    };

    handleNullValues = (value: string | number | null) => {
        if (value === null) {
            return "-";
        } else {
            return value;
        }
    };

    handleCompanyNameValue = (value: CompanyName | null) => {
        if (value === null) {
            return "-"
        } else if (value.company_name === null) {
            return "-"
        } else {
            return value.company_name
        }
    };

    handleAccountManagerListChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ accountManagerAssign: event.target.value as string });
    };

    handleSalesManagerListChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ salesManagerAssign: event.target.value as string });
    };

    handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ noteText: event.target.value });
    };

    handleOpenReassignModal = (companyId: string | null) => {
        this.setState({anchorEl: null});
        this.getOwnerDetails(companyId);
        if(this.state.userRole === 'coo'){   
            this.getAccountList(configJSON.cooProfileAccountManagerListingEndpoint)
            this.getSalesList(configJSON.cooProfileSalesManagerListingEndpoint)
        }else {
            this.getAccountList(configJSON.salesProfileAccountManagerListingEndpoint)
        }
    };

    handleCloseReassignModal = () => {
        this.setState({ openReassignModal: false });
    };

    handleSuccessAlert = () => {
        this.setState({ showSuccess: false });
    };
    // Customizable Area End
}
