import React from "react";
// Customizable Area Start
import {
    Box,
    Breadcrumbs,
    Button,
    Paper,
    MenuItem,
    Select,
    Typography,
    FormControl,
    TextField,
    InputAdornment,
    IconButton,
    Divider,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Modal,
    Grid,
    InputLabel,
    TextareaAutosize,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { styled } from "@material-ui/styles";
import MoreIcon from "@material-ui/icons/MoreVert";
import MainScreen from "./Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
import { TFunction, I18nInterface, CompanyListSuccessResponseItem, TotalIndividualType, SalesManagerItem, AccountManagerItem, ComplexListItem, BuildingListItem, SalesManagerListItem, AccountManagerListItem } from './IndivisualBuildingController.web';
import MapDialog from "../../../components/src/MuiDialog/MapDialog.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import AlertError from "../../../components/src/AlertError.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";
import Loader from "../../../components/src/Loader.web";
import SuccessAlert from "../../../components/src/AlertSuccess.web";

// Customizable Area End

import IndivisualBuildingController, { Props, } from "./IndivisualBuildingController.web";

class IndivisualBuilding extends IndivisualBuildingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCooTotalIndividual = () => {
        const open = Boolean(this.state.openMenu);
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <TotalIndividual dir={languageCondition(language, "rtl", "ltr")}>
                <Box dir={languageCondition(language, "rtl", "ltr")}>
                    <Breadcrumbs aria-label="breadcrumb" dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="navigateLink" dir={languageCondition(language, "rtl", "ltr")} onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"myDashboardTitleTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateLink" dir={languageCondition(language, "rtl", "ltr")} onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"generalDashboardTitleTestId"}>{t("General Dashboard")}</Typography>
                        <Typography className="tabActive" data-test={"indivisualBuidlingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("Total Individual Buildings")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="companiesText" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Individual Buildings")}</Typography>
                <Box className="headingContainer" dir={languageCondition(language, "rtl", "ltr")}>
                    <FormControl variant="outlined">
                        <SelectData
                            labelId="complexLabel"
                            id="demo-simple-select-outlined"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            displayEmpty
                            data-testId={"cooCompanySelectTestId"}
                            onChange={this.handleCompanyChange}
                            value={this.state.company}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Company")}</MenuItem>
                            {this.state.companyList.map((company: CompanyListSuccessResponseItem, companyIndex: number) => {
                                return <MenuItem value={company.attributes.name} key={companyIndex} data-testId={`companyListItem${companyIndex}`}>{company.attributes.name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            labelId="complexLabel"
                            id="demo-simple-select-outlined"
                            label={t("Complex")}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            displayEmpty
                            data-testId={"cooComplexSelectTestId"}
                            onChange={this.handleComplexChange}
                            value={this.state.complex}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Complex")}</MenuItem>
                            {this.state.complexList.map((complex: ComplexListItem, complexIndex: number) => {
                                return <MenuItem value={complex.attributes.name} key={complexIndex} data-testId={`complexItem${complexIndex}`}>{complex.attributes.name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            labelId="complexLabel"
                            id="demo-simple-select-outlined"
                            label={t("Complex")}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            displayEmpty
                            data-testId={"cooSalesManagerSelectTestId"}
                            onChange={this.handleSalesManagerChange}
                            value={this.state.salesManager}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Sales Manager")}</MenuItem>
                            {this.state.salesManagerList.map((salesManager: SalesManagerItem, salesManagerIndex: number) => {
                                return <MenuItem value={salesManager.full_name} key={salesManagerIndex} data-testId={`salesManagerItem${salesManagerIndex}`}>{salesManager.full_name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            label={t("Complex")}
                            displayEmpty
                            data-testId={"cooSubscriptionSelectTestId"}
                            onChange={this.handleSubscriptionChange}
                            value={this.state.subscriptionEndsIn}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")} value="">{t("Subscription ends in")}</MenuItem>
                            <MenuItem value={"30"}>{t("Next 30 days")}</MenuItem>
                            <MenuItem value={"60"}>{t("Next 60 days")}</MenuItem>
                            <MenuItem value={"90"}>{t("Next 90 days")}</MenuItem>
                            <MenuItem value={"180"}>{t("Next 6 months")}</MenuItem>
                            <MenuItem value={"365"}>{t("Next 1 year")}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <FormControl className={"complexSelect"} variant="outlined">
                        <SelectData
                            label={t("Complex")}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={ExpandMoreIcon}
                            displayEmpty
                            data-testId={"cooCountrySelectTestId"}
                            onChange={this.handleCountryChange}
                            value={this.state.country}
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")}  value="">{t("Country")}</MenuItem>
                            {this.state.countryList.map((country: string, countryIndex: number) => {
                                return <MenuItem value={country} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl className={"complexSelect"}  variant="outlined">
                        <SelectData
                            onChange={this.handleCityChange}
                            label={"Complex"}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"cooCitySelectTestId"}
                            displayEmpty
                            value={this.state.city}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return <MenuItem value={city} key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <Button
                        className="searchButton"
                        variant="contained"
                        startIcon={<SearchIcon />}
                        color="primary"
                        data-testId={"searchButtonTestId"}
                        onClick={() => this.getIndividualBuildingListing("1")}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Paper className="tableHeaderContainer">
                    <Box className="listHeader">
                        <Typography className="listPara">
                            {t("Total assigned individual building")}
                        </Typography>
                        <SearchField
                            onChange={this.handleSearchCompanyChange}
                            placeholder={t("Search by building name")}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="buildingSearchIcon" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow className="tableHeading">
                                        <TableCell className="tableHeading">#</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Building")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Account Manager")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("No. of floors")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscriptions Ends in")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Units")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Country")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("City")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.individualBuildingList.map((totalIndividualItem: TotalIndividualType, totalIndividaulIndex: number) => (
                                        <TableRow key={totalIndividaulIndex}>
                                            <TableCell>{totalIndividaulIndex + 1}</TableCell>
                                            <TableCell component="th" scope="row" data-testId={`buildingTableName${totalIndividaulIndex}`}>{totalIndividualItem.attributes.building_name}</TableCell>
                                            <TableCell>{totalIndividualItem.attributes.account_manager}</TableCell>
                                            <TableCell>{totalIndividualItem.attributes.no_of_floor}</TableCell>
                                            <TableCell>{totalIndividualItem.attributes.subscription_ends}</TableCell>
                                            <TableCell>{totalIndividualItem.attributes.totle_unit}</TableCell>
                                            <TableCell>{totalIndividualItem.attributes.country}</TableCell>
                                            <TableCell>{totalIndividualItem.attributes.city}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={this.handleOpenMenu} data-testId={`cooTableMenuBtn${totalIndividaulIndex}`}>
                                                    <MoreIcon />
                                                </IconButton>
                                                <Paper>
                                                    <Popover
                                                        open={open}
                                                        anchorEl={this.state.openMenu}
                                                        data-testId={"cooTablePopOverTestId"}
                                                        onClose={this.handleCloseMenu}
                                                        anchorOrigin={{
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                width: "20ch",
                                                            },
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        }}
                                                    >
                                                        <MenuItem dir={languageCondition(language, "rtl", "ltr")}>{t("View location on map")}</MenuItem>
                                                        <MenuItem dir={languageCondition(language, "rtl", "ltr")} data-testId={`reassignButton${totalIndividaulIndex}`} onClick={()=>this.handleOpenReassignModal(totalIndividualItem.id)}>{t("Reassign")}</MenuItem>
                                                    </Popover>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <PaginationData>
                                <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData?.current_page} data-testId={"cooPagination"}/>
                            </PaginationData>
                        </TableContainer>
                    </Box>
                </Paper>
            </TotalIndividual>
        );
    };

    renderSalesManagedIndividual = () => {
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        const open = Boolean(this.state.openMenu);
        return (
            <ManagedIndividual>
                <Box>
                    <Breadcrumbs>
                        <Typography className="navigateRoute" dir={languageCondition(language, "rtl", "ltr")} onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"myDashboardTitleTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateRoute" dir={languageCondition(language, "rtl", "ltr")} onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"generalDashboardTitleTestId"}>{t("General Dashboard")}</Typography>
                        <Typography className="tabActive" data-testId={"salesManagerHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("Managed Individual Building")}</Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="realEstateHeading">
                    {t("Managed individual building")}
                </Typography>
                <Box className="managedContainer">
                    <FormControl variant="outlined">
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={ExpandMoreIcon}
                            displayEmpty
                            onChange={this.handleCountryChange}
                            value={this.state.country}
                            label={t("Country")}
                            data-testId={"salesManagerCountrySelectTestId"}
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")}  value="">{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((country: string, countryIndex: number) => {
                                return <MenuItem value={country} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{country}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl className={"complexSelect"} variant="outlined">
                        <SelectData
                            label={t("Select City")}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            value={this.state.city}
                            displayEmpty
                            data-testId={"salesManagerCitySelectTestId"}
                            onChange={this.handleCityChange}
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")} value="">{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return <MenuItem value={city} key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl className={"complexSelect"} variant="outlined">
                        <SelectData
                            label={t("Select Building")}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            onChange={this.handleBuildingChange}
                            data-testId={"salesManagerBuildingSelectTestId"}
                            displayEmpty
                            value={this.state.building}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Building")}</MenuItem>
                            {this.state.buildingList.map((building: BuildingListItem, buildingIndex: number) => {
                                return <MenuItem value={building.name} key={buildingIndex} data-testId={`buidingListItem${buildingIndex}`}>{building.name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            labelId="complexLabel"
                            id="demo-simple-select-outlined"
                            label={t("Select Account Manager")}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            displayEmpty
                            data-testId={"salesAccountManagerSelectTestId"}
                            onChange={this.handleAccountManagerChange}
                            value={this.state.accountManager}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Select Account Manager")}</MenuItem>
                            {this.state.accountManagerList.map((accountManager: AccountManagerItem, accountManagerIndex: number) => {
                                return <MenuItem value={accountManager.full_name} key={accountManagerIndex} data-testId={`accountManagerItem${accountManagerIndex}`}>{accountManager.full_name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            IconComponent={ExpandMoreIcon}
                            data-testId={"complexSelectTestId"}
                            label={t("Subscription end's in")}
                            onChange={this.handleSubscriptionChange}
                            value={this.state.subscriptionEndsIn}
                            displayEmpty
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")} value="" >{t("Subscription end's in")}</MenuItem>
                            <MenuItem value='30'>{t("Next 30 days")}</MenuItem>
                            <MenuItem value='60'>{t("Next 60 days")}</MenuItem>
                            <MenuItem value='90'>{t("Next 90 days")}</MenuItem>
                            <MenuItem value='180'>{t("Next 6 months")}</MenuItem>
                            <MenuItem value='365'>{t("Next 1 year")}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <Button
                        variant="contained"
                        className="searchBtn"
                        startIcon={<SearchIcon />}
                        color="primary"
                        data-testId={"searchButtonTestId"}
                        onClick={() => this.getIndividualBuildingListing("1")}
                    >
                        {t("Search")}
                    </Button>
                </Box>
                <Paper>
                    <Box className="listHeading">
                        <Typography className="listPara">
                            {t("Total assigned individual building")}
                        </Typography>
                        <SearchTextField
                            variant="outlined"
                            placeholder="Search by building name"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="buildingSearchIcon" />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleSearchBuildingChange}
                            value={this.state.searchBuildingText}
                            data-testId={"salesSearchInputTestId"}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("#")}</TableCell>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("Building")}</TableCell>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("Account Manager")}</TableCell>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("No. of floors")}</TableCell>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscriptions Ends in")}</TableCell>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Units")}</TableCell>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("Country")}</TableCell>
                                        <TableCell className="tableHeader" dir={languageCondition(language, "rtl", "ltr")}>{t("City")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.individualBuildingList.map((totalIndividaul: TotalIndividualType, totalIndividaulIndex: number) => (
                                        <TableRow>
                                            <TableCell>{totalIndividaulIndex + 1}</TableCell>
                                            <TableCell component="th" data-testId={`buildingTableName${totalIndividaulIndex}`}>
                                                {totalIndividaul.attributes.building_name}
                                            </TableCell>
                                            <TableCell>{totalIndividaul.attributes.account_manager}</TableCell>
                                            <TableCell>{totalIndividaul.attributes.no_of_floor}</TableCell>
                                            <TableCell>{totalIndividaul.attributes.subscription_ends}</TableCell>
                                            <TableCell>{totalIndividaul.attributes.totle_unit}</TableCell>
                                            <TableCell>{totalIndividaul.attributes.country}</TableCell>
                                            <TableCell>{totalIndividaul.attributes.city}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={this.handleOpenMenu} data-testId={`cooTableMenuBtn${totalIndividaulIndex}`}>
                                                    <MoreIcon />
                                                </IconButton>
                                                <Paper>
                                                    <Popover
                                                        anchorEl={this.state.openMenu}
                                                        open={open}
                                                        onClose={this.handleCloseMenu}
                                                        data-testId={"cooTablePopOverTestId"}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                width: "20ch",
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem dir={languageCondition(language, "rtl", "ltr")}>{t("View location on map")}</MenuItem>
                                                        <MenuItem dir={languageCondition(language, "rtl", "ltr")} onClick={()=>this.handleOpenReassignModal(totalIndividaul.id)} data-testId={`reassignButton${totalIndividaulIndex}`}>{t("Reassign")}</MenuItem>
                                                    </Popover>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <FilterData>
                            <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData?.current_page} data-testId={"salesManagerPaginationTestId"}/>
                            </FilterData>
                        </TableContainer>
                    </Box>
                </Paper>
            </ManagedIndividual>
        );
    };

    renderAccountManagingData = () => {
        const openPopover = Boolean(this.state.openPopOver);
        const { t, i18n }: { t: TFunction, i18n: I18nInterface } = this.props;
        const language = i18n.language;
        return (
            <TeamManagingWrapper dir={languageCondition(language, "rtl", "ltr")}>
                <Box dir={languageCondition(language, "rtl", "ltr")}>
                    <Breadcrumbs aria-label="breadcrumb" dir={languageCondition(language, "rtl", "ltr")}>
                        <Typography className="navigateData" dir={languageCondition(language, "rtl", "ltr")} onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"myDashboardTitleTestId"}>{t("My Dashboard")}</Typography>
                        <Typography className="navigateData" dir={languageCondition(language, "rtl", "ltr")} onClick={()=>this.handleRedirection("DashboardCompanyEmployee")} data-testId={"generalDashboardTitleTestId"}>{t("General Dashboard")}</Typography>
                        <Typography className="tabActive" data-testId={"accountManagerHeadingTestId"} dir={languageCondition(language, "rtl", "ltr")}>
                            {t("Managed individual building")}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Typography className="companiesText" dir={languageCondition(language, "rtl", "ltr")}>
                    {t("Managed individual building")}
                </Typography>
                <Box className="managedContainer">
                    <FormControl variant="outlined">
                        <SelectData
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            data-testId={"accountBuildingSelectTestID"}
                            value={this.state.building}
                            onChange={this.handleBuildingChange}
                            displayEmpty
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")} value="">{t("Select Building")}</MenuItem>
                            {this.state.buildingList.map((building: BuildingListItem,buildingIndex: number)=>{
                                return <MenuItem value={building.name} key={buildingIndex} data-testId={`buidingListItem${buildingIndex}`}>{building.name}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl className={"complexSelect"}  variant="outlined">
                        <SelectData
                            label={"Select Country"}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            displayEmpty
                            data-testId={"accountCountrySelectTestId"}
                            onChange={this.handleCountryChange}
                            value={this.state.country}
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")} value="">{t("Select Country")}</MenuItem>
                            {this.state.countryList.map((countryItem: string, countryIndex: number) => {
                                return <MenuItem value={countryItem} key={countryIndex} data-testId={`countryListItem${countryIndex}`}>{countryItem}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            label={t("Select City")}
                            onChange={this.handleCityChange}
                            IconComponent={ExpandMoreIcon}
                            data-testId={"accountCitySelectTestId"}
                            value={this.state.city}
                            displayEmpty
                        >
                            <MenuItem dir={languageCondition(language, "rtl", "ltr")}  value="">{t("Select City")}</MenuItem>
                            {this.state.cityList.map((city: string, cityIndex: number) => {
                                return <MenuItem value={city} key={cityIndex} data-testId={`cityListItem${cityIndex}`}>{city}</MenuItem>
                            })}
                        </SelectData>
                    </FormControl>
                    <FormControl variant="outlined" className={"complexSelect"}>
                        <SelectData
                            MenuProps={{
                                style: {
                                    marginTop: "50px",
                                },
                            }}
                            IconComponent={ExpandMoreIcon}
                            label={t("Complex")}
                            displayEmpty
                            data-testId={"accountSubscriptionSelectTestId"}
                            onChange={this.handleSubscriptionChange}
                            value={this.state.subscriptionEndsIn}
                        >
                            <MenuItem value="" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscription end's in")}</MenuItem>
                            <MenuItem value="30">{t("Next 30 days")}</MenuItem>
                            <MenuItem value="60">{t("Next 60 dpays")}</MenuItem>
                            <MenuItem value="90">{t("Next 90 days")}</MenuItem>
                            <MenuItem value="180">{t("Next 6 months")}</MenuItem>
                            <MenuItem value="365">{t("Next 1 year")}</MenuItem>
                        </SelectData>
                    </FormControl>
                    <Box className="realEstateButton">
                        <Button
                            variant="contained"
                            className="searchButton"
                            color="primary"
                            startIcon={<SearchIcon />}
                            onClick={() => this.getIndividualBuildingListing("1")}
                            data-testId={"searchButtonTestId"}
                        >
                            {t("Search")}
                        </Button>
                    </Box>
                </Box>
                <Paper className="realEstateHeading">
                    <Box className="listHeader">
                        <Typography className="listPara">
                            {t("List of managed real estate companies")}
                        </Typography>
                        <SearchField
                            variant="outlined"
                            placeholder={t("Search by company name")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="buildingSearchIcon" />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleSearchCompanyChange}
                            value={this.state.searchCompanyText}
                            data-testId={"accountSearchInputTestId"}
                        />
                    </Box>
                    <Divider />
                    <Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow className="tableHeading">
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("#")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Building")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Account Manager")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("No. of floors")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Subscriptions Ends in")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Total Units")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("Country")}</TableCell>
                                        <TableCell className="tableHeading" dir={languageCondition(language, "rtl", "ltr")}>{t("City")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.individualBuildingList.map((arrayData: TotalIndividualType, arrayDataIndex: number) => (
                                        <TableRow>
                                            <TableCell>{arrayDataIndex + 1}</TableCell>
                                            <TableCell component="th" scope="row" data-testId={`buildingTableName${arrayDataIndex}`}>{arrayData.attributes.building_name}</TableCell>
                                            <TableCell>{arrayData.attributes.account_manager}</TableCell>
                                            <TableCell>{arrayData.attributes.no_of_floor}</TableCell>
                                            <TableCell>{arrayData.attributes.subscription_ends}</TableCell>
                                            <TableCell>{arrayData.attributes.totle_unit}</TableCell>
                                            <TableCell>{arrayData.attributes.country}</TableCell>
                                            <TableCell>{arrayData.attributes.city}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={this.handleOpenPopover} data-testId={`accountTableMenuBtnTestId${arrayDataIndex}`}>
                                                    <MoreIcon />
                                                </IconButton>
                                                <Paper>
                                                    <Popover
                                                        anchorEl={this.state.openPopOver}
                                                        open={openPopover}
                                                        onClose={this.handleClosePopover}
                                                        data-testId={"popoverTestId"}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                width: "20ch",
                                                            },
                                                        }}

                                                    >
                                                        <MenuItem data-testId={"popoverMen1DataTestId"} dir={languageCondition(language, "rtl", "ltr")}>{t("View location on map")}</MenuItem>
                                                    </Popover>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Box className="paginationData">
                                <PaginationModule pagination={this.state.paginationData} handlePagination={this.handlePagination} page={this.state.paginationData} data-testId={"paginationTestId"}/>
                            </Box>
                        </TableContainer>
                    </Box>
                </Paper>
            </TeamManagingWrapper>
        );
    };

    renderReassignFormMain = () => {
        const { t }: { t: TFunction } = this.props;
        return <Box>
            <Modal open={this.state.openReassignModal} onClose={this.handleCloseReassignModal} data-testId={"reassignModal"}>
                <IndiVidualBuildingReassignForm>
                    <Paper elevation={2} className="modalContainer">
                    <Typography align="left" className="reassignModalHeading">{t("Assign to another Manager")}</Typography>
                    <Divider />
                    <Box className="reassignForm">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="modalMainLabel">{t("Building ID")}</InputLabel>
                            <TextField fullWidth variant="outlined" value={this.state.modalBuildingId} disabled className="modalMainInputField"/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="modalMainLabel">{t("Building Name")}</InputLabel>
                            <TextField fullWidth variant="outlined" value={this.state.modalBuildingId} disabled className="modalMainInputField"/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="modalMainLabel">{t("Sales Manager")}</InputLabel>
                            <TextField fullWidth variant="outlined" value={this.state.modalBuildingId} disabled className="modalMainInputField"/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                        <InputLabel className="modalMainLabel">{t("Current Account Manager")}</InputLabel>
                                <TextField fullWidth variant="outlined" value={this.state.modalBuildingId} disabled className="modalMainInputField" />
                            </Grid>
                            {
                                    this.state.userRole === "coo" &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <InputLabel className="modalMainLabel" id="demo-simple-select-filled">{t("New Sales Manager")}</InputLabel>
                                        <FormControl fullWidth variant="outlined" className="selectAccountManager">
                                            <Select
                                                value={this.state.salesManagerAssign}
                                                onChange={this.handleSalesManager}
                                                IconComponent={ExpandMoreIcon}
                                                data-testId={"reassignSalesManagerChangeTestId"}
                                            >
                                                <MenuItem value="">{t("Select option")}</MenuItem>
                                                {this.state.newSalesManagerList.map((sales: SalesManagerListItem,salesIndex: number)=>{
                                                    return <MenuItem value={sales.full_name} key={salesIndex}>{t(sales.full_name)}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <InputLabel className="modalMainLabel">{t("New Account Manager")}</InputLabel>
                                <FormControl fullWidth variant="outlined" className="selectAccountManager">
                                    <Select
                                        onChange={this.handleAccountManager}
                                            value={this.state.accountManagerAssign}
                                            data-testId={"reassignAccountManagerChangeTestId"}
                                            IconComponent={ExpandMoreIcon}
                                        >
                                            <MenuItem value="">{t("Select option")}</MenuItem>
                                            {this.state.newAccountManagerList.map((account: AccountManagerListItem, accountIndex: number) => {
                                                return <MenuItem value={account.first_name} key={accountIndex}>{t(account.first_name)}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel className="modalMainLabel">{t("Notes")}</InputLabel>
                                <TextareaAutosize onChange={this.handleNote} minRows={5} value={this.state.noteText}  placeholder="Notes" className="noteInput"  data-testId={"noteInputTestId"}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box className="buttonContainer">
                                    <Button onClick={this.handleCloseReassignModal} className="cancelBtn" variant="outlined" data-testId={"reassignCancelBtnTestId"}>{t("Cancel")}</Button>
                                    <Button className="reassignBtn" variant="contained" data-testId={"reassignBtnTestID"} onClick={this.reassignToAnotherAccountManager}>{t("Reassign")}</Button>
                                </Box>
                            </Grid>
                    </Grid>
                    </Box>
                    </Paper>
                </IndiVidualBuildingReassignForm>
            </Modal>
        </Box>
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainScreen>
                {this.handleUserType('coo') && this.renderCooTotalIndividual()}
                {this.handleUserType('sales_manager') && this.renderSalesManagedIndividual()}
                {this.handleUserType('account_manager') && this.renderAccountManagingData()}
                {this.renderReassignFormMain()}
                <AlertError show={this.state.showError} handleClose={this.handleAlertError} message={this.state.errorMessage} data-testId={"errorAlertTestId"}/>
                <Loader loading={this.state.loader}/>
                <MapDialog
                    t={this.props.t}
                    IsOpen={this.state.openBuildingModal}
                    CloseDialog={this.handleCloseViewBuilding}
                    lat={this.state.latitude}
                    long={this.state.longitude}
                    data-testId={"viewBuildingModalTestId"}
                />
                <SuccessAlert show={this.state.showSuccess} message={this.state.showSuccessMessage} handleClose={this.handleSuccessAlert} data-testId={"successAlertTestId"}/>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(IndivisualBuilding)
export { IndivisualBuilding }
const SelectData = styled(Select)({
    background: "white",
    marginTop: "15px",
    border: "1px solid #f0f0f3",
    borderRadius: "10px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "40px",
        border: "1px solid #f0f0f3",
        borderRadius: "10px",
    },
});

const TotalIndividual = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .tabActive": {
        fontSize: "18px",
        color: "#2b6fed"
    },
    "& .searchButton": {
        borderRadius: "10px",
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: "20px",
        height: "50px",
        marginLeft: "15px",
        marginTop: "15px",
        backgroundColor: "#2B6FED"
    },
    "& .headingContainer": {
        display: "flex",
        flexWrap: "wrap",
        gap: "15px"
    },
    "& .listHeader": {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .tableHeaderContainer": {
        borderRadius: "10px"
    },
    "& .buildingSearchIcon": {
        color: "#808080"
    },
    "& .navigateLink": {
        fontWeight: 400,
        cursor: "pointer",
        fontSize: "16px",
        color: "black",
    },
    "& .companiesText": {
        lineHeight: "27px",
        fontSize: "28px",
        fontWeight: 700,
        marginBottom: "25px",
        marginTop: "25px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontSize: "22px",
        fontWeight: 700,
    },
    "& .tableHeading": {
        color: "black",
        fontSize: "18px",
        fontWeight: 700,
    },
    "& .statusField": {
        fontWeight: 700,
        borderRadius: "20px",
        color: "#40CE74",
        backgroundColor: "#E4F8EB",
        textTransform: "capitalize",
    },
    "& .errorMsg": {
        color: "#FC924B",
        fontWeight: 700,
        backgroundColor: "#FEF0E6",
        borderRadius: "20px",
        textTransform: "capitalize",
    },
    "& .spanContent": {
        fontWeight: 700
    },
    "& .filterContent": {
        color: "fc8e45",
        fontWeight: 700
    },
    
});

const PaginationData = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff"
    },
});

const SearchField = styled(TextField)({
    width: "260px",
    border: "1px solid #f0f0f3",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
        padding: "14.5px 0px",
    },
    "& .MuiInputAdornment-positionStart": {
        marginRight: "3",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
    }
});

const ManagedIndividual = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .tabActive": {
        fontSize: "18px",
        color: "#2b6fed"
    },
    "& .searchBtn": {
        fontWeight: 700,
        textTransform: "capitalize",
        height: "50px",
        fontSize: "20px",
        borderRadius: "10px",
        marginTop: "20px",
    },
    "& .managedContainer": {
        display: "flex",
        flexWrap: "wrap",
        gap: "15px"
    },
    "& .buildingSearchIcon": {
        color: "#808080"
    },
    "& .listHeading": {
        display: "flex",
        alignItems: "center",
        padding: "15px",
        justifyContent: "space-between",
        marginBottom: "5px",
        marginTop: "15px",
    },
    "& .navigateRoute": {
        fontWeight: 400,
        fontSize: "16px",
        color: "black",
    },
    "& .realEstateHeading": {
        fontSize: "28px",
        marginTop: "15px",
        lineHeight: "27px",
        fontWeight: 700,
        marginBottom: "15px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontSize: "22px",
        fontWeight: 700,
    },
    "& .tableHeader": {
        fontWeight: 700,
        fontSize: "18px",
        color: "black",
    },
    "& .successMsg": {
        backgroundColor: "#E4F8EB",
        fontWeight: 700,
        borderRadius: "20px",
        color: "#40CE74",
        textTransform: "capitalize",
    },
    "& .errorMsg": {
        backgroundColor: "#FEF0E6",
        fontWeight: 700,
        textTransform: "capitalize",
        borderRadius: "20px",
        color: "#FC924B",
    },
    "& .spanContent": {
        fontWeight: 700
    },
    "& .filterContent": {
        fontWeight: 700,
        color: "fc8e45",
    },
    
});

const FilterData = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
    "& .MuiPaginationItem-page.Mui-selected": {
        background: "#fc8e45",
        color: "#fff"
    },
});

const SearchTextField = styled(TextField)({
    width: "260px",
    "& .MuiOutlinedInput-input": {
        padding: "14.5px 0px",
    },
    "& .MuiInputAdornment-positionStart": {
        marginRight: "3",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "15px"
    }
});

const TeamManagingWrapper = styled(Box)({
    backgroundColor: "#F4F7FF",
    padding: "15px 25px",
    "& .tabActive": {
        fontSize: "18px",
        color: "#2b6fed"
    },
    "& .searchButton": {
        marginTop: "20px",
        textTransform: "capitalize",
        borderRadius: "10px",
        fontWeight: 700,
        fontSize: "20px",
        backgroundColor: "#2B6FED"
    },
    "& .listHeader": {
        display: "flex",
        padding: "15px",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "15px",
        marginBottom: "5px",
    },
    "& .managedContainer": {
        gap: "15px",
        display: "flex",
        flexWrap: "wrap",
    },
    "& .buildingSearchIcon": {
        color: "#808080"
    },
    "& .navigateData": {
        color: "black",
        fontWeight: 400,
        fontSize: "16px",
    },
    "& .companiesText": {
        lineHeight: "27px",
        fontWeight: 700,
        fontSize: "28px",
        marginBottom: "15px",
        marginTop: "15px",
    },
    "& .listPara": {
        lineHeight: "27px",
        fontWeight: 700,
        fontSize: "22px",
    },
    "& .tableHeading": {
        color: "black",
        fontWeight: 700,
        fontSize: "18px",
    },
    "& .approvedMsg": {
        backgroundColor: "#E4F8EB",
        fontWeight: 700,
        color: "#40CE74",
        borderRadius: "20px",
        textTransform: "capitalize",
    },
    "& .pendingMsg": {
        color: "#FC924B",
        backgroundColor: "#FEF0E6",
        fontWeight: 700,
        borderRadius: "20px",
        textTransform: "capitalize",
    },
    "& .paginationData": {
        justifyContent: "space-between",
        margin: "20px",
        display: "flex",
    },
    "& .realEstateHeading": {
        borderRadius: "10px"
    },
    "& .filterContent": {
        color: "fc8e45",
        fontWeight: 700
    },
    "& .spanContent": {
        fontWeight: 700
    },
    "& .realEstateButton": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "min-content"
    }
});

const IndiVidualBuildingReassignForm = styled(Box)({
    alignItems: "center",
    display: "flex",
    height:"100vh",
    width:"50%",
    borderRadius:"20px",
    margin:"auto",
    "& .reassignModalHeading":{
        fontWeight:900,
        fontSize:"25px",
        margin:"20px 0px 20px 20px",
        textAlign:"left",
    },
    "& .MuiDivider-root":{
        width:"100%"
    },
    "& .reassignForm":{
        padding:"20px"
    },
    "& .modalMainInputField":{
        border:"1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor:"#f0f0f0",
        borderRadius:"8px",
    },
    "& .noteInput":{
        padding:"10px",
        width:"100%",
        border:"1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor:"#f0f0f0",
        borderRadius:"10px",
    },
    "& .selectAccountManager":{
        borderRadius:"10px",
        backgroundColor:"#f0f0f0",
        border:"1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .modalMainLabel":{
        color:"black",
        marginBottom:"10px",
    },
    "& .modalContainer":{
        overflow:"scroll",
        borderRadius:"20px",
        height:"95%",
    },
    "& .buttonContainer":{
        gap:"20px",
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"center",
    },
    "& .reassignBtn": {
        textTransform: "capitalize",
        borderRadius: "7px",
        padding:"9px 0px",
        backgroundColor: "#2B6FED",
        fontSize: "18px",
        color: "#fff",
        width:"150px",
        fontWeight: 500,
    },
    "& .cancelBtn": {
        borderRadius: "7px",
        border:"1px solid #2B6FED",
        fontSize: "18px",
        textTransform: "capitalize",
        padding:"9px 0px",
        color: "#2B6FED",
        width:"150px",
        fontWeight: 500,
    },
});
// Customizable Area End