import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    t: (value: string) => string;
    i18n: I18nInterface;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    accountSaleValue: string;
    propertyManagerList: Array<PropertyManagerListItem>;
    anchorEl: HTMLButtonElement | null;
    country: string;
    city: string;
    propertyManagerId: string;
    sortBy: string;
    searchText: string;
    propertyManagerPage: number;
    paginationData: PaginationDataInterface;
    page: string;
    countryList: Array<string>;
    cityList: Array<string>;
    showError: boolean;
    errorMessage: string;
    // Customizable Area End
}
// Customizable Area Start
export interface PropertyManagerListItem {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        managed_units: number;
    }
}

export type TFunction = (value: string) => string;

export interface I18nInterface {
    language: string;
}

export interface PaginationDataInterface {
    current_page: number | null;
    next_page: number | null;
    prev_page: number | null;
    total_pages: number | null;
    total_count: number | null;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
    contract: string
};

export interface CountryListResponse {
    data: {
        countries: Array<string>
    }
}

export interface CityListResponse {
    data: {
        cities: Array<string>;
    }
}

export interface PropertyManagerListResponse {
    data: Array<PropertyManagerListItem>;
    meta: {
        pagination: PaginationDataInterface | null
    }
}
// Customizable Area End
interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class PropertyManagerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryApiCallId: string = "";
    cityApiCallId: string = "";
    propertyManagerApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            country: "",
            city: "",
            propertyManagerId: "",
            sortBy: "",
            searchText: "",
            propertyManagerPage: 0,
            propertyManagerList: [],
            anchorEl: null,
            accountSaleValue: "",
            paginationData: {
                current_page: 0,
                next_page: 0,
                prev_page: 0,
                total_pages: 0,
                total_count: 0
            },
            page: "",
            countryList: [],
            cityList: [],
            showError: false,
            errorMessage: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if ((responseJson && !responseJson.errors) || responseJson.contracts) {
                this.propertyManagerApiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.propertyManagerApiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getCountryList();
        this.getPropertyManagerList("1")
    };

    propertyManagerApiCall = async (apiPayloadData: APIPayloadType) => {
        let { method, endPoint, body, type = "", contentType } = apiPayloadData;
        let token = localStorage.getItem("loginSuccessToken")
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let contractDetailsrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
    };

    propertyManagerApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: CountryListResponse & CityListResponse & PropertyManagerListResponse) => {
        if (apiRequestCallId === this.countryApiCallId) {
            this.countryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityApiCallId) {
            this.cityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.propertyManagerApiCallId) {
            this.propertyManagerSucessCallBack(responseJson);
        }
    };

    propertyManagerApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
        if (apiRequestCallId === this.countryApiCallId) {
            this.countryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityApiCallId) {
            this.cityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.propertyManagerApiCallId) {
            this.propertyManagerFailureCallBack(responseJson);
        }
    };

    getCountryList = async () => {
        this.countryApiCallId = await this.propertyManagerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.countryEndPoint
        });
    };

    countryListSucessCallBack = (response: CountryListResponse) => {
        this.setState({ countryList: response.data.countries });
    };

    countryListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getCityList = async () => {
        this.cityApiCallId = await this.propertyManagerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.cityEndpoint}${this.state.country}`
        });
    };

    cityListSucessCallBack = (response: CityListResponse) => {
        this.setState({ cityList: response.data.cities });
    };

    cityListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    getPropertyManagerList = async (page: string) => {
        let endPoint = `${configJSON.propertyManagerListEndpoint}?page=${page}`;
        let { country, city, propertyManagerId, searchText, sortBy } = this.state;
        if (country) {
            endPoint = endPoint + `&country=${country}`
        }
        if (city) {
            endPoint = endPoint + `&city=${city}`
        }
        if (propertyManagerId) {
            endPoint = endPoint + `&p_id=${propertyManagerId}`
        }
        if (searchText) {
            endPoint = endPoint + `&search_term=${searchText}`
        }
        if (sortBy) {
            endPoint = endPoint + `&sort_by=${sortBy}`
        }

        this.propertyManagerApiCallId = await this.propertyManagerApiCall({
            method: configJSON.dashboarApiMethodType,
            endPoint: endPoint
        });
    };

    propertyManagerSucessCallBack = (response: PropertyManagerListResponse) => {
        if (response.meta.pagination !== null) {
            this.setState({ propertyManagerList: response.data, paginationData: response.meta.pagination });
        } else {
            this.setState({
                propertyManagerList: [],
                paginationData: {
                    current_page: 0,
                    next_page: 0,
                    prev_page: 0,
                    total_pages: 0,
                    total_count: 0
                },
                showError: false,
                errorMessage: "No Data Found"
            });
        }
    };

    propertyManagerFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({ showError: true, errorMessage: response.errors[0].contract });
    };

    handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ country: event.target.value as string }, () => {
            this.getCityList();
        });
    };

    handleCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ city: event.target.value as string });
    };

    handlePropertyManagerIdChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ propertyManagerId: event.target.value as string });
    };

    handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ sortBy: event.target.value as string });
    };

    handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchText: event.target.value },()=>{
            this.getPropertyManagerList("1");
        });
    };

    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        this.getPropertyManagerList(String(value));
    };

    handleAlertClose = () => {
        this.setState({ showError: false });
    };
    // Customizable Area End
}
