// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "framework/src/Utilities";
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation:typeof navigation,
  t:(value:string)=> string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  country: string,
  city: string,
  unitNumber:string;
  complex: string,
  selectComplex: string,
  building: string,
  unit: string,
  owner:string;
  countryList: Array<string>;
  cityList: Array<string>;
  hasSuccessError: HasSuccessErrorType;
  findPersonModal: boolean;
  addNewPersonModal: boolean;
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  selectedMarker: Array<DataItem>;
  buildingList: Array<SingleBuilding>;
  unitNumberList: Array<AllUnitNumberListDatainterface>;
  findNewPersonUserType:string;
  showError:boolean;
  error:string;
  showSuccessMessage: boolean;
  successMessage: string;
  selectComplexList:Array<GetSelectComplexListResponseItem>;
  ownerTenantActiveContract:GetOwnerTenantActiveContractSuccessResponse;
  findPersonIdNumber:string;
  findPersonEmail:string;
  findPersonPassport:string;
  findPersonPhoneNumber:string;
  findPersonList:Array<GetFindPersonListItem>;
  selectedPersonDetails:GetFindPersonListItem;
  openFindShowPersonModal:boolean;
  showAddNewPersonButton:boolean;
  societyMagementId: string;
  buildingManagementId: string;
  unitId: string;
  findPersonRoleId:string;
  handleFindPersonUserTypeChange:string;
  findNewPersonMemberName:string;
  findNewPersonEmail:string;
  countryCode:string;
  findNewPersonPhoneNumber:string;
  userTypeList:Array<UserTypeInterface>;
  unitType: string;
  createContractPayload: CreateContractPayloadDataItem;
  openBuildingModal: boolean;
  latitude: LatitudeLongitudeInterface;
  longitude: LatitudeLongitudeInterface;
  showOwnerDetails: boolean;
  showContractDetails: boolean;
  // Customizable Area End
}

// Customizable Area Start

export type LatitudeLongitudeInterface = null | string | number | undefined
export interface CreateContractPayloadDataItem {
  templateId: string;
  country: string;
  buildingName: string;
  city: string;
  complex: string;
  landlord_name: string;
  unitName: string;
  complexName: string;
  tenant_name: TenantNameInterface;
  agreement_duration: string;
  tenant_id: string;
  society_management_id: string;
  apartment_management_id: string;
  building_management_id: string;
  start_date: string;
  custom_contract: boolean;
  lease_template_id: string;
  term_ids: Array<number>;
  currency: string;
  rent_amount: string;
  ownerEmail: string | null;
  condition_ids: Array<number>;
  contract_template: string;
  penanlty_late_payment: boolean;
  owner: string | null;
  expires_on: string;
  ownerNumber: string | null;
  accountId: Account_id;
  status: string;
  contractLink: string;
};
type TenantNameInterface = string | number | null


export interface GetFindPersonListNoData {
  message: string;
};

export interface UserTypeInterface {
  id: string;
  type: string;
  attributes: {
      name: string;
      name_ar: string;
      created_at: string;
      updated_at: string;
      types: string;
      dashboard_id: null;
      team_type: null;
      dashboard_name: null
  }
};

export interface GetUserTypeResponse {
  data:Array<UserTypeInterface>;
};

export interface DataItem {
  id: number;
  name: string;
}
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
}
export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export type TFunction = (value: string) => string;

export interface ApiFailureResponseError {
  contract: string
}
export interface GetAllCountrySuccessResponse {
  data: {
      countries: Array<string>
  }
}

export interface GetAllCitySuccessResponse {
  data: {
      cities: Array<string>
  }
}

export interface GetBuildingSuccessResponse {
  data: {
      buildings: Array<SingleBuilding>;
  };
}
interface Unit {
  id: number;
  unit: string;
}

export interface SingleBuilding {
  id: number;
  name: string;
  society_management_id: number;
  description: string | null;
  created_at: string;
  updated_at: string;
  per_floor_unit: number;
  generation_methods: string;
  number_of_floor: number;
  building_area: string;
  account_id: number | null;
  lat: number | null;
  long: number | null;
  city: string | null;
  country: string | null;
  googlemaplocation: string | null;
  real_estate_company_id: number | null;
}

interface SharedArea {
  id: number;
  name: string;
  // Add other properties as needed
}
interface SocietyManagement {
  id: number;
  name: string;
  description: string;
  total_floor: number;
  total_unit: number;
}

interface Logo {
  url: string;
  content_type: string;
  file_name: string;
}
interface Photo {
  url: string;
  content_type: string;
  file_name: string;
}

interface SocietyAttributesData {
  name: string;
  description: string;
  complex_area: string;
  measurement_unit: string | null;
  maintenance_per_square_feet: number | null;
  is_building: boolean;
  total_floor: number | null;
  total_unit: number | null;
  currency_id: number | null;
  account_manager_id: number | null;
  language: string | null;
  logo: SocietyPhotoData;
  photos: SocietyPhotoData[];
  subscription_end_in: string | null;
  subscription_id: string | null;
  is_building_list: boolean | null;
  building_list: Array<string>;
  shared_area: Array<string>;
  documents: Array<string>;
  building_documents: Array<string>;
  incident_categories: Record<string, number>;
  total_buildings: number;
  active_users: number | null;
  inactive_users: number | null;
  pending_ownership_requests: number;
  collected_management_fee: number | null;
  status: string;
  company_name: string;
  rented: number;
  total_polls: number;
  last_date_of_polls: string | null;
  total_surveys: number;
  last_date_of_surveys: string | null;
  building_ownership_rate: number | null;
  ownership_onboarded: {
    ownership_percent: string;
    ownership_count: number;
  };
  tenant_resquests: {
    tenant_percent: number;
    tenant_count: number;
  };
  total_units: Array<string>;
  account_manager: Array<string>;
  sales_manager: Array<string>;
  country: string;
  city: string;
  lat: number;
  long: number;
  streetname: string | null;
  zipcode: string;
  landmark: string;
  locationlink: string;
  complex_lat: number | null;
  complex_long: number | null;
  currency: string | null;
  subscription_type: string | null;
  plan_id: string | null;
  paying_month: string | null;
  googlemaplocation: string | null;
  jurisdiction: JurisdictionDataItem;
}

interface SocietyPhotoData {
  url: string;
  content_type: string;
  file_name: string;
}

interface JurisdictionDataItem {
  data: {
    id: string;
    type: string;
    attributes: JurisdictionAttributesData;
  };
}
interface JurisdictionAttributesData {
  id: number;
  jurisdiction_id: number;
  jurisdiction_name: string;
  currency: string;
  country: string | null;
  timezone: string;
  unit_of_measurement: string;
  mobile_number_length: string;
  formula: string;
  value_added_tax: string | null;
  zone_multiplier: number | null;
  complex: AllComplexListItemData[];
  total_complex: number;
  total_buildings: number;
  total_units: number;
  contracts: Array<string>;
}

interface AllComplexListItemData {
  id: number;
  dataId:string;
  name: string;
  created_at: string;
  description: string;
  updated_at: string;
  complex_area: string;
  measurement_unit: string | null;
  maintenance_per_square_feet: number | null;
  contigency: number | null;
  ticket_days_configuration: number;
  late_charge_percentage: string | null;
  zone_multiplier: number | null;
  longitude: number | null;
  latitude: number | null;
  value_added_tax: string | null;
  real_estate_company_id: number | null;
  is_building: boolean;
  total_unit: number | null;
  total_floor: number | null;
  account_manager_id: number | null;
  language: string | null;
  currency_id: number | null;
}

export interface UnitNumberListData {
  data: {
    apartments: Array<AllUnitNumberListDatainterface>
  }
}
export interface AllUnitNumberListDatainterface {
  id: number,
  society_management_id: number,
  building_management_id: number,
  created_at: string,
  updated_at: string,
  apartment_name: string,
  floor_number: number,
  size: null,
  purchase_price: string,
  configuration: null,
  purchase_date: null,
  unit_type: string,
  status: string,
  current_valuation: string,
  monthly_renting_income: string,
  account_id: null,
  owner_name: null,
  resident_name: null
}

export interface GetSelectComplexListResponseItem {
  type: string | null;
  id: string;
  attributes: {
    description: string;
    complex_area: string;
    measurement_unit: null;
    maintenance_per_square_feet: null;
    is_building: boolean;
    total_floor: null;
    total_unit: null;
    language: null;
    currency_id: null;
    status: null;
    account_manager_id: null;
    name: string;
    logo: {
      url: string;
      file_name: string
      content_type: string;
    };
    photos: [
      {
        url: string;
        file_name: string
        content_type: string;
      }
    ];
    subscription_id: null;
    subscription_end_in: null;
    building_list: [];
    is_building_list: null;
    shared_area: [
      {
        id: number;
        society_management_id: number;
        created_at: string;
        reservation_fee: number;
        updated_at: string;
        details: string;
        currency_id: null;
        total_area: string;
        name: string;
      }
    ];
    building_documents: [];
    documents: [];
    incident_categories: {
      Carpenter: number;
      Electrician: number
      Plumbing: number;
    };
    active_users: null;
    inactive_users: null;
    pending_ownership_requests: number;
    collected_management_fee: null;
    total_buildings: number;
    rented: number;
    total_polls: number;
    last_date_of_polls: null;
    building_ownership_rate: null;
    total_surveys: number;
    last_date_of_surveys: null;
    company_name: null;
    ownership_onboarded: {
      ownership_percent: string;
      ownership_count: number
    };
    tenant_resquests: {
      tenant_count: number;
      tenant_percent: number;
    };
    total_units: [];
    sales_manager: null;
    country: string;
    city: string;
    lat: null;
    account_manager: null;
    long: null;
    streetname: string;
    zipcode: string;
    complex_long: null;
    landmark: string;
    locationlink: null;
    complex_lat: null;
    currency: {
      id: number;
      currency: string;
      created_at: string;
      updated_at: string;
      currency_ar: string
    };
    googlemaplocation: null;
    subscription_type: null;
    plan_id: null;
    paying_month: null;
    jurisdiction: {
      data: {
        type: string;
        id: string;
        attributes: {
          jurisdiction_id: string;
          jurisdiction_name: string;
          currency: string;
          country: string;
          timezone: string;
          unit_of_measurement: string;
          mobile_number_length: string;
          id: number;
          zone_multiplier: number;
          value_added_tax: number;
          formula: string;
          complex: [
            {
              name: string;
              description: string;
              created_at: string;
              updated_at: string;
              complex_area: string;
              maintenance_per_square_feet: null;
              measurement_unit: null;
              ticket_days_configuration: number;
              contigency: null;
              zone_multiplier: null;
              status: null;
              late_charge_percentage: null;
              latitude: null;
              longitude: null;
              value_added_tax: null;
              is_building: boolean;
              id: number;
              real_estate_company_id: null;
              total_floor: null;
              total_unit: null;
              currency_id: null;
              account_manager_id: null;
              language: null;
            }
          ];
          contracts: [];
          total_complex: number;
          total_buildings: number;
          total_units: number;
        }
      }
    }
  }
};

export interface GetSelectComplexListResponse {
  data: Array<GetSelectComplexListResponseItem>;
};

export interface GetOwnerTenantActiveContractSuccessResponse {
  data: {
    id: string,
    type: string,
    attributes: {
      id: number,
      owner_details: {
        owner_name: null | string,
        owner_number: null | string,
        email: null | string,
        owner_id : null | string;
      },
      tenant_details: Array<TenantDetailsData>,
      contract_details: {
        id: number,
        apartment_management_id: number,
        account_id: number,
        building_management_id: number,
        society_management_id: number,
        landlord_name: null | string,
        country: null | string,
        region: null | string,
        city: null | string,
        agreement_duration: null | string,
        start_date: null | string,
        rent_amount: null | string,
        expires_on: null | string,
        state: null | string,
        tenant_name: null | string,
        duration: null | string,
        lease_template_id: null | string,
        status: string,
        contract_type: null | string,
        custom_term_condition: null | string,
        penanlty_late_payment: null | string,
        tenant_id: number,
        contract_template: null | string,
        custom_contract: boolean,
        created_at: string,
        updated_at: string,
        currency: string,
        employee_account_id: null | string,
        owner_name: null | string
      }
    }
  }
};

export interface TenantDetailsData{
  id: number,
  tenant_id: number,
  account_id: number,
  apartment_management_id: number,
  building_management_id: number,
  society_management_id: number,
  landlord_name: LandlordName,
  country: Country,
  region: Region,
  city: City,
  agreement_duration: AgreementDuration
  start_date: StartDate,
  rent_amount: RentAmount,
  expires_on: ExpiresOn,
  state: State,
  tenant_name: TenantName,
  duration: Duration,
  lease_template_id: LeaseTemplateId,
  status: Status,
  contract_type: ContractType,
  custom_term_condition: CustomTermCondition,
  penanlty_late_payment: PenanltyLatePayment,
  contract_template: ContractTemplate,
  custom_contract: boolean,
  created_at: string,
  updated_at: string,
  currency: string,
  employee_account_id: EmployeeAccountId,
  owner_name: OwnerName,
}

type Account_id = string | number | null;
type LandlordName = null | string | number;
type Country = null | string | number;
type Region = null | string | number;
type City = null | string | number;
type AgreementDuration = null | string | number;
type StartDate = null | string | number;
type RentAmount = null | string | number;
type ExpiresOn = null | string | number;
type State = null | string | number;
type TenantName = null | string | number;
type Duration = null | string | number;
type LeaseTemplateId = null | string | number;
type Status = string;
type ContractType = null | string | number;
type CustomTermCondition = null | string | number;
type PenanltyLatePayment = null | string | number;
type ContractTemplate = null | string | number;
type EmployeeAccountId = null | string | number;
type OwnerName = null | string | number;

export interface GetFindPersonListResponse {
  data: Array<GetFindPersonListItem>
}
export interface GetFindPersonListItem {
  type: string,
  id: string,
  attributes: {
    email: string,
    activated: boolean,
    full_name: string,
    type: string,
    company_name: null,
    full_phone_number: string,
    owner_full_name: null,
    owner_email: null,
    manager_full_name: null,
    first_name: null,
    created_at: string,
    updated_at: string,
    disable_chat: boolean,
    gender: null,
    owner_phone_number: null,
    last_name: null,
    add_name: string;
    profile_pic: null;
    address: {
      address: string
    }
  }
}

export type TenantNameType = string | number | null;

// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewLeaseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryApiCallId: string = "";
  cityListApiCallId: string = "";
  countryCodeAndFlagApiCallId: string = "";
  buildingListApiCallId: string = "";
  unitListApiCallId: string = "";
  complexListBasedOnComplexApiCallId: string = "";
  ownerListApiCallId: string = "";
  findPersonListApiCallId: string = "";
  sendInvitationApiCallId: string = "";
  userTypeApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      country: "",
      city: "",
      complex: "",
      selectComplex: "",
      building: "",
      unit: "",
      owner: "",
      unitNumber: "",
      countryList: [],
      cityList: [],
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      findPersonModal: false,
      addNewPersonModal: false,
      anchorEl: null,
      selectedId: null,
      selectedMarker: [
        { id: 1, name: "Marker 1" },
        { id: 2, name: "Marker 2" },

      ],
      buildingList: [],
      unitNumberList: [],
      findNewPersonUserType:"",
      showError:false,
      error:"",
      showSuccessMessage: false,
      successMessage: "",
      selectComplexList:[],
      ownerTenantActiveContract:{
        data: {
            id: "",
            type: "",
            attributes: {
                id: 0,
              owner_details: {
                owner_name: "",
                owner_number: "",
                email: "",
                owner_id: null
              },
              tenant_details: [],
              contract_details: {
                    id: 0,
                    apartment_management_id: 0,
                    account_id: 0,
                    building_management_id: 0,
                    society_management_id: 0,
                    landlord_name: null,
                    country: null,
                    region: null,
                    city: null,
                    agreement_duration: null,
                    start_date: null,
                    rent_amount: null,
                    expires_on: null,
                    state: null,
                    tenant_name: null,
                    duration: null,
                    lease_template_id: null,
                    status: "",
                    contract_type: null,
                    custom_term_condition: null,
                    penanlty_late_payment: null,
                    tenant_id: 0,
                    contract_template: null,
                    custom_contract: false,
                    created_at: "",
                    updated_at: "",
                    currency: "",
                    employee_account_id: null,
                    owner_name: null
                }
            }
        }
    },
      findPersonIdNumber: "",
      findPersonEmail: "",
      findPersonPassport: "",
      findPersonPhoneNumber: "",
      findPersonList:[],
      societyMagementId: "",
      buildingManagementId: "",
      unitId: "",
      findPersonRoleId:"",
      selectedPersonDetails: {
        "id": "",
        "type": "",
        "attributes": {
          "activated": true,
          "email": "",
          "full_phone_number": "",
          "full_name": "",
          "type": "",
          "company_name": null,
          "manager_full_name": null,
          "owner_full_name": null,
          "owner_email": null,
          "owner_phone_number": null,
          "created_at": "",
          "updated_at": "",
          "disable_chat": false,
          "gender": null,
          "first_name": null,
          "last_name": null,
          "add_name": " ",
          "profile_pic": null,
          address: {
             address: ""
          }
        }
      },
      showAddNewPersonButton: false,
      openFindShowPersonModal: false,
      handleFindPersonUserTypeChange:"",
      findNewPersonMemberName:"",
      findNewPersonEmail:"",
      countryCode:"",
      findNewPersonPhoneNumber:"",
      userTypeList:[],
      unitType: "",
      createContractPayload: {
        country: "",
        templateId: "",
        complex: "",
        city: "",
        unitName: "",
        buildingName: "",
        tenant_name: "",
        complexName: "",
        building_management_id: "",
        landlord_name: "",
        tenant_id: "",
        society_management_id: "",
        apartment_management_id: "",
        expires_on: "",
        agreement_duration: "",
        start_date: "",
        lease_template_id: "",
        term_ids: [],
        rent_amount: "",
        currency: "",
        condition_ids: [],
        custom_contract: false,
        contract_template: "",
        penanlty_late_payment: false,
        owner: "",
        ownerNumber: "",
        accountId: "",
        ownerEmail: "",
        status: "create",
        contractLink: ""
      },
      openBuildingModal: false,
      latitude: null,
      longitude: null,
      showOwnerDetails: false,
      showContractDetails: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    this.getCountryList();
    this.getUserTypeList();
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    let token = localStorage.getItem("loginSuccessToken")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson);
      }
    }else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractData = message.getData(getName(MessageEnum.CreateContractPayloadData));
       if(createContractData){
        this.setState({createContractPayload:createContractData},()=>{  
          this.fillData();
        })
       }
    }
  }

  fillData = () => {
    let contractData = this.state.createContractPayload;
        if (contractData.tenant_name !== "") {
      let tenantDetails = {
        id: Number(contractData.tenant_id),
        tenant_id: Number(contractData.tenant_id),
        account_id: 0,
        apartment_management_id: 0,
        building_management_id: 0,
        society_management_id: 0,
        landlord_name: null,
        country: null,
        region: null,
        city: null,
        agreement_duration: null,
        start_date: contractData.start_date,
        rent_amount: null,
        expires_on: "",
        state: null,
        tenant_name: contractData.tenant_name,
        duration: null,
        lease_template_id: null,
        status: "",
        contract_type: null,
        custom_term_condition: null,
        penanlty_late_payment: null,
        contract_template: null,
        custom_contract: false,
        created_at: "",
        updated_at: "",
        currency: "",
        employee_account_id: null,
        owner_name: null
      };
      this.state.ownerTenantActiveContract.data.attributes.tenant_details.push(tenantDetails);
      this.setState({ownerTenantActiveContract:this.state.ownerTenantActiveContract})
    }
    if (contractData.owner !== "") {
      let ownerDetails = {
        owner_name: contractData.owner,
        owner_number: contractData.ownerNumber,
        email: contractData.ownerEmail,
        owner_id: null
    };
      this.state.ownerTenantActiveContract.data.attributes.owner_details = ownerDetails;
      this.setState({ownerTenantActiveContract:this.state.ownerTenantActiveContract})
    }
    if (contractData.country !== "" && contractData.country !== null) {
      this.setState({ country: contractData.country }, () => {
        this.getCityList();
      });
    }
    if (contractData.city !== "" && contractData.city !== null) {
      this.setState({ city: contractData.city, complex: "Complex" }, () => {
        this.getDataBasedOnComplexSelection(false);
      });
    }
    if (contractData.complex !== "") {
      this.setState({ selectComplex: contractData.complex }, () => {
        this.getDataBasedOnComplexSelection(false);
      });
    }
    if (contractData.society_management_id !== "") {
      this.setState({ societyMagementId: contractData.society_management_id, building: contractData.buildingName }, () => {
        this.getBuildingList();
      });
    }
    if (contractData.building_management_id !== "") {
      this.setState({ buildingManagementId: contractData.building_management_id, unit: contractData.unitName }, () => {
        this.getUnitNumberList();
      });
    }
   
  };

  responseSucessCell = async (
    apiRequestCallId: string,
    responseJson: GetAllCountrySuccessResponse &
      GetAllCitySuccessResponse  & 
      GetBuildingSuccessResponse &
      UnitNumberListData &
      GetOwnerTenantActiveContractSuccessResponse &
      GetSelectComplexListResponse &
      GetFindPersonListResponse &
      GetUserTypeResponse & GetFindPersonListNoData) => {

    if (apiRequestCallId === this.countryApiCallId) {
      this.getCountryListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.getCityListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.getBuildingListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.unitListApiCallId) {
      this.getUnitNumberListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListBasedOnComplexApiCallId) {
      this.getSelectComplexSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.ownerListApiCallId) {
      this.ownerListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.findPersonListApiCallId) {
      this.findPersonListSucessCallBack(responseJson);
    }
    if(apiRequestCallId === this.userTypeApiCallId) {
      this.userTypeListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.sendInvitationApiCallId) {
      this.sendInvitationSucessCallBack(responseJson);
    }

  };

  responseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.countryApiCallId) {
      this.getCountryListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.getCityListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.getBuildingListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.unitListApiCallId) {
      this.getUnitListNumberFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListBasedOnComplexApiCallId) {
      this.getSelectComplexFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.ownerListApiCallId) {
      this.ownerListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.findPersonListApiCallId) {
      this.findPersonListFailureCallBack(responseJson);
    }
    if(apiRequestCallId === this.userTypeApiCallId) {
      this.userTypeListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.sendInvitationApiCallId) {
      this.sendInvitationFailureCallBack(responseJson);
    }
  };

  getCountryList = async () => {
    this.countryApiCallId = await this.apiCall({
      method: configJSON.countryListMethod,
      endPoint: configJSON.countryEndPoint,
    });
  };

  getCountryListSucessCallBack = (response: GetAllCountrySuccessResponse) => {    
    this.setState({ 
      countryList: response.data.countries,
      cityList: [],
      city: "",
      complex: "",
      selectComplex: "",
      selectComplexList: [],
      building: "",
      buildingList: [],
      unit: "",
      unitNumberList: []
     })
  };

  getCountryListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  getCityList = async () => {
    this.cityListApiCallId = await this.apiCall({
      method: configJSON.getCityListMethod,
      endPoint: `${configJSON.cityEndPoint}${this.state.country}`,
    });
  };

  getCityListSucessCallBack = (response: GetAllCitySuccessResponse) => {
    this.setState({ 
      cityList: response.data.cities,
      city: "",
      complex: "",
      selectComplex: "",
      selectComplexList: [],
      building: "",
      buildingList: [],
      unit: "",
      unitNumberList: []
    })
  };

  getCityListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };


  getBuildingList = async () => {    
    this.buildingListApiCallId = await this.apiCall({
      method: configJSON.buildingListMethod,
      endPoint: `${configJSON.buildingListEndPoint}${this.state.societyMagementId}`,
    });
  };

  getBuildingListSucessCallBack = (response: GetBuildingSuccessResponse) => {    
    this.setState({
      building: "",
      buildingList: response.data.buildings,
      unit: "",
      unitNumberList: []
    });
  };

  getBuildingListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  getUnitNumberList = async () => {
    this.unitListApiCallId = await this.apiCall({
      method: configJSON.unitNumberListMethod,
      endPoint: `${configJSON.unitNumberListOwnerEndPoint}society_management_id=${this.state.societyMagementId}&building_management_id=${this.state.buildingManagementId}&unit_type=Rented`
    });
  };

  getUnitNumberListSucessCallBack = (response: UnitNumberListData) => {
    this.setState({ unitNumberList: response.data.apartments, unit: "" });
  };

  getUnitListNumberFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  getDataBasedOnComplexSelection = async (complex:boolean) => {
    this.complexListBasedOnComplexApiCallId = await this.apiCall({
      method: configJSON.complexSelectListMethod,
      endPoint: `${configJSON.complexSelectListEndPoint}?city=${this.state.city}&is_building=${complex}`,
    });
  };

  getSelectComplexSucessCallBack = (response: GetSelectComplexListResponse & GetFindPersonListNoData) => {    
    if(response.message){
      this.setState({ selectComplexList:[]});
    }else if (response.data){
      this.setState({
        selectComplexList: response.data,
        selectComplex: "",
        building: "",
        buildingList: [],
        unit: "",
        unitNumberList: []
      });
    }
  };

  getSelectComplexFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: false, error: response.errors[0].contract });
  };

  getOwnerList = async () => {
    this.setState({ showOwnerDetails: false, showContractDetails: false });
    this.ownerListApiCallId = await this.apiCall({
      method: configJSON.onwerListMethod,
      endPoint: `${configJSON.ownerTenantActiveContractEndpoint}${this.state.unitId}`,
    });
  };

  ownerListSucessCallBack = (response: GetOwnerTenantActiveContractSuccessResponse
  ) => {    
    this.state.createContractPayload.accountId=response.data.attributes.owner_details.owner_id
    if (this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_name) {
      this.setState({ showOwnerDetails: true });
    }
    if (typeof response.data.attributes.contract_details === "string") {
      this.setState({ ownerTenantActiveContract: response, createContractPayload: this.state.createContractPayload, showContractDetails: false });
    }
    if (typeof response.data.attributes.contract_details !== "string") {
      this.setState({ ownerTenantActiveContract: response, createContractPayload: this.state.createContractPayload, showContractDetails: true });
    }
    if (typeof response.data.attributes.owner_details === "string") {
      this.setState({ ownerTenantActiveContract: response, createContractPayload: this.state.createContractPayload, showOwnerDetails: false });
    }
    if (typeof response.data.attributes.owner_details !== "string") {
      this.setState({ ownerTenantActiveContract: response, createContractPayload: this.state.createContractPayload, showOwnerDetails: true });
    }
  };

  ownerListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  findPersonListApiCall = async () => {
    this.findPersonListApiCallId = await this.apiCall({
      method: configJSON.findPersonListMethod,
      endPoint: `${configJSON.findPersonListEndPoint}?id_number=${this.state.findPersonIdNumber}&phone_number=${this.state.findPersonPhoneNumber}&email=${this.state.findPersonEmail}&passport=${this.state.findPersonPassport}&role=Tenant`,
    });
  };

  findPersonListSucessCallBack = (response: GetFindPersonListResponse) => {    
    this.setState({ findPersonList: response.data, showAddNewPersonButton: false }, () => {
      if (this.state.findPersonList.length === 0) {
        this.setState({ showAddNewPersonButton: true })
      }
    })
  };

  findPersonListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  sendInvitation = async () => {
    if (!this.state.findNewPersonMemberName ||
      !this.state.findNewPersonEmail ||
      !this.state.buildingManagementId ||
      !this.state.unitId ||
      !this.state.societyMagementId ||
      !this.state.findPersonRoleId
    ) {
      return this.setState({ error: "All fields required", showError: true });
    } else {
      let formdata = new FormData();
      formdata.append("member_invitation[full_name]", this.state.findNewPersonMemberName);
      formdata.append("member_invitation[email_address]", this.state.findNewPersonEmail);
      formdata.append("member_invitation[phone_number]", this.state.findNewPersonPhoneNumber);
      formdata.append("member_invitation[role_id]", String(this.state.findPersonRoleId));
      formdata.append("member_invitation[building_management_id]", String(this.state.buildingManagementId));
      formdata.append("member_invitation[apartment_management_id]", String(this.state.unitId));
      formdata.append("member_invitation[society_management_id]", String(this.state.societyMagementId));
      this.sendInvitationApiCallId = await this.apiCall({
        method: configJSON.inviteMemberMethod,
        endPoint: configJSON.inviteMemberEndPoints,
        body: formdata,
        type: "formData"
      });
    }
  };

  sendInvitationSucessCallBack = (response: GetFindPersonListResponse) => {  
    this.setState({ showError: false, addNewPersonModal: false, showSuccessMessage: true,
      successMessage: "Invitation sent", })
  };

  sendInvitationFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  getUserTypeList = async () => {
    this.userTypeApiCallId = await this.apiCall({
      method: configJSON.complexSelectListMethod,
      endPoint: configJSON.userTypeEndpoint,
    });
  };

  userTypeListSucessCallBack = (response: GetUserTypeResponse) => {
    this.setState({ userTypeList: response.data });
  };

  userTypeListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };

  handleNavigateUser = () => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "LeaseManagement");
    this.send(msgData);
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, selectedId: number) => {
    this.setState({ anchorEl: event?.currentTarget, selectedId: selectedId });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };
  handleOpenFindPersonModal = () => {
    this.setState({ findPersonModal: true })
  }
  handleCloseFindPersonModal = () => {
    this.setState({ findPersonModal: false, findPersonList: [] })
  }
  handleAddNewPerson = () => {
    this.setState({ addNewPersonModal: true, findPersonModal: false, unitType: "invitation" })
  }

  handleCloseAddNewPersonModal = () => {
    this.setState({ addNewPersonModal: false })
  }

  handleIssueNewContract = async (tenantName: TenantNameType, tenantId: number, contractType: string) => {
    let createContractPayload = this.state.createContractPayload
    createContractPayload.tenant_name = tenantName;
    createContractPayload.tenant_id = String(tenantId);
    if (!this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_name) {
      createContractPayload.owner = "";
      createContractPayload.ownerNumber = "";
      createContractPayload.ownerEmail = "";
      createContractPayload.accountId = "";
      createContractPayload.status = contractType;
    } else if (this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_name) {
      createContractPayload.owner = this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_name;
      createContractPayload.ownerNumber = this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_number;
      createContractPayload.ownerEmail = this.state.ownerTenantActiveContract.data.attributes.owner_details.email;
      createContractPayload.landlord_name = this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_name;
      createContractPayload.accountId = this.state.ownerTenantActiveContract.data.attributes.owner_details.owner_id;
      createContractPayload.status = contractType;
      let tenant = this.state.ownerTenantActiveContract.data.attributes.tenant_details.find((tenant)=>{return tenant.id ===tenantId});
      if(tenant){
        createContractPayload.lease_template_id = tenant.id + "";
        createContractPayload.templateId = tenant.id + "";
        createContractPayload.contract_template = tenant.contract_template + "";
      }
      
    }
    this.setState({ createContractPayload: this.state.createContractPayload }, () => { 
      const msgData: Message = new Message(
        getName(MessageEnum.NavigationMessageSendData)
      );
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContract");
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayload);
      this.send(msgData);
    });
  }

  hanldeComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { createContractPayload } = this.state;
    createContractPayload.complex = event.target.value as string
    this.setState({
      complex: event.target.value as string,
      createContractPayload: this.state.createContractPayload,
      selectComplexList: [],
      selectComplex: "",
      building: "",
      buildingList: [],
      unit: "",
      unitNumberList: []
    }, () => {
      if (this.state.complex === "Complex") {
        this.getDataBasedOnComplexSelection(false);
      } else if (this.state.complex === "Individual Building") {
        this.getDataBasedOnComplexSelection(true);
      }
    });
  };


  hanldeCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { createContractPayload } = this.state
    createContractPayload.city = event.target.value as string
    this.setState({
      city: event.target.value as string,
      createContractPayload: this.state.createContractPayload,
      complex: "",
      selectComplexList: [],
      selectComplex: "",
      building: "",
      buildingList: [],
      unit: "",
      unitNumberList: []
    })
  };

  hanldeCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { createContractPayload } = this.state
    createContractPayload.country = event.target.value as string
    this.setState({
      country: event.target.value as string,
      createContractPayload: this.state.createContractPayload,
    }, () => {
      this.getCityList();
    });
  };

  hanldeSelectComplexChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { selectComplexList, createContractPayload } = this.state;
    const selectedComplex = event.target.value as string;
    const selectedComplexData = selectComplexList.find((complex) => { return complex.attributes.name === selectedComplex });

    if (selectedComplexData) {
      const societyManagementId = selectedComplexData.id;
      createContractPayload.complexName = event.target.value as string;
      createContractPayload.society_management_id = societyManagementId;
      this.setState({ 
        selectComplex: selectedComplex, 
        societyMagementId: societyManagementId, 
        createContractPayload: this.state.createContractPayload
      }, () => {
        this.getBuildingList();
      });
    }
  };

  hanldeBuildingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { buildingList, createContractPayload } = this.state;
    const selectedBuildingName = event.target.value as string;
    const selectedBuildingData = buildingList.find((item) => item.name === selectedBuildingName);

    if (selectedBuildingData) {
      const buildingManagementId = selectedBuildingData.id;
      createContractPayload.buildingName = event.target.value as string;
      createContractPayload.building_management_id = String(buildingManagementId);
      this.setState({
        building: selectedBuildingName,
        buildingManagementId: String(buildingManagementId),
      }, () => {
        this.getUnitNumberList();
      });
    }
  };


  hanldeUnitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { createContractPayload } = this.state
    if (this.state.unitType === "invitation") {
      let unitItem = this.state.unitNumberList.find((unit) => { return unit.apartment_name === event.target.value })
      if (unitItem) {
        createContractPayload.unitName = event.target.value as string;
        createContractPayload.apartment_management_id = String(unitItem.id);
        this.setState({ unit: event.target.value as string, unitId: String(unitItem.id) });
      }
    } else {
      let unitItem = this.state.unitNumberList.find((unit) => { return unit.apartment_name === event.target.value })
      if (unitItem) {
        createContractPayload.unitName = event.target.value as string;
        createContractPayload.apartment_management_id = String(unitItem.id);
        this.setState({ unit: event.target.value as string, unitId: String(unitItem.id) }, () => {
          this.getOwnerList();
        });
      }
    }
  };

handleFindPersonIdNumberChange = (event:React.ChangeEvent<{value:unknown}>) => {
  this.setState({findPersonIdNumber:event.target.value as string})
};

handleFindPersonEmailChange = (event:React.ChangeEvent<{value:unknown}>) => {
  this.setState({findPersonEmail:event.target.value as string})
};

handleFindPersonPassportChange = (event:React.ChangeEvent<{value:unknown}>) => {
  this.setState({findPersonPassport:event.target.value as string})
};

handleFindPersonPhoneNumberChange = (event:React.ChangeEvent<{value:unknown}>) => {
  this.setState({findPersonPhoneNumber:event.target.value as string})
};

handleFindPersonUserTypeChange = (event:React.ChangeEvent<{value:unknown}>) => {
  let userType = this.state.userTypeList.find((user) => { return user.attributes.name === event.target.value })
  if (userType) {
    this.setState({ findNewPersonUserType: event.target.value as string, findPersonRoleId: userType.id });
  }
};

handleFindPersonMemberNameChange = (event:React.ChangeEvent<HTMLInputElement>) => {
  this.setState({findNewPersonMemberName:event.target.value})
};

handleFindNewPersonEmailChange = (event:React.ChangeEvent<HTMLInputElement>) => {
  this.setState({findNewPersonEmail:event.target.value})
};

handleCountryCode = (event:React.ChangeEvent<HTMLInputElement>) => {
  this.setState({countryCode:event.target.value})
};

handleFindpersonPhoneNumber = (event:React.ChangeEvent<HTMLInputElement>) => {
  this.setState({findNewPersonPhoneNumber:event.target.value})
};

handleCloseAlertError = () => { this.setState({ showError: false, error: "" })};

handleNullValue=(value:string|number|boolean|null):string=>{
  if(value===null){
    return "-"
  }else{
    return String(value)
  }
};

hanldeViewPersonDetails = (person:GetFindPersonListItem) => {
  this.setState({selectedPersonDetails:person,selectedId:null,findPersonModal:false,openFindShowPersonModal:true})
};

handleClosePersonDetailsModal = () => {
  this.setState({openFindShowPersonModal:!this.state.openFindShowPersonModal,findPersonModal:true})
};

handleSelectFindPerson = () => {  
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const startDay = String(date.getDate()).padStart(2, '0');
  const {createContractPayload} = this.state;
  createContractPayload.tenant_id = this.state.selectedPersonDetails.id;
  createContractPayload.tenant_name = this.state.selectedPersonDetails.attributes.full_name;
  let tenantDetails = {
    id: Number(this.state.selectedPersonDetails.id),
    tenant_id: Number(this.state.selectedPersonDetails.id),
    account_id: 0,
    apartment_management_id: 0,
    building_management_id: 0,
    society_management_id: 0,
    landlord_name: null,
    country: null,
    region: null,
    city: null,
    agreement_duration: null,
    start_date: `${year}-${month}-${startDay}`,
    rent_amount: null,
    expires_on: "",
    state: null,
    tenant_name: this.state.selectedPersonDetails.attributes.full_name,
    duration: null,
    lease_template_id: null,
    status: "",
    contract_type: null,
    custom_term_condition: null,
    penanlty_late_payment: null,
    contract_template: null,
    custom_contract: false,
    created_at: "",
    updated_at: "",
    currency: "",
    employee_account_id: null,
    owner_name: null
  };
  this.state.ownerTenantActiveContract.data.attributes.tenant_details.push(tenantDetails)
  this.setState({
    selectedPersonDetails: this.state.selectedPersonDetails,
    findPersonModal: false,
    findPersonList: [],
    openFindShowPersonModal: false,
    ownerTenantActiveContract: this.state.ownerTenantActiveContract,
    createContractPayload: this.state.createContractPayload
  });  
};

  handleExistRecordNavigation = () => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
  );
  msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "LeaseManagment");
  this.send(msgData);
  };

  hanldeCloseSuccessAlert = () => {
    this.setState({ showSuccessMessage: false, successMessage: "" }, () => {
      this.handleNavigateUser();
    })
  };

  calculateDaysUntilExpiry = (targetDateString: number | string | null): boolean => {
    if (targetDateString === null) { return false }
    const targetDate: Date = new Date(targetDateString);
    const today: Date = new Date();
    targetDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    const timeDifference = targetDate.getTime() - today.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference <= 10 && daysDifference >= 0 || daysDifference < 0) {
      return true
    } else {
      return false
    }
  };

  handleBuildingModal = () => {
    this.setState({
      openBuildingModal: !this.state.openBuildingModal,
    });
  };

  handleCloseViewBuilding = () => {
    this.setState({ openBuildingModal: !this.state.openBuildingModal });
  };

  handleViewContract = async (contractId: string | number) => {
    await setStorageData("contractId",contractId);
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "ContractDetails");
    msgData.addData(getName(MessageEnum.ContractDetailsId), contractId);
    this.send(msgData);
  };

  isDateExpired = (targetDateString: string | number | null): boolean => {
    if (!targetDateString) {
      return false;
    }

    const targetDate = new Date(targetDateString);
    const today = new Date();

    targetDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return targetDate < today;
  };

  handleExpireContractLabel = (expiresOn: string | number | null) => {
    return this.isDateExpired(expiresOn) ? `Contract expired on ${expiresOn}` : `Contract expires on ${expiresOn}`
  };

  handleExpireDateLabel = (expiresOn: string | number | null) => {
    return this.isDateExpired(expiresOn) ? `Expired on` : `Expires On`
  };

  // Customizable Area End
}