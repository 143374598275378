// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  withStyles,
  TextField, InputLabel, styled, InputAdornment
} from "@material-ui/core";
import CEAwaitAcceptController, { Props } from "./CEAwaitAcceptController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CERequestManagementStyle } from "./CERequestManagement.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchIcon from "@material-ui/icons/Search";
import AwaitAcceptCard from "../../../../components/src/CommunityManagementCard/AwaitAcceptCard.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { DeleteIcon, buildingIcon, emailIcon, phoneIcon, userIcon } from "../assets";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { Formik,Field } from "formik";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { navigationFunc } from "../../../../components/src/helperFunctions.web";
// Customizable Area End

class CEAwaitAccept extends CEAwaitAcceptController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: Props = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation await-heading-box">
              <Typography variant="body1" className="await-breadcrumb-box">
                <span onClick={() => navigationFunc(this.props, "DashboardCompanyEmployee")}>{t("Community Management")}</span> /{" "}
                <span onClick={() => navigationFunc(this.props, "CERequestManagement")}>{t("Requests Management")}</span> /{" "}
                <Box component="span" style={{ color: "blue" }} className="await-head-text">
                  {t("Sent Invitations Awaiting Acceptance")}
                </Box>
              </Typography>
              <Box className="sub-heading-box await-heading-box">
                <Typography variant="h5" className="bold-text await-heading-text">
                  {t("Sent Invitations Awaiting Acceptance")}
                </Typography>
              </Box>
            </Box>

            <Box className="filter-head-box">
              {this.state.complexType === "Complex" && (
                <FilterSelect
                  t={t}
                  language={language}
                  label={t("Select Building")}
                  onChange={(e: any) => this.handleChangeValue(e, "selectedBuilding")}
                  value={this.state.filter.selectedBuilding}
                  option={[{ label: "All", value: "all" }, ...this.state.buildingsList]}
                />
              )} 
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Unit")}
                value={this.state.filter.selectedUnit}
                onChange={(e: any) => this.handleChangeValue(e, "selectedUnit")}
                option={[{ label: "All", value: "all" }, ...this.state.unitList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select User Type")}
                value={this.state.filter.selectedType}
                option={[{ label: "All", value: "all" }, ...this.state.userList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedType")}
              />
              <Button startIcon={<SearchIcon />} data-testId="search-filter-btn" className="await-search-filter-btn" onClick={this.getWaitingAcceptenceList}>
                {t("Search")}
              </Button>
            </Box>

            <Grid container spacing={2} style={{ margin: "15px 0 30px" }}>
            {this.state.waitingAcceptenceList.length === 0 && (
                <Grid item xs={12}>
                  <Box style={webStyle.noDataFound}>{t("No request found")}</Box>
                </Grid>
              )}
            {this.state.waitingAcceptenceList.length > 0 &&  this.state.waitingAcceptenceList.map((item:any, index:any) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <AwaitAcceptCard item={item} t={t} dateFormat={this.handleDateFormat} handleDialog={this.handleDialog} handleDelete={this.handleDelete}/>
              </Grid>
            ))}
            </Grid>
          </Container>
        </CEHeaderComponentWeb>

        <AlertDialog
          IsOpen={this.state.isDeleteDialogOpen}
          Image={DeleteIcon}
          CloseDialog={() => {this.handleDelete()}}
          Header={t("Delete Invitation Confirmation")}
          Content={`<p>${t("Are you sure you want to delete invitation request")} <br/> ${t(
            "sent to"
          )} <b>${"Name"}</b> ${t("for Unit")} <b>${"Unit"}</b></p>`}
          DeclineText={t("Close")}
          AcceptText={t("Yes, Delete")}
          DeclineFun={() => {this.handleDelete()}}
          AcceptFun={() => {this.handleAccept()}}
        />

        <DialogActionsCss
          dir={languageCondition(language, "rtl", "ltr")}
          open={this.state.isResendDialogOpen}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title="Resend Request" onClose={this.handleClose} />
          <Formik
            onSubmit={(values, { resetForm }) => {
              this.handleFormSubmit(values);
              resetForm();
            }}
            initialValues={this.state.formValue}
            enableReinitialize={true}
            validationSchema={this.meetingValidation}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <form onSubmit={handleSubmit} >
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          icon={userIcon}
                          language={language}
                          name="type"
                          label="User Type"
                          option={this.state.userList}
                          data-testid="user-type"
                          value={values.type}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.type, errors.type)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          icon={userIcon}
                          language={language}
                          value={values.fullName}
                          isLabel={true}
                          label="Member full name"
                          data-testid="member-full-name"
                          id="fullName"
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.fullName, errors.fullName)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          icon={emailIcon}
                          language={language}
                          value={values.emailID}
                          isLabel={true}
                          data-testid="email-address"
                          id="emailId"
                          label="Email Address"
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.emailID, errors.emailID)}
                      </Grid>
                      <Grid item md={6}>
                        <Box className="form__phone_numbers" display="flex" alignItems="center">
                          <PhoneInput
                            inputProps={{ name: "selectCode" }}
                            value={values.countryCode}
                            enableSearch={true}
                            country="us"
                            data-testid="country-code"
                            onChange={(value: any) => {
                              setFieldValue("countryCode", `+${value}`);
                            }}
                            disabled
                          /> 
                           <Box className="input_icon__form_box">
                          <InputLabel>{t("Phone Number")}</InputLabel>
                          <Field
                            as={TextField}
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            value={values.phoneNumber}
                            placeholder={t("Phone Number")}
                            className="input_box"
                            data-testid="phoneNumber-input"
                            onChange={handleChange}
                            disabled
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={phoneIcon} alt="icon" />
                                </InputAdornment>
                              )
                            }}
                          />
                        </Box>
                        </Box>
                        {ErrorHandler(t, touched.phoneNumber, errors.phoneNumber)}
                      </Grid>
                      <Grid item md={6}>
                         <NormalSelect
                            t={t}
                            label="Country"
                            value={values.country}
                            language={language}
                            name="country"
                            data-testid="select-country"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            option={[...this.state.countryList]}
                            disabled
                          />
                          {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          label="City"
                          key="city"
                          data-testid="select-city"
                          value={values.city}
                          language={language}
                          option={this.state.cityList}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.city, errors.city)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          id="selectedIndividualBuilding"
                          data-testid="individual-building"
                          label="Complex/Individual Buildings"
                          value={values.individualBuilding}
                          option={this.state.complexBuildingFilterList}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.individualBuilding, errors.individualBuilding)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          label="Complex"
                          data-testid="select-complex"
                          id="selectedComplex"
                          language={language}
                          icon={buildingIcon}
                          value={values.complex}
                          option={this.state.complexList}
                          onChange={handleChange}
                          disabled
                        />
                        {ErrorHandler(t, touched.complex, errors.complex)}
                      </Grid>
                      {
                        !this.state.hideField &&
                        <Grid item md={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            label="Building"
                            data-testid="select-building"
                            id="selectedBuilding"
                            icon={buildingIcon}
                            value={values.building}
                            option={this.state.buildingsList}
                            onChange={handleChange}
                            disabled
                          />
                          {ErrorHandler(t, touched.building, errors.building)}
                        </Grid>
                      }
                      {
                        !this.state.hideField &&
                        <Grid item md={6}>
                          <NormalSelect
                            t={t}
                            language={language}
                            icon={buildingIcon}
                            value={values.unit}
                            label="Unit"
                            data-testid="select-unit"
                            id="selectedUnit"
                            option={this.state.unitList}
                            onChange={handleChange}
                            disabled
                          />
                          {ErrorHandler(t, touched.unit, errors.unit)}
                        </Grid>
                      }
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button  data-testid="cancel-button" className="cancel-button" onClick={() => { this.handleClose() }}>
                      {t("Cancel")}
                    </Button>
                    <Button className="add-button" type="submit"  data-testid="add-button">
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogActionsCss>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  noDataFound: {
    display: "flex",
    alignItems: "center",
    marginTop:"5%",
    justifyContent: "center",
  }
}
export const DialogActionsCss = styled(Dialog)({
  '& .form__phone_numbers': {
    position: "relative",
    '& .react-tel-input': {
      left: 0,
      position: "absolute",
      zIndex: 9,
      width: "fit-content",
      top: "33px",
      borderRight: "1px solid #f0f0f0",
      '& .selected-flag': {
        background: "transparent"
      },
      '& .country-list': {
        position: "fixed",
      },
    },
    '& .input_icon__form_box .input_box': {
      paddingLeft: "120px",
      width: "68%",
    },
    '& .input_box': {
      background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
      padding: "10px 12px",
      borderRadius: "8px",
      fontSize: "16px",
      outline: "none",
      border: "none",
    },
    '& .input_box::placeholder': {
      textDecoration: "none !important",
      borderBottom: "none",
    }
  },
})

// @ts-ignore
export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CEAwaitAccept)));
// Customizable Area End
