// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation:typeof navigation
    t:(value:string)=> string
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    reviewDraftDetails: ContractDetailsSuccessResponse;
    successError: HasSuccessErrorType;
    showError:boolean;
    error:string;
    constractId: string;
    showSuccessMessage: boolean;
    createContractPayload: CreateContractPayload
    successMessage: string;
    termsId: Array<number>;
    conditionId: Array<number>;
    // Customizable Area End
}

// Customizable Area Start

interface CreateContractPayload {
  complex: string;
  templateId: string;
  building_management_id: string;
  country: string;
  city: string;
  unitName: string;
  buildingName: string;
  tenant_name: TenantNameInterface;
  complexName: string;
  society_management_id: string;
  landlord_name: string;
  tenant_id: string;
  expires_on: string;
  apartment_management_id: string;
  agreement_duration: string;
  start_date: string;
  term_ids: Array<number>;
  lease_template_id: string;
  condition_ids: Array<number>;
  currency: string;
  rent_amount: string;
  custom_contract: boolean;
  penanlty_late_payment: boolean;
  contract_template: string;
  owner: string | null;
  accountId: Account_id;
  contractLink: string;
}

type TenantNameInterface = null | number | string
type Account_id = null | number | string
export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
  };

  export interface ContractDetailsSuccessResponse {
    contract: {
      data: {
        id: string;
        type: string;
        attributes: {
          apartment_management_id: number | string;
          account_id: number | string;
          building_management_id: number | string;
          society_management_id: number | string;
          agreement_duration: string;
          region: string | null;
          country: string | null;
          landlord_name: string;
          start_date: string;
          city: string | null;
          currency: string;
          state: string | null;
          expires_on: string;
          rent_amount: number | string;
          duration: string | null;
          tenant_name: string;
          penanlty_late_payment: boolean;
          lease_template_id: number | string;
          owner: {
            first_name: string | null;
            phone_number: PhoneNumber
            id: number | string;
            email: string;
            last_name: string | null;
            device_id: string | null;
            country_code: string | null;
            created_at: string;
            activated: boolean;
            full_phone_number: string;
            unique_auth_id: string | null;
            updated_at: string;
            user_name: string | null;
            last_visit_at: string | null;
            user_type: string | null;
            suspend_until: string | null;
            app_language_id: AppLanguageId;
            platform: string | null;
            stripe_id: string | null;
            role_id: RoleId;
            status: string;
            is_blacklisted: boolean;
            stripe_subscription_id: string | null;
            stripe_subscription_date: string | null;
            full_name: string;
            date_of_birth: string | null;
            is_paid: boolean;
            gender: string | null;
            age: Age;
            owner_phone_number: string | null;
            company_name: string | null;
            manager_full_name: string | null;
            owner_full_name: string | null;
            owner_email: string | null;
            public_field: string | null;
            building_management_id: BuildingManagementId;
            disable_chat: boolean;
            language_type: string | null;
            ticket_days_configuration: number | string;
          };
          contract_template: string;
          custom_contract: boolean;
          created_at: string;
          updated_at: string;
          status: string;
          owner_name: string | null;
          
          
          lease_template_text: string;
          building_name: string;
          contract_type: string;
          expire_on: number | string;
          lease_template_pdf: {
            url: string;
          };
          contract_template_pdf: {
            url: string;
          };
          
          society_management: {
            name: string;
            created_at: string;
            updated_at: string;
            description: string;
            complex_area: string;
            ticket_days_configuration: number | string;
            maintenance_per_square_feet: number | string;
            measurement_unit: string;
            id: number | string;
            zone_multiplier: number | string;
            contigency: number | string;
            latitude: string | null;
            late_charge_percentage: string;
            real_estate_company_id: RealEstateCompanyId;
            value_added_tax: string;
            longitude: string | null;
            account_manager_id: AccountManagerId;
            is_building: boolean;
            total_unit: TotalUnit;
            total_floor: TotalFloor;
            language: string | null;
            status: string | null;
            currency_id: CurrencyId;
          };
          tenant: {
            id: number | string;
            first_name: string | null;
            last_name: string | null;
            full_phone_number: string;
            country_code: string | null;
            phone_number: PhoneNumber
            email: string;
            activated: boolean;
            device_id: string | null;
            unique_auth_id: string | null;
            user_name: string | null;
            created_at: string;
            user_type: string | null;
            updated_at: string;
            platform: string | null;
            app_language_id: AppLanguageId;
            suspend_until: string | null;
            last_visit_at: string | null;
            stripe_id: string | null;
            status: string;
            is_blacklisted: boolean;
            stripe_subscription_date: string | null;
            stripe_subscription_id: string | null;
            role_id: RoleId;
            full_name: string;
            age: Age;
            gender: string | null;
            company_name: string | null;
            date_of_birth: string | null;
            owner_full_name: string | null;
            is_paid: boolean;
            owner_phone_number: string | null;
            manager_full_name: string | null;
            disable_chat: boolean;
            owner_email: string | null;
            language_type: string | null;
            building_management_id: BuildingManagementId;
            public_field: string | null;
            ticket_days_configuration: number | string;
          };
          penalty_late_payments: {
            penanlty_type: string | null;
            id: number | string;
            penanlty_counted: string;
            tenant_id: number | string;
            account_id: number | string;
            amount: string;
          };
          terms: Array<TermsAndConditionItem> ;
          custom_term_condition: null;
          conditions: Array<TermsAndConditionItem> ;
          unit_name: string;
          custom_contract_image: string | null;
          custom_term_condition_added: boolean;
        };
      };
    };
    message: string;
    code: number;
  };


export interface TermsAndConditionItem {
  id: number;
  text: string;
}

  type PhoneNumber =  number | string | null;
  type AppLanguageId =  number | string | null;
  type RoleId =  number | string | null;
  type Age =  number | string | null;
  type BuildingManagementId =  number | string | null;
  type RealEstateCompanyId =  number | string | null;
  type AccountManagerId =  number | string | null;
  type IsBuilding =  boolean;
  type TotalUnit =  number | string | null;
  type TotalFloor =  number | string | null;
  type CurrencyId =  number | string | null;
  export interface ApiFailureResponseError {
    contract: string
  };
export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError> & Array<{ errors: string }>
};

export type ApiFailureResponseCombination = ApiFailureResponse & ApiFailureResponseError & ApiFailureErrorResponse & ApiFailureCallResponse

export interface GetCreateContractResponse {
  code: number,
  message: string,
  contract: {
    data: {
      type: string,
      id: string,
      attributes: {
        duration: null,
        accountId: null,
        apartment_management_id: number,
        building_management_id: number,
        society_management_id: number,
        landlord_name: string,
        country: null,
        region: null,
        city: null,
        agreement_duration: string,
        start_date: string,
        rent_amount: number,
        currency: string,
        expires_on: string,
        state: null,
        tenant_name: string,
        status: string,
        lease_template_id: number,
        contract_template: string,
        penanlty_late_payment: boolean,
        custom_contract: boolean,
        owner: null,
        created_at: string,
        updated_at: string,
        owner_name: null,
        contract_template_pdf: {
          url: string
        },
        lease_template_pdf: {
          url: string
        },
        contract_type: null,
        building_name: string,
        expire_on: number,
        lease_template_text: string,
        society_management: {
          name: string,
          id: number,
          created_at: string,
          description: string,
          maintenance_per_square_feet: number,
          updated_at: string,
          complex_area: string,
          contigency: number,
          measurement_unit: string,
          ticket_days_configuration: number,
          longitude: null,
          zone_multiplier: number,
          late_charge_percentage: string,
          latitude: null,
          total_unit: null,
          value_added_tax: string,
          is_building: boolean,
          real_estate_company_id: null,
          total_floor: null,
          status: null
          currency_id: null,
          account_manager_id: null,
          language: null,
        },
        unit_name: string,
        terms: [
          {
            created_at: string,
            id: number,
            text: string,
            text_ar: string
            updated_at: string,
          }
        ],
        conditions: [
          {
            created_at: string,
            text: string,
            id: number,
            text_ar: string
            updated_at: string,
          }
        ],
        custom_term_condition_added: boolean,
        custom_term_condition: null,
        tenant: {
          id: number,
          first_name: null,
          last_name: null,
          full_phone_number: string,
          activated: boolean,
          phone_number: null,
          email: string,
          updated_at: string,
          device_id: null,
          country_code: null,
          unique_auth_id: null,
          password_digest: string,
          created_at: string,
          platform: null,
          user_name: null,
          app_language_id: null,
          user_type: null,
          is_blacklisted: boolean,
          last_visit_at: null,
          status: string,
          suspend_until: null,
          full_name: string,
          stripe_id: null,
          stripe_subscription_id: null,
          stripe_subscription_date: null,
          role_id: null,
          is_paid: boolean,
          gender: string,
          date_of_birth: null,
          age: null,
          manager_full_name: null,
          company_name: null,
          owner_email: null,
          owner_full_name: null,
          public_field: null,
          owner_phone_number: null,
          building_management_id: null,
          disable_chat: boolean,
          language_type: null,
          ticket_days_configuration: number
        },
        custom_contract_image: null
        penanlty_late_payments: null,
      }
    }
  },
};

export interface ApiFailureErrorResponse {
  errors: string;
};

export interface ApiFailureResponseError {
  contract: string
};

export interface ApiFailureCallResponse {
  errors: Array<{ errros: string }>
}

  interface HasSuccessErrorType {
    isOpen: boolean,
    isSeverity: "success" | "info" | "warning" | "error",
    isMessage: string,
  };

export type TFunction = (value: string) => string;
// Customizable Area End

interface SS {
    id: string;
}
export default class LeaseManagementReviewDraftController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    reviewDraftDetailsApiCallId: string = "";
    createContractApiCallId: string = "";
    saveContractTemplateTestId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.DraftDetailsId),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            successError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
              },
              showError:false,
              error:"",
              constractId: "",
              showSuccessMessage: false,
              successMessage: "",
              termsId: [],
              conditionId: [],
          reviewDraftDetails: {
            "code": 200,
            "message": "",
            "contract": {
              "data": {
                "type": "contract",
                "id": "",
                "attributes": {
                  "account_id": 0,
                  "building_management_id": 0,
                  "apartment_management_id": 0,
                  "landlord_name": "",
                  "country": null,
                  "region": null,
                  "society_management_id": 0,
                  "agreement_duration": "",
                  "start_date": "",
                  "state": null,
                  "rent_amount": 0,
                  "currency": "",
                  "expires_on": "",
                  "status": "",
                  "tenant_name": "",
                  "city": null,
                  "duration": null,
                  "created_at": "",
                  "penanlty_late_payment": true,
                  "custom_contract": false,
                  "lease_template_id": 0,
                  "owner_name": null,
                  "updated_at": "",
                  "contract_template": "",
                  "owner": {
                    "first_name": null,
                    "last_name": null,
                    "id": 0,
                    "full_phone_number": "",
                    "email": "",
                    "phone_number": 0,
                    "device_id": null,
                    "country_code": null,
                    "unique_auth_id": null,
                    "created_at": "",
                    "updated_at": "",
                    "platform": null,
                    "activated": true,
                    "user_type": null,
                    "app_language_id": null,
                    "last_visit_at": null,
                    "is_blacklisted": false,
                    "user_name": null,
                    "suspend_until": null,
                    "stripe_id": null,
                    "stripe_subscription_id": null,
                    "stripe_subscription_date": null,
                    "status": "",
                    "full_name": "",
                    "gender": null,
                    "date_of_birth": null,
                    "age": null,
                    "company_name": null,
                    "owner_full_name": null,
                    "role_id": null,
                    "is_paid": false,
                    "manager_full_name": null,
                    "owner_phone_number": null,
                    "disable_chat": false,
                    "owner_email": null,
                    "public_field": null,
                    "language_type": null,
                    "ticket_days_configuration": 0,
                    "building_management_id": null
                  },
                  "contract_template_pdf": {
                    "url": ""
                  },
                  "lease_template_pdf": {
                    "url": ""
                  },
                  "contract_type": "",
                  "expire_on": 0,
                  "lease_template_text": "",
                  "building_name": "",
                  "society_management": {
                    "description": "",
                    "maintenance_per_square_feet": 0,
                    "id": 0,
                    "created_at": "",
                    "ticket_days_configuration": 0,
                    "complex_area": "",
                    "name": "",
                    "real_estate_company_id": null,
                    "updated_at": "",
                    "zone_multiplier": 0,
                    "contigency": 0,
                    "latitude": null,
                    "late_charge_percentage": "",
                    "measurement_unit": "",
                    "value_added_tax": "",
                    "longitude": null,
                    "total_unit": null,
                    "is_building": false,
                    "account_manager_id": null,
                    "total_floor": null,
                    "currency_id": null,
                    "language": null,
                    "status": null
                  },
                  "unit_name": "",
                  "custom_term_condition_added": false,
                  "conditions": [],
                  "custom_term_condition": null,
                  "terms": [],
                  "tenant": {
                    "first_name": null,
                    "id": 0,
                    "full_phone_number": "",
                    "last_name": null,
                    "company_name": null,
                    "manager_full_name": null,
                    "owner_full_name": null,
                    "owner_email": null,
                    "public_field": null,
                    "phone_number": null,
                    "owner_phone_number": null,
                    "disable_chat": false,
                    "ticket_days_configuration": 0,
                    "language_type": null,
                    "email": "",
                    "country_code": null,
                    "building_management_id": null,
                    "unique_auth_id": null,
                    "device_id": null,
                    "suspend_until": null,
                    "status": "",
                    "activated": true,
                    "stripe_subscription_id": null,
                    "stripe_id": null,
                    "stripe_subscription_date": null,
                    "full_name": "",
                    "gender": null,
                    "date_of_birth": null,
                    "role_id": null,
                    "is_paid": false,
                    "age": null,
                    "platform": null,
                    "user_type": null,
                    "app_language_id": null,
                    "updated_at": "",
                    "created_at": "",
                    "last_visit_at": null,
                    "user_name": null,
                    "is_blacklisted": false
                  },
                  "custom_contract_image": null,
                  "penalty_late_payments": {
                    "id": 0,
                    "penanlty_counted": "",
                    "account_id": 0,
                    "penanlty_type": null,
                    "tenant_id": 0,
                    "amount": ""
                  }
                  }
                }
              }
            },
          createContractPayload: {
            country: "",
            templateId: "",
            complex: "",
            city: "",
            unitName: "",
            buildingName: "",
            tenant_name: "",
            complexName: "",
            building_management_id: "",
            landlord_name: "",
            tenant_id: "",
            society_management_id: "",
            apartment_management_id: "",
            expires_on: "",
            start_date: "",
            agreement_duration: "",
            lease_template_id: "",
            term_ids: [],
            currency: "",
            rent_amount: "",
            custom_contract: false,
            condition_ids: [],
            contract_template: "",
            penanlty_late_payment: false,
            accountId: "",
            owner: "",
            contractLink: ""
          }
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        // Customizable Area End
    }
    // Customizable Area Start
      draftReviewContractDetailsApiCall = async (data: APIPayloadType) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = data;
        const header = {
          token: token,
          "Content-Type": contentType,
        };
        let contractDetailsrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        body && type !== "formData"
          ? contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          )
          : contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
      };

      async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson && !responseJson.errors) {
            this.contractDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
          } else if (responseJson && responseJson.errors) {
            this.contractDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
          }
        } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
          let DraftDetailsId = message.getData(getName(MessageEnum.DraftDetailsId));
          if (DraftDetailsId) {
            this.setState({ constractId: DraftDetailsId }, () => {
              this.getContractDetails();
            })
                    }
        }
      };
  
      contractDetailsApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: ContractDetailsSuccessResponse & GetCreateContractResponse) => {
        if (apiRequestCallId === this.reviewDraftDetailsApiCallId) {
          this.reviewDraftDetailsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.createContractApiCallId) {
          this.createContractSucessCallBack(responseJson);
        }
      };
    
      contractDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponseCombination) => {
        if (apiRequestCallId === this.reviewDraftDetailsApiCallId) {
          this.reviewDraftDetailsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.createContractApiCallId) {
          this.createContractFailureCallBack(responseJson);
        }
      };
    
      getContractDetails = async () => {
        this.reviewDraftDetailsApiCallId = await this.draftReviewContractDetailsApiCall({
          method: configJSON.contractDetailsMethod,
          endPoint: `${configJSON.contractDetailsEndPoint}/${this.state.constractId}`,
        });
      };
    
  reviewDraftDetailsSucessCallBack = (response: ContractDetailsSuccessResponse) => {
    if (response.contract.data.attributes.terms !== null) {
      response.contract.data.attributes.terms.forEach((term) => {
        this.state.termsId.push(term.id)
      })
    }
    if (response.contract.data.attributes.conditions !== null) {
      response.contract.data.attributes.conditions.forEach((condition) => {
        this.state.conditionId.push(condition.id)
      })
    }
    let template = response.contract.data.attributes.lease_template_text;
    template = template.replace('{{TENANT_NAME}}', response.contract.data.attributes.tenant.full_name)
    template = template.replace('{{START_DATE}}', response.contract.data.attributes.start_date)
    template = template.replace(/{{LANDLORD_NAME}}/g, response.contract.data.attributes.owner.full_name)
    template = template.replace('{{BUILDING_NAME}}', response.contract.data.attributes.building_name)
    template = template.replace('{{UNIT_NAME}}', response.contract.data.attributes.unit_name)
    template = template.replace('{{DURATION}}', response.contract.data.attributes.agreement_duration + " months")
    template = template.replace('{{END_DATE}}', response.contract.data.attributes.expire_on + "")
    template = template.replace('{{AMOUNT}}', response.contract.data.attributes.rent_amount + " " + response.contract.data.attributes.currency + " per months")
    template = template.replace('{{COMPLEX_NAME}}', response.contract.data.attributes.society_management.name);
    template = template.replace('{{COMPLEX_ADDRESS}}', response.contract.data.attributes.city + ", " + response.contract.data.attributes.country )
    response.contract.data.attributes.lease_template_text = template;
    this.setState({ reviewDraftDetails: response, termsId: this.state.termsId, conditionId: this.state.conditionId });
  };
    
      reviewDraftDetailsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({showError:true,error:response.errors[0].contract});
      };

  generatContract = async () => {
        let formdata: FormData = new FormData();
        formdata.append("[contract][apartment_management_id]", this.state.reviewDraftDetails.contract.data.attributes.apartment_management_id + "");
    formdata.append("[contract][society_management_id]", this.state.reviewDraftDetails.contract.data.attributes.society_management_id + "");
    formdata.append("[contract][building_management_id]", this.state.reviewDraftDetails.contract.data.attributes.building_management_id + "");
    formdata.append("[contract][agreement_duration]", this.state.reviewDraftDetails.contract.data.attributes.agreement_duration);
    formdata.append("[contract][tenant_name]", this.state.reviewDraftDetails.contract.data.attributes.tenant.full_name);
    formdata.append("[contract][rent_amount]", this.state.reviewDraftDetails.contract.data.attributes.rent_amount + "");
    formdata.append("[contract][tenant_id]", this.state.reviewDraftDetails.contract.data.attributes.tenant.id + "");
    formdata.append("[contract][start_date]", this.state.reviewDraftDetails.contract.data.attributes.start_date);
    formdata.append("[contract][landlord_name]", this.state.reviewDraftDetails.contract.data.attributes.landlord_name);
    formdata.append("[contract][expires_on]", this.state.reviewDraftDetails.contract.data.attributes.expires_on);
    formdata.append("[contract][custom_contract]", this.state.reviewDraftDetails.contract.data.attributes.custom_contract + "");
    formdata.append("[contract][term_ids][]", this.state.termsId + "");
    formdata.append("[contract][lease_template_id]", this.state.reviewDraftDetails.contract.data.attributes.lease_template_id + "");
    formdata.append("[contract][contract_template]", this.state.reviewDraftDetails.contract.data.attributes.contract_template + "");
    formdata.append("[contract][penanlty_late_payment]", this.state.reviewDraftDetails.contract.data.attributes.penanlty_late_payment + "");
    formdata.append("[contract][currency]", this.state.reviewDraftDetails.contract.data.attributes.currency);
    formdata.append("[contract][account_id]", this.state.reviewDraftDetails.contract.data.attributes.owner.id + "")
    formdata.append("[contract][condition_ids][]", this.state.conditionId + "");
    formdata.append("[contract][country]", this.state.reviewDraftDetails.contract.data.attributes.country + "");
    formdata.append("[contract][city]", this.state.reviewDraftDetails.contract.data.attributes.city + "");
    this.createContractApiCallId = await this.draftReviewContractDetailsApiCall({
      method: configJSON.inviteMemberMethod,
      endPoint: configJSON.createContractEndPoint,
      body: formdata,
      type:"formData"
    });
  };

  createContractSucessCallBack = (response: GetCreateContractResponse) => {
    this.setState({ showSuccessMessage:true,successMessage: response.message })
  };

  createContractFailureCallBack = (response: ApiFailureResponseCombination) => {        
    if (response.errors[0].contract) {
      this.setState({ showError: true, error: response.errors[0].contract });
    } else if (response.errors[0].errors) {
      this.setState({ showError: true, error: response.errors[0].errors });
    } else {
      this.setState({ showError: true, error: response.errors });
    }
  };

  saveContract = ()=>{
    let createContractPayload= {
      templateId: this.state.reviewDraftDetails.contract.data.attributes.tenant.id,
      complex: "Complex",
      city: this.state.reviewDraftDetails.contract.data.attributes.city,
      unitName: this.state.reviewDraftDetails.contract.data.attributes.unit_name,
      buildingName: this.state.reviewDraftDetails.contract.data.attributes.building_name,
      society_management_id: this.state.reviewDraftDetails.contract.data.attributes.society_management_id,
      complexName: this.state.reviewDraftDetails.contract.data.attributes.society_management.name,
      tenant_name: this.state.reviewDraftDetails.contract.data.attributes.tenant.full_name,
      country: this.state.reviewDraftDetails.contract.data.attributes.country,
      landlord_name: this.state.reviewDraftDetails.contract.data.attributes.owner.full_name,
      building_management_id: this.state.reviewDraftDetails.contract.data.attributes.building_management_id,
      apartment_management_id: this.state.reviewDraftDetails.contract.data.attributes.apartment_management_id,
      tenant_id: this.state.reviewDraftDetails.contract.data.attributes.tenant.id,
      expires_on: this.state.reviewDraftDetails.contract.data.attributes.expire_on,
      term_ids: this.state.termsId,
      agreement_duration: this.state.reviewDraftDetails.contract.data.attributes.agreement_duration,
      start_date: this.state.reviewDraftDetails.contract.data.attributes.start_date,
      custom_contract: this.state.reviewDraftDetails.contract.data.attributes.custom_contract,
      lease_template_id: this.state.reviewDraftDetails.contract.data.attributes.lease_template_id,
      rent_amount: this.state.reviewDraftDetails.contract.data.attributes.rent_amount,
      currency: this.state.reviewDraftDetails.contract.data.attributes.currency,
      condition_ids: this.state.conditionId,
      penanlty_late_payment: this.state.reviewDraftDetails.contract.data.attributes.penanlty_late_payment,
      owner: this.state.reviewDraftDetails.contract.data.attributes.owner.full_name,
      ownerNumber: this.state.reviewDraftDetails.contract.data.attributes.owner.phone_number,
      contract_template: this.state.reviewDraftDetails.contract.data.attributes.contract_template,
      accountId: this.state.reviewDraftDetails.contract.data.attributes.owner.id,
      ownerEmail: this.state.reviewDraftDetails.contract.data.attributes.owner.email,
      status: "create",
      contractLink: this.state.reviewDraftDetails.contract.data.attributes.contract_template_pdf.url
    }
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractReview");
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), createContractPayload);
    this.send(msgData);
};

  handleCloseAlertError = () => {
    this.setState({ showError: false, error: "" });
  };

  handleCommentFile = (fileUrl: string) => {
    const downloadPdfBtn = document.createElement('a');
    downloadPdfBtn.href = fileUrl;
    downloadPdfBtn.download = 'document.pdf';
    downloadPdfBtn.target = '_blank';
    document.body.appendChild(downloadPdfBtn);
    downloadPdfBtn.click();
    document.body.removeChild(downloadPdfBtn);
  }

  handleExistRecordNavigation = (path: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    this.send(msgData);
  }; 

  handleEditDocumentNavigation = () => {
    let createContractPayload = {
      templateId: this.state.reviewDraftDetails.contract.data.attributes.lease_template_id,
      country: this.state.reviewDraftDetails.contract.data.attributes.country,
      city: this.state.reviewDraftDetails.contract.data.attributes.city,
      complex: "Complex",
      buildingName: this.state.reviewDraftDetails.contract.data.attributes.building_name,
      unitName: this.state.reviewDraftDetails.contract.data.attributes.unit_name,
      complexName: this.state.reviewDraftDetails.contract.data.attributes.society_management.name,
      tenant_name: this.state.reviewDraftDetails.contract.data.attributes.tenant.full_name,
      landlord_name: this.state.reviewDraftDetails.contract.data.attributes.owner.full_name,
      building_management_id: this.state.reviewDraftDetails.contract.data.attributes.building_management_id,
      society_management_id: this.state.reviewDraftDetails.contract.data.attributes.society_management_id,
      tenant_id: this.state.reviewDraftDetails.contract.data.attributes.tenant.id,
      expires_on: this.state.reviewDraftDetails.contract.data.attributes.expire_on,
      apartment_management_id: this.state.reviewDraftDetails.contract.data.attributes.apartment_management_id,
      agreement_duration: this.state.reviewDraftDetails.contract.data.attributes.agreement_duration,
      start_date: this.state.reviewDraftDetails.contract.data.attributes.start_date,
      term_ids: this.state.termsId,
      lease_template_id: this.state.reviewDraftDetails.contract.data.attributes.lease_template_id,
      rent_amount: this.state.reviewDraftDetails.contract.data.attributes.rent_amount,
      currency: this.state.reviewDraftDetails.contract.data.attributes.currency,
      condition_ids: this.state.conditionId,
      custom_contract: this.state.reviewDraftDetails.contract.data.attributes.custom_contract,
      penanlty_late_payment: this.state.reviewDraftDetails.contract.data.attributes.penanlty_late_payment,
      contract_template: this.state.reviewDraftDetails.contract.data.attributes.contract_template,
      owner: this.state.reviewDraftDetails.contract.data.attributes.owner.full_name,
      ownerNumber: this.state.reviewDraftDetails.contract.data.attributes.owner.full_phone_number,
      ownerEmail: this.state.reviewDraftDetails.contract.data.attributes.owner.owner_email,
      accountId: this.state.reviewDraftDetails.contract.data.attributes.owner.id,
      status: "active"
    };
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractForm");
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), createContractPayload);
    this.send(msgData);
  };
     
  hanldeCloseSuccessAlert = () => {this.setState({showSuccessMessage:false,successMessage:""}, () => {
    this.handleExistRecordNavigation("LeaseManagment");
  })};

    // Customizable Area End
}