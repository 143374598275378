// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { ChangeEvent } from "react";
export const configJSON = require("./config");
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string;
  navigation :typeof navigation;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  addMoreConditionModal:boolean,
  penaltyType: string;
  addMoreTermsModal:boolean;
  termsList:Array<GetTermsSuccessResponseItem>;
  conditionList:Array<GetConditionsSuccessResponseItem>;
  showError:boolean;
  error:string ;
  selectedConditions:Array<number>;
  selectedTerms:Array<number>;
  addTermText:string;
  showSuccessMessage:boolean,
  successMessage:string;
  createContractPayloadData:CreateContractPayloadData;
  penaltyOption: string;
  penalty:boolean;
  penaltyAmount: string;
  penaltyTypeOptions: Array<string>;
  penaltyId: string;
  // Customizable Area End
}

// Customizable Area Start
export interface CreateContractPayloadData{
  country: string;
  templateId: string;
  complex: string;
  city: string;
  unitName: string;
  buildingName: string;
  tenant_name: TenantNameInterface;
  complexName: string;
  building_management_id: string;
  landlord_name: string;
  tenant_id: string;
  society_management_id: string;
  apartment_management_id: string;
  expires_on: string;
  start_date: string;
  agreement_duration: string;
  lease_template_id: string;
  term_ids: Array<number>;
  condition_ids: Array<number>;
  currency: string;
  rent_amount: string;
  custom_contract: boolean;
  contract_template: string;
  penanlty_late_payment: boolean;
  ownerNumber: string | null;
  owner: string | null;
  accountId: Account_id;
  ownerEmail: string | null;
  status: string;
  contractLink: string;
}

  type Account_id = string | number | null
  
  type TenantNameInterface = string | number | null

export interface ApiPayloadType {
  method: string;
  baseURL?: string;
  endPoint: string;
  body?: object;
  contentType?: string;
  type?: string;
};

export interface GetTermsSuccessResponse{
  terms: Array<GetTermsSuccessResponseItem>
};

export interface GetTermsSuccessResponseItem {
  id: number,
  text: string,
  created_at: string,
  updated_at: string,
  text_ar: string
};

export interface GetConditionsSuccessResponse{
  Conditions: Array<GetConditionsSuccessResponseItem>
};

export interface GetConditionsSuccessResponseItem {
  id: number,
  text: string,
  created_at: string,
  updated_at: string,
  text_ar: string
};

export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError {
  contract: string
}

export interface AddTermsSuccessResponse{
  terms: {
      id: number,
      text: string,
      created_at: string,
      updated_at: string,
      text_ar: null
  }
}

export type TFunction = (value: string) => string;

export interface GetPenaltyResonsResponse {
  penanlty: {
      id: number;
      penanlty_counted: string;
      penanlty_type: null | string | number;
      amount: string;
      account_id: number;
      tenant_id: number;
  }
}

export interface PenaltyUpdateResponse {
  penanlty: {
    id: number;
    penanlty_counted: string;
    amount: string;
    tenant_id: number;
    account_id: number;
    penanlty_type: null;
  },
  code: number;
  message: string;
}
export interface GetNoPenaltyResponse {
  penanlty: null
}
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractAddTermsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsApiCallId : string = "";
  getConditionsApiCallId : string = "";
  addTermsApiCallId : string = "";
  getPenaltyResonsApiCallId : string = "";
  penaltyUpdateApiCallId : string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CreateContractPayloadData),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      addMoreConditionModal:false,
      addMoreTermsModal:false,
      penaltyType: "",
      showError:false,
      error:"",
      termsList: [],
      conditionList: [],
      selectedConditions:[],
      selectedTerms:[],
      addTermText:"",
      showSuccessMessage:false,
      penaltyOption: "",
      successMessage:"",
      createContractPayloadData: {
        country: "",
        templateId: "",
        complex: "",
        city: "",
        unitName: "",
        buildingName: "",
        tenant_name: "",
        complexName: "",
        building_management_id: "",
        landlord_name: "",
        tenant_id: "",
        society_management_id: "",
        apartment_management_id: "",
        expires_on: "",
        start_date: "",
        agreement_duration: "",
        term_ids: [],
        lease_template_id: "",
        currency: "",
        rent_amount: "",
        custom_contract: false,
        condition_ids: [],
        penanlty_late_payment: false,
        contract_template: "",
        ownerNumber: "",
        owner: "",
        accountId: "",
        ownerEmail: "",
        status: "create",
        contractLink: ""
      },
      penalty:false,
      penaltyAmount: "",
      penaltyTypeOptions: ["Fixed Percentage", "Fixed Amount"],
      penaltyId: ""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    
    // Customizable Area End
  }
  
  // Customizable Area Start
  async receive(from: string, message: Message) {    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson?.errors) {
        this.issueNewContractAddTersSuccessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.issueNewContractAddTermsFailureCall(apiRequestCallId, responseJson);
      }
    }
    else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractData = message.getData(getName(MessageEnum.CreateContractPayloadData));
             if(createContractData){
        this.setState({createContractPayloadData:createContractData},()=>{ 
          this.fillData();                  
          this.getTermsList();
          this.getConditionsList();
          this.getPenaltyResons();
        })
       }
    }
  };

  fillData = () => {
    if (this.state.createContractPayloadData.condition_ids.length > 0) {
      this.setState({ selectedConditions: this.state.createContractPayloadData.condition_ids });
    }
    if (this.state.createContractPayloadData.term_ids.length > 0) {
      this.setState({ selectedTerms: this.state.createContractPayloadData.term_ids });
    }
  };

  issueNewContractAddTermsApiCall = async (data: ApiPayloadType) => {
    let token = localStorage.getItem("loginSuccessToken")
    let { method, endPoint, body, type = "", contentType } = data;
    const header = {
      token: token,
      "Content-Type": contentType,
    };

    let addTermMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    addTermMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    addTermMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    addTermMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    addTermMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );


    body && type !== "formData"
      ? addTermMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : addTermMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(addTermMessage.id, addTermMessage);
    return addTermMessage.messageId;
  };

  issueNewContractAddTersSuccessCell = async (
    apiRequestCallId: string,
    responseJson:
      GetTermsSuccessResponse & GetConditionsSuccessResponse & AddTermsSuccessResponse & GetPenaltyResonsResponse & GetNoPenaltyResponse) => {

    if (apiRequestCallId === this.getTermsApiCallId) {
      this.getTermsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getConditionsApiCallId) {
      this.getConditionsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.addTermsApiCallId) {    
      this.addTermsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPenaltyResonsApiCallId) {    
      this.getPenaltyResonsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.penaltyUpdateApiCallId) {    
      this.penaltyUpdateSucessCallBack(responseJson);
    }
    
  };

  issueNewContractAddTermsFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.getTermsApiCallId) {
      this.getTermsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getConditionsApiCallId) {
      this.getConditionsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.addTermsApiCallId) {
      this.addTermsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPenaltyResonsApiCallId) {    
      this.getPenaltyResonsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.penaltyUpdateApiCallId) {    
      this.penaltyUpdateFailureCallBack(responseJson);
    }
  };

  getTermsList = async () => {
    this.getTermsApiCallId = await this.issueNewContractAddTermsApiCall({
      method: configJSON.termsListMethod,
      endPoint: configJSON.termsListEndPoint,
    });
  };

  getTermsSucessCallBack = (response: GetTermsSuccessResponse) => {        
    this.setState({ termsList: response.terms })
  };

  getTermsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract})
  };

  getConditionsList = async () => {
    this.getConditionsApiCallId = await this.issueNewContractAddTermsApiCall({
      method: configJSON.conditionListMethod,
      endPoint: configJSON.conditionListEndPoint,
    });
  };

  getConditionsSucessCallBack = (response: GetConditionsSuccessResponse) => {
    this.setState({ conditionList: response.Conditions }, () => {
      this.fixTemplateDetails();
    });
  };

  getConditionsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract})
  };

  fixTemplateDetails = () => {
    let contract_template = this.state.createContractPayloadData.contract_template
    contract_template = contract_template.replace("{{TENANT_NAME}}", this.state.createContractPayloadData.tenant_name as string);
    contract_template = contract_template.replace("{{START_DATE}}", this.state.createContractPayloadData.start_date);
    contract_template = contract_template.replace("{{LANDLORD_NAME}}", this.state.createContractPayloadData.landlord_name);
    contract_template = contract_template.replace("{{BUILDING_NAME}}", this.state.createContractPayloadData.buildingName);
    contract_template = contract_template.replace("{{UNIT_NAME}}", this.state.createContractPayloadData.unitName);
    contract_template = contract_template.replace("{{DURATION}}", this.state.createContractPayloadData.agreement_duration + " months");
    contract_template = contract_template.replace("{{END_DATE}}", this.state.createContractPayloadData.expires_on);
    contract_template = contract_template.replace("{{AMOUNT}}", this.state.createContractPayloadData.currency + " " + this.state.createContractPayloadData.rent_amount  +  " per month");
    if (this.state.createContractPayloadData.landlord_name) {
      contract_template = contract_template.replace(/{{LANDLORD_NAME}}/g, this.state.createContractPayloadData.landlord_name);
    }
    this.state.createContractPayloadData.contract_template = contract_template;
    this.setState({ createContractPayloadData: this.state.createContractPayloadData });
  };

  addTermsApi = async() => {
    this.addTermsApiCallId = await this.issueNewContractAddTermsApiCall({
      method: configJSON.addTermsMethod,
      endPoint: `${configJSON.addTermsEndPoint}${this.state.addTermText}`,
    });
  };

  addTermsSucessCallBack = (response: AddTermsSuccessResponse) => {
    this.setState({showSuccessMessage:true,successMessage:`${response.terms.text} Term added`,addMoreConditionModal:true,addMoreTermsModal:false},()=>{
      this.getTermsList();
    })
  };

  addTermsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract})
  };

  getPenaltyResons = async() => {
    this.getPenaltyResonsApiCallId = await this.issueNewContractAddTermsApiCall({
      method: configJSON.conditionListMethod,
      endPoint: `${configJSON.penaltyReasonsEndPoint}${this.state.createContractPayloadData.tenant_id}`,
    });
  };

  getPenaltyResonsSucessCallBack = (response: GetPenaltyResonsResponse) => {
    if (response.penanlty !== null) {
      this.setState({
        penaltyType: response.penanlty.penanlty_counted,
        penaltyAmount: response.penanlty.amount,
        penaltyId: String(response.penanlty.id)
      });
    }
  };

  getPenaltyResonsFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({showError:true,error:response.errors[0].contract})
  };

  penaltyUpdate = async (penalty: string | number | null) => {
    let payload = {
      penanlty:
      {
        penanlty_counted: this.state.penaltyType,
        amount: penalty,
        tenant_id: this.state.createContractPayloadData.tenant_id
      }
    }
    this.penaltyUpdateApiCallId = await this.issueNewContractAddTermsApiCall({
      method: configJSON.PatchAPiMethod,
      endPoint: `${configJSON.penaltyUpdateEndpoint}/${this.state.penaltyId}`,
      body: payload,
      contentType: configJSON.validationApiContentType
    });
  };

  penaltyUpdateSucessCallBack = (response: PenaltyUpdateResponse) => {
      this.setState({ successMessage: response.message, penaltyAmount: response.penanlty.amount });
  };

  penaltyUpdateFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({ showError: true, error: response.errors[0].contract });
  };
  
  handleBack = () => {
    let createContractPayload = this.state.createContractPayloadData
    createContractPayload.penanlty_late_payment = this.state.penalty
    createContractPayload.term_ids = this.state.selectedTerms
    createContractPayload.condition_ids = this.state.selectedConditions
    createContractPayload.custom_contract = false;
    this.setState({ createContractPayloadData: this.state.createContractPayloadData }, () => {
      const messageData = new Message(getName(MessageEnum.NavigationMessageSendData));
      messageData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractForm");
      messageData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      messageData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayloadData);
      this.send(messageData);
    });
  }
  
  handleNext=()=>{
    let contractPayloadData =this.state.createContractPayloadData
    contractPayloadData.penanlty_late_payment=this.state.penalty
    contractPayloadData.term_ids=this.state.selectedTerms
    contractPayloadData.condition_ids=this.state.selectedConditions
    contractPayloadData.custom_contract=false;
    contractPayloadData.status = this.state.createContractPayloadData.status;
    this.setState({createContractPayloadData:this.state.createContractPayloadData},()=>{      
     const msgData = new Message(getName(MessageEnum.NavigationMessageSendData));
      msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "IssueNewContractReview");
      msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayloadData);
      this.send(msgData);
    });
  }
  handleOpenAddMoreCondition=()=>{
    this.setState({addMoreConditionModal:true})
  }
  handleCloseAddMoreCondition=()=>{
    this.setState({addMoreConditionModal:false})
  }
  handleOpenMoreTermsModal = () => {
    this.setState({ addMoreTermsModal: true, addMoreConditionModal: false })
  }
  handleCloseMoreTermsModal = () => {
    this.setState({ addMoreTermsModal: false, addMoreConditionModal: true })
  };

  handleConfirm =()=>{
    this.setState({addMoreConditionModal:false});
  };

  handleAddTermChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ addTermText: event.target.value });
  };

  handleCloseAlertError = () => { this.setState({ showError: false, error: "." }) };

  handleSelectedConditions = (conditionId: number) => {
    const { selectedConditions } = this.state;
    const isChecked = selectedConditions.includes(conditionId);
    const updatedSelectedConditions = isChecked
        ? selectedConditions.filter(conditionId => conditionId !== conditionId)
        : [...selectedConditions, conditionId];
    this.setState({ selectedConditions: updatedSelectedConditions });
};

  handleSelectedTerms = (termId: number) => {
    const { selectedTerms } = this.state;
    const isChecked = selectedTerms.includes(termId);
    const updatedSelectedConditions = isChecked
      ? selectedTerms.filter(termId => termId !== termId)
      : [...selectedTerms, termId];
    this.setState({ selectedTerms: updatedSelectedConditions });
  };

  hanldeCloseSuccessAlert = () => {this.setState({showSuccessMessage:false,successMessage:""})}

  handlePenaltyCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({penalty:!this.state.penalty})
  }

  handleExistRecordNavigation = (path: string) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), path);
    msgData.addData(getName(MessageEnum.CreateContractPayloadData), this.state.createContractPayloadData);
    this.send(msgData);
  };
  
  handleConditionsCheck = (conditionId: number) => {
    let condition = this.state.selectedConditions.includes(conditionId);
    return condition ? true : false;
  };

  handleTermsCheck = (termId: number) => {
    let condition = this.state.selectedTerms.includes(termId);
    return condition ? true : false;
  };

  handlePenaltyChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ penaltyAmount: event.target.value }, () => {
      if (this.state.penaltyType === "Fixed Percentage" && Number(this.state.penaltyAmount) > 100) {
        this.setState({ showError: true, error: "Please enter below hundread" });
      } else {
        this.penaltyUpdate(this.state.penaltyAmount);
      }
    });
  };

  handlePenaltyType = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ penaltyType: event.target.value as string }, () => {
      if (this.state.penaltyType === "Fixed Percentage" && Number(this.state.penaltyAmount) > 100) {
        this.setState({ showError: true, error: "Please enter below hundread" });
      }
    });
  };
  // Customizable Area End
}